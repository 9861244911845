import React, { useContext } from "react";
import { Form, Row, Col, Accordion } from "react-bootstrap";
import { BiPencil } from "react-icons/bi";
import { AuthContext } from "../helpers/AuthContext";
import { PaymentPage } from "./PaymentPage";
import { handleModalEnter } from "./utils";

export const Summary = ({ parsedDetails, proposalId }) => {
  const { setSteps, showPaymentPage, setShowPaymentPage } =
    useContext(AuthContext);

  const medicalHistory = (
    medQ1,
    medQ2,
    medQ3,
    medQ4,
    medQ5,
    medQ6,
    medQ7,
    medQ8,
    medQ9,
    medQ10,
    medQ11,
    medQ12,
    height,
    weight
  ) => {
    return (
      <Row
        className="mb-3 container-fluid mx-auto pb-3"
        style={{ maxWidth: "800px", borderBottom: "1px solid #EFEFEF" }}
      >
        <>
          <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
            <Form.Label style={{ color: "#818181" }}>
              Have you ever suffered from any serious personal accident
              involving fractured skull and bones or unconciousness or other
              injury?
            </Form.Label>
            <p>
              <strong>{medQ1}</strong>
            </p>
          </Form.Group>

          <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
            <Form.Label style={{ color: "#818181" }}>
              Have you ever suffered from epilepsy or other mental disturbances?
            </Form.Label>
            <p>
              <strong>{medQ2}</strong>
            </p>
          </Form.Group>
        </>
        <>
          <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
            <Form.Label style={{ color: "#818181" }}>
              Have you ever been treated for tuberculosis, asthma, pneumonia or
              any other chest related diseases?
            </Form.Label>
            <p>
              <strong>{medQ3}</strong>
            </p>
          </Form.Group>

          <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
            <Form.Label style={{ color: "#818181" }}>
              Have you experienced recurrent or persistent fever or skin
              disorder?
            </Form.Label>
            <p>
              <strong>{medQ4}</strong>
            </p>
          </Form.Group>
        </>
        <>
          <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
            <Form.Label style={{ color: "#818181" }}>
              Have you ever been diagnosed of any kidney, liver, vital internal
              organs infection or blood in urine?
            </Form.Label>
            <p>
              <strong>{medQ5}</strong>
            </p>
          </Form.Group>

          <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
            <Form.Label style={{ color: "#818181" }}>
              Have you ever been diagnosed or suffered from any canver,
              cancer-related disease, tumour, any other terminal illness
              disease?
            </Form.Label>
            <p>
              <strong>{medQ6}</strong>
            </p>
          </Form.Group>
        </>
        <>
          <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
            <Form.Label style={{ color: "#818181" }}>
              Have you ever been diagnosed or suffered from heart disease, high
              blood pressure, stroke or any cardiovascular related diseases?
            </Form.Label>
            <p>
              <strong>{medQ7}</strong>
            </p>
          </Form.Group>

          <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
            <Form.Label style={{ color: "#818181" }}>
              Have you ever been diagnosed or suffered from any blood sugar
              related disease, diabetes and/or diabetic related illness?
            </Form.Label>
            <p>
              <strong>{medQ8}</strong>
            </p>
          </Form.Group>
        </>
        <>
          <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
            <Form.Label style={{ color: "#818181" }}>
              Have you ever had any minor or major organ transplant?
            </Form.Label>
            <p>
              <strong>{medQ9}</strong>
            </p>
          </Form.Group>

          <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
            <Form.Label style={{ color: "#818181" }}>
              Have you ever been diagnosed or suffered from any other ailment or
              illness not mentioned in 1-9 above? If yes, please state the
              ailment or illnwss and the date it was discovered or diagnosed.
            </Form.Label>
            <p>
              <strong>{medQ10}</strong>
            </p>
          </Form.Group>
        </>
        <>
          <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
            <Form.Label style={{ color: "#818181" }}>
              Do you consume alcohol? If Yes, please state your daily
              consumption in litres.
            </Form.Label>
            <p>
              <strong>{medQ11}</strong>
            </p>
          </Form.Group>

          <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
            <Form.Label style={{ color: "#818181" }}>
              Do you consume tobacco? If Yes, please state your daily
              consumption in sticks.
            </Form.Label>
            <p>
              <strong>{medQ12}</strong>
            </p>
          </Form.Group>
        </>
        <>
          <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
            <Form.Label style={{ color: "#818181" }}>Height</Form.Label>
            <p>
              <strong>{height} CM</strong>
            </p>
          </Form.Group>

          <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
            <Form.Label style={{ color: "#818181" }}>Weight</Form.Label>
            <p>
              <strong>{weight} KG</strong>
            </p>
          </Form.Group>
        </>
        <div className="add-beneficiary-container">
          <button
            onClick={() => {
              setSteps(8);
              handleModalEnter();
            }}
          >
            <BiPencil /> Edit
          </button>
        </div>
      </Row>
    );
  };
  return (
    <>
      <div className="form-info" style={{ marginBottom: 20 }}>
        <h1 style={{ fontSize: 17, textDecoration: "underline" }}>
          Personal Info
        </h1>
      </div>
      <Row
        className="mb-3 container-fluid mx-auto pb-3"
        style={{ maxWidth: "800px", borderBottom: "1px solid #EFEFEF" }}
      >
        <Form.Group className="mb-4" as={Col} md={4}>
          <Form.Label style={{ color: "#818181" }}>Title</Form.Label>
          <p>
            <strong>{parsedDetails.title}</strong>
          </p>
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={4} controlId="formFullName">
          <Form.Label style={{ color: "#818181" }}>First Name</Form.Label>
          <p>
            <strong>{parsedDetails.firstName}</strong>
          </p>
        </Form.Group>

        <Form.Group
          className="mb-4"
          as={Col}
          md={4}
          controlId="formPhoneNumber"
        >
          <Form.Label style={{ color: "#818181" }}>Middle Name</Form.Label>
          <p>
            <strong>{parsedDetails.middleName}</strong>
          </p>
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={4} controlId="formFullName">
          <Form.Label style={{ color: "#818181" }}>Last Name</Form.Label>
          <p>
            <strong>{parsedDetails.lastName}</strong>
          </p>
        </Form.Group>

        <Form.Group
          className="mb-4"
          as={Col}
          md={4}
          controlId="formPhoneNumber"
        >
          <Form.Label style={{ color: "#818181" }}>Phone number</Form.Label>
          <p>
            <strong>{parsedDetails.phone}</strong>
          </p>
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={4} controlId="formBasicEmail">
          <Form.Label style={{ color: "#818181" }}>Email address</Form.Label>
          <p>
            <strong>{parsedDetails.email}</strong>
          </p>
        </Form.Group>

        <Form.Group
          className="mb-4"
          as={Col}
          md={4}
          controlId="formDateOfBirth"
        >
          <Form.Label style={{ color: "#818181" }}>Date of birth</Form.Label>
          <p>
            <strong>{parsedDetails.dob}</strong>
          </p>
        </Form.Group>

        <Form.Group
          className="mb-4"
          as={Col}
          md={4}
          controlId="formDateOfBirth"
        >
          {/* <Form.Label style={{ color: "#818181" }}>
            Are you a US citizen
          </Form.Label>
          <p>
            <strong>{parsedDetails.usCitizen}</strong>
          </p> */}
        </Form.Group>

        {parsedDetails.usCitizen === "No" && (
          <>
            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formBasicEmail"
            >
              <Form.Label style={{ color: "#818181" }}>
                State of Residence
              </Form.Label>
              <p>
                <strong>{parsedDetails.state}</strong>
              </p>
            </Form.Group>
            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formDateOfBirth"
            >
              <Form.Label style={{ color: "#818181" }}>City</Form.Label>
              <p>
                <strong>{parsedDetails.city}</strong>
              </p>
            </Form.Group>
          </>
        )}

        <Form.Group
          className="mb-4"
          as={Col}
          md={4}
          controlId="formDateOfBirth"
        >
          <Form.Label style={{ color: "#818181" }}>Street address</Form.Label>
          <p>
            <strong>{parsedDetails.address}</strong>
          </p>
        </Form.Group>

        <Form.Group
          className="mb-4"
          as={Col}
          md={4}
          controlId="formDateOfBirth"
        >
          <Form.Label style={{ color: "#818181" }}>Marital Status</Form.Label>
          <p>
            <strong>{parsedDetails.maritalStatus}</strong>
          </p>
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={4} controlId="formBasicEmail">
          <Form.Label style={{ color: "#818181" }}>Religion</Form.Label>
          <p>
            <strong>{parsedDetails.religion}</strong>
          </p>
        </Form.Group>

        <Form.Group
          className="mb-4"
          as={Col}
          md={4}
          controlId="formDateOfBirth"
        >
          <Form.Label style={{ color: "#818181" }}>Occupation</Form.Label>
          <p>
            <strong>{parsedDetails.occupation}</strong>
          </p>
        </Form.Group>

        <Form.Group
          className="mb-4"
          as={Col}
          md={4}
          controlId="formDateOfBirth"
        >
          <Form.Label style={{ color: "#818181" }}>Business Type</Form.Label>
          <p>
            <strong>{parsedDetails.businessTypes}</strong>
          </p>
        </Form.Group>

        <div className="add-beneficiary-container">
          <button
            onClick={() => {
              setSteps(4);
              handleModalEnter();
            }}
          >
            <BiPencil /> Edit
          </button>
        </div>
      </Row>

      <>
        <Row
          className="mb-3 container-fluid mx-auto pb-3"
          style={{ maxWidth: "800px", borderBottom: "1px solid #EFEFEF" }}
        >
          <div className="form-info" style={{ marginBottom: 20 }}>
            <h1 style={{ fontSize: 17, textDecoration: "underline" }}>
              Bank Details
            </h1>
          </div>

          {/* <Form.Group
            className="mb-4"
            as={Col}
            md={4}
            controlId="formBasicEmail"
          >
            <Form.Label style={{ color: "#818181" }}>
              Are you a First Bank holder?
            </Form.Label>
            <p>
              <strong>{parsedDetails.bankType}</strong>
            </p>
          </Form.Group> */}

          <Form.Group
            className="mb-4"
            as={Col}
            md={4}
            controlId="formDateOfBirth"
          >
            <Form.Label style={{ color: "#818181" }}>Account number</Form.Label>
            <p>
              <strong>{parsedDetails.accountNumber}</strong>
            </p>
          </Form.Group>

          <Form.Group
            className="mb-4"
            as={Col}
            md={4}
            controlId="formDateOfBirth"
          >
            <Form.Label style={{ color: "#818181" }}>Account Type</Form.Label>
            <p>
              <strong>{parsedDetails.accountType}</strong>
            </p>
          </Form.Group>

          {parsedDetails.bankType === "No" && (
            <>
              <Form.Group
                className="mb-4"
                as={Col}
                md={4}
                controlId="formDateOfBirth"
              >
                <Form.Label style={{ color: "#818181" }}>Bank</Form.Label>
                <p>
                  <strong>{parsedDetails.selectBank}</strong>
                </p>
              </Form.Group>
            </>
          )}

          <Form.Group
            className="mb-4"
            as={Col}
            md={4}
            controlId="formBasicEmail"
          >
            <Form.Label style={{ color: "#818181" }}>
              Mode of premium payment
            </Form.Label>
            <p>
              <strong>{parsedDetails.premiumPayment}</strong>
            </p>
          </Form.Group>

          <Form.Group
            className="mb-4"
            as={Col}
            md={4}
            controlId="formDateOfBirth"
          >
            <Form.Label style={{ color: "#818181" }}>
              Inflation protection %
            </Form.Label>
            <p>
              <strong>{parsedDetails.inflation}</strong>
            </p>
          </Form.Group>

          {parsedDetails.premiumPayment === "Direct Debit" && (
            <>
              <p
                style={{
                  alignSelf: "flex-start",
                  paddingLeft: "18px",
                  marginTop: "10px",
                }}
              >
                <strong>Direct Debit Details</strong>
              </p>
              <>
                <Form.Group
                  className="mb-4"
                  as={Col}
                  md={4}
                  controlId="formBasicEmail"
                >
                  <Form.Label style={{ color: "#818181" }}>
                    Direct Debit commencement date
                  </Form.Label>
                  <p>
                    <strong>{parsedDetails.commencementDate}</strong>
                  </p>
                </Form.Group>
              </>
              <>
                <Form.Group
                  className="mb-4"
                  as={Col}
                  md={4}
                  controlId="formBasicEmail"
                >
                  <Form.Label style={{ color: "#818181" }}>
                    Direct debit bank
                  </Form.Label>
                  <p>
                    <strong>{parsedDetails.ddBank}</strong>
                  </p>
                </Form.Group>

                <Form.Group
                  className="mb-4"
                  as={Col}
                  md={4}
                  controlId="formDateOfBirth"
                >
                  <Form.Label style={{ color: "#818181" }}>
                    Direct debit account name
                  </Form.Label>
                  <p>
                    <strong>{parsedDetails.ddAccountName}</strong>
                  </p>
                </Form.Group>
              </>
              <>
                <Form.Group
                  className="mb-4"
                  as={Col}
                  md={4}
                  controlId="formBasicEmail"
                >
                  <Form.Label style={{ color: "#818181" }}>
                    Direct debit account number
                  </Form.Label>
                  <p>
                    <strong>{parsedDetails.ddAccountNumber}</strong>
                  </p>
                </Form.Group>

                <Form.Group
                  className="mb-4"
                  as={Col}
                  md={4}
                  controlId="formDateOfBirth"
                >
                  <Form.Label style={{ color: "#818181" }}>
                    Direct debit account type
                  </Form.Label>
                  <p>
                    <strong>{parsedDetails.ddAccountType}</strong>
                  </p>
                </Form.Group>
              </>
            </>
          )}
          <>
            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formBasicEmail"
            >
              <Form.Label style={{ color: "#818181" }}>ID card type</Form.Label>
              <p>
                <strong>{parsedDetails.idCardType}</strong>
              </p>
            </Form.Group>

            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formDateOfBirth"
            >
              <Form.Label style={{ color: "#818181" }}>
                ID card number
              </Form.Label>
              <p>
                <strong>{parsedDetails.cardNumber}</strong>
              </p>
            </Form.Group>
          </>
          <>
            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formBasicEmail"
            >
              <Form.Label style={{ color: "#818181" }}>
                ID card issuance date
              </Form.Label>
              <p>
                <strong>{parsedDetails.issuanceDate}</strong>
              </p>
            </Form.Group>

            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formDateOfBirth"
            >
              <Form.Label style={{ color: "#818181" }}>
                ID card expiration date
              </Form.Label>
              <p>
                <strong>{parsedDetails.expirationDate}</strong>
              </p>
            </Form.Group>
          </>
          <>
            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formDateOfBirth"
            >
              <Form.Label style={{ color: "#818181" }}>
                BVN (Optional)
              </Form.Label>
              <p>
                <strong>{parsedDetails.bvn}</strong>
              </p>
            </Form.Group>
            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formDateOfBirth"
            >
              <Form.Label style={{ color: "#818181" }}>
                Have you held / currently holding a public office?
              </Form.Label>
              <p>
                <strong>{parsedDetails.publicOffice}</strong>
              </p>
            </Form.Group>
          </>
          <div className="add-beneficiary-container">
            <button
              onClick={() => {
                setSteps(5);
                handleModalEnter();
              }}
            >
              <BiPencil /> Edit
            </button>
          </div>
        </Row>
      </>

      {parsedDetails.beneficiaries.length > 0 && (
        <Row
          className="mb-3 container-fluid mx-auto pb-3"
          style={{ maxWidth: "800px", borderBottom: "1px solid #EFEFEF" }}
        >
          <>
            <div className="form-info" style={{ marginBottom: 20 }}>
              <h1 style={{ fontSize: 17, textDecoration: "underline" }}>
                Beneficiaries
              </h1>
            </div>
            {parsedDetails.beneficiaries.length > 0 &&
              parsedDetails.beneficiaries.map((beneficiary) => {
                return (
                  <>
                    <>
                      <Form.Group
                        className="mb-4"
                        as={Col}
                        md={4}
                        controlId="formBasicEmail"
                      >
                        <Form.Label style={{ color: "#818181" }}>
                          Full Name
                        </Form.Label>
                        <p>
                          <strong>{beneficiary.firstname}</strong>
                        </p>
                      </Form.Group>

                      <Form.Group
                        className="mb-4"
                        as={Col}
                        md={4}
                        controlId="formDateOfBirth"
                      >
                        <Form.Label style={{ color: "#818181" }}>
                          Date of birth
                        </Form.Label>
                        <p>
                          <strong>{beneficiary.dob}</strong>
                        </p>
                      </Form.Group>
                    </>
                    <>
                      <Form.Group
                        className="mb-4"
                        as={Col}
                        md={4}
                        controlId="formBasicEmail"
                      >
                        <Form.Label style={{ color: "#818181" }}>
                          Relationship
                        </Form.Label>
                        <p>
                          <strong>{beneficiary.relationship}</strong>
                        </p>
                      </Form.Group>

                      {/* <Form.Group
                                    className="mb-3"
                                    controlId="formDateOfBirth"
                                  >
                                    <Form.Label style={{ color: "#818181" }}>Phone Number</Form.Label>
                                    <p>
                                      <strong>{beneficiary.phone}</strong>
                                    </p>
                                  </Form.Group> */}
                    </>
                    <>
                      <Form.Group
                        className="mb-4"
                        as={Col}
                        md={4}
                        controlId="formBasicEmail"
                      >
                        <Form.Label style={{ color: "#818181" }}>
                          State
                        </Form.Label>
                        <p>
                          <strong>{beneficiary.state}</strong>
                        </p>
                      </Form.Group>

                      <Form.Group
                        className="mb-4"
                        as={Col}
                        md={4}
                        controlId="formDateOfBirth"
                      >
                        <Form.Label style={{ color: "#818181" }}>
                          City
                        </Form.Label>
                        <p>
                          <strong>{beneficiary.city}</strong>
                        </p>
                      </Form.Group>
                    </>
                    <>
                      <Form.Group
                        className="mb-4"
                        as={Col}
                        md={4}
                        controlId="formDateOfBirth"
                      >
                        <Form.Label style={{ color: "#818181" }}>
                          Street address
                        </Form.Label>
                        <p>
                          <strong>{beneficiary.street_address}</strong>
                        </p>
                      </Form.Group>
                    </>
                    {(beneficiary.beneficiaryGuardianName !== "" ||
                      beneficiary.beneficiaryGuardianPhone !== "") && (
                      <>
                        <Form.Group
                          className="mb-4"
                          as={Col}
                          md={4}
                          controlId="formBasicEmail"
                        >
                          <Form.Label style={{ color: "#818181" }}>
                            Guardian Name
                          </Form.Label>
                          <p>
                            <strong>{beneficiary.guardian}</strong>
                          </p>
                        </Form.Group>

                        {/* <Form.Group
                                      className="mb-3"
                                      controlId="formDateOfBirth"
                                    >
                                      <Form.Label style={{ color: "#818181" }}>Guardian Phone</Form.Label>
                                      <p>
                                        <strong>
                                          {beneficiary.beneficiaryGuardianPhone}
                                        </strong>
                                      </p>
                                    </Form.Group> */}
                      </>
                    )}
                    <>
                      <Form.Group
                        className="mb-4"
                        as={Col}
                        md={4}
                        controlId="formDateOfBirth"
                      >
                        <Form.Label style={{ color: "#818181" }}>
                          Proportion
                        </Form.Label>
                        <p>
                          <strong>{beneficiary.percentage} %</strong>
                        </p>
                      </Form.Group>
                    </>

                    <div className="add-beneficiary-container">
                      <button
                        onClick={() => {
                          setSteps(6);
                          handleModalEnter();
                        }}
                      >
                        <BiPencil /> Edit
                      </button>
                    </div>

                    <p></p>
                  </>
                );
              })}
          </>
        </Row>
      )}

      <>
        <div className="form-info" style={{ marginBottom: 20 }}>
          <h1 style={{ fontSize: 17, textDecoration: "underline" }}>
            Referral
          </h1>
        </div>
        <Row
          className="mb-3 container-fluid mx-auto pb-3"
          style={{ maxWidth: "800px", borderBottom: "1px solid #EFEFEF" }}
        >
          <>
            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formBasicEmail"
            >
              <Form.Label style={{ color: "#818181" }}>
                How did you hear about us?
              </Form.Label>
              <p>
                <strong>{parsedDetails.hearAboutUs}</strong>
              </p>
            </Form.Group>

            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formDateOfBirth"
            >
              <Form.Label style={{ color: "#818181" }}>
                Do you have a referral code?
              </Form.Label>
              <p>
                <strong>{parsedDetails.haveReferralCode}</strong>
              </p>
            </Form.Group>
          </>
          {parsedDetails.haveReferralCode === "Yes" && (
            <>
              <Form.Group
                className="mb-4"
                as={Col}
                md={4}
                controlId="formDateOfBirth"
              >
                <Form.Label style={{ color: "#818181" }}>
                  Referral code
                </Form.Label>
                <p>
                  <strong>{parsedDetails.referralCode}</strong>
                </p>
              </Form.Group>
            </>
          )}
          <div className="add-beneficiary-container">
            <button
              onClick={() => {
                setSteps(7);
                handleModalEnter();
              }}
            >
              <BiPencil /> Edit
            </button>
          </div>
        </Row>
      </>

      <>
        <div className="form-info" style={{ marginBottom: 20 }}>
          <h1 style={{ fontSize: 17, textDecoration: "underline" }}>
            Medical History
          </h1>
        </div>
        {medicalHistory(
          parsedDetails.medQ1,
          parsedDetails.medQ2,
          parsedDetails.medQ3,
          parsedDetails.medQ4,
          parsedDetails.medQ5,
          parsedDetails.medQ6,
          parsedDetails.medQ7,
          parsedDetails.medQ8,
          parsedDetails.medQ9,
          parsedDetails.medQ10,
          parsedDetails.medQ11,
          parsedDetails.medQ12,
          parsedDetails.height,
          parsedDetails.weight
        )}

        {parsedDetails.dependants.length > 0 && (
          <Accordion
            className="mb-3 container-fluid mx-auto pb-3"
            style={{ maxWidth: "800px" }}
          >
            <div className="dependants-header">
              <h1>Dependants</h1>
              <p>Medical details for the dependants of this policy</p>
            </div>
            {parsedDetails.dependants.map((dependant) => {
              return (
                <Accordion.Item eventKey={dependant.id} key={dependant.id}>
                  <Accordion.Header>
                    <h6>
                      {dependant.name} ({dependant.beneficiary})
                    </h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    {medicalHistory(
                      dependant.medicals.medQ1,
                      dependant.medicals.medQ2,
                      dependant.medicals.medQ3,
                      dependant.medicals.medQ4,
                      dependant.medicals.medQ5,
                      dependant.medicals.medQ6,
                      dependant.medicals.medQ7,
                      dependant.medicals.medQ8,
                      dependant.medicals.medQ9,
                      dependant.medicals.medQ10,
                      dependant.medicals.medQ11,
                      dependant.medicals.medQ12,
                      dependant.medicals.height,
                      dependant.medicals.weight
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        )}
      </>

      <>
        <div className="form-info" style={{ marginBottom: 20 }}>
          <h1 style={{ fontSize: 17, textDecoration: "underline" }}>
            Uploaded Documents
          </h1>
        </div>
        <Row
          className="mb-3 container-fluid mx-auto pb-3"
          style={{ maxWidth: "800px", borderBottom: "1px solid #EFEFEF" }}
        >
          <div className="upload-container">
            <div className="upload-details">
              <h1>Means of Identification</h1>
              <p>(National ID, Voters ID, Passport or Drivers License)</p>
              <p>{`ID TYPE: ${parsedDetails.idCardType}`}</p>
              <p>{`ID NUMBER: ${parsedDetails.cardNumber}`}</p>
              <p>{`ISSUANCE DATE: ${parsedDetails.issuanceDate}`}</p>
              <p>{`EXPIRATION DATE: ${parsedDetails.expirationDate}`}</p>
              <span style={{ color: "gray", textDecoration: "none" }}>
                Uploaded successfully
              </span>
            </div>

            <div className="upload-image">
              <img
                src={parsedDetails.idImage}
                alt="proof"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>

          <div className="upload-container">
            <div className="upload-details">
              <h1>Passport</h1>
              <p>(A clear passport photograph)</p>
              <span style={{ color: "gray", textDecoration: "none" }}>
                Uploaded successfully
              </span>
            </div>

            <div className="upload-image">
              <img
                src={parsedDetails.passportImage}
                alt="passport"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>

          <div className="upload-container">
            <div className="upload-details">
              <h1>Evidence of address</h1>
              <p>(Electricity bill, water bill, Phone bill, Bank statement)</p>
              <span style={{ color: "gray", textDecoration: "none" }}>
                Uploaded successfully
              </span>
            </div>

            <div className="upload-image">
              <img
                src={parsedDetails.addressImage}
                alt="proof"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="upload-container">
            <div className="upload-details">
              <h1>Upload signature</h1>
              <p>(Upload a clear signature on a white sheet of paper)</p>
              <span style={{ color: "gray", textDecoration: "none" }}>
                Uploaded successfully
              </span>
            </div>

            <div className="upload-image">
              <img
                src={parsedDetails.signatureImage}
                alt="proof"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div
            className="add-beneficiary-container"
            style={{ marginTop: "15px", marginBottom: 0 }}
          >
            <button
              onClick={() => {
                setSteps(9);
                handleModalEnter();
              }}
            >
              <BiPencil /> Edit
            </button>
          </div>

          <p></p>
        </Row>
      </>
      {showPaymentPage && (
        <PaymentPage
          showPaymentPage={showPaymentPage}
          setShowPaymentPage={setShowPaymentPage}
          proposalId={proposalId}
        />
      )}
    </>
  );
};
