import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { useSearchParams } from "react-router-dom";
import { addPayment, verifyPayment } from "../services";
import { VerificationModal } from "./VerificationModal";
import { postReferral } from "./CommonActions";
import { AuthContext } from "../helpers/AuthContext";

export const Verification = () => {
  const [status, setStatus] = useState("");
  const [reference, setReference] = useState("");
  const [isVerification, setIsVerification] = useState(false);
  const [responseData, setResponseData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { personalInfo } = useContext(AuthContext);

  let [queryParameters] = useSearchParams();

  const accessToken = sessionStorage.getItem("token");

  useEffect(() => {
    setStatus(queryParameters.get("status"));
    setReference(queryParameters.get("reference"));

    const verify = async () => {
      setIsLoading(true);
      const response = await fetch(
        `https://produpgrade.fbninsurance.co/api/v1/orders/${queryParameters.get(
          "reference"
        )}`,
        {
          method: "GET",
          Accept: "application/json",
          "content-type": "application/json",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      ).then((res) => res.json());

      if (response?.status === "success") {
        const modifiedRef = response?.data?.transaction_id.substring(12);
        const details = sessionStorage.getItem("details");
        const parsedDetails = JSON.parse(details);

        addPayment(response?.data).then((res) => {
          setResponseData(response);
          setIsLoading(false);
          setIsVerification(true);
        });

        await postReferral(
          modifiedRef,
          parsedDetails?.referralCode !== ""
            ? parsedDetails?.referralCode
            : "DIRECT"
        ).then((res) => {
          console.log(res);
        });
      } else {
        console.log("something went wrong");
      }
    };

    verify();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <h1 style={{ color: "#0064a3" }}>
        {isLoading ? "Verifying payment..." : ""}
      </h1>

      <VerificationModal
        isVerification={isVerification}
        setIsVerification={setIsVerification}
        responseData={responseData}
      />
    </div>
  );
};
