import React, { useContext, useState } from "react";
import { Form, OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap";
import { GoInfo } from "react-icons/go";
import { AuthContext } from "../../helpers/AuthContext";
import { addQuote, getProductQuote } from "../../services";
import { useAlert } from "react-alert";
import { GrClose } from "react-icons/gr";
import { BiPencil } from "react-icons/bi";
import { numFormat } from "../CommonActions";
import { IoAlarmOutline } from "react-icons/io5";

export const QuoteCalculation = ({
  minDuration,
  maxDuration,
  availableAmounts,
  productId,
  productCode,
  handleChange,
  costPerUnit,
}) => {
  const {
    steps,
    setSteps,
    setQuote,
    quoteForm,
    personalInfo,
    prods,
    setProds,
  } = useContext(AuthContext);

  const [isGenerating, setIsGenerating] = useState(false);
  const [showDependencies, setShowDependencies] = useState(false);
  const [showFlpQuotes, setShowFlpQuotes] = useState(false);

  // const [prods, setProds] = useState([]);
  const {
    sofData,
    benefitsData,
    uniqueTypes,
    dependantInfo,
    setDependantInfo,
    dependants,
    setDependants,
    dependantUploadInfo,
    setDependantUploadInfo,
  } = useContext(AuthContext);

  const alert = useAlert();

  const widerFamilyAmounts = [
    { premium: "0.00", sum_assured: "500000.00" },
    { premium: "0.00", sum_assured: "1000000.00" },
  ];

  const getQuote = () => {
    try {
      setIsGenerating(true);

      const commonParams = {
        product_id: productId,
        calculation_type: quoteForm.item,
        assured_date_of_birth: personalInfo.dob,
        amount: productCode !== "FLSP" ? quoteForm.itemDetail : 500000,
        policy_duration:
          productCode === "FLPP" ||
          productCode === "FLPI" ||
          productCode === "FLPC"
            ? 99
            : quoteForm.duration,
        payment_frequency: quoteForm.frequency,
      };

      let params;

      if (productCode === "GLRP") {
        params = {
          ...commonParams,
          source_of_fund: quoteForm.source_of_fund,
          benefit_type: quoteForm.benefit_type,
          benefit: quoteForm.benefit,
          guaranteed_period: `${quoteForm.guaranteed_period}-years`,
          purchase_amount: quoteForm.purchase_amount,
        };
      } else if (
        productCode === "FLSP" ||
        productCode === "FLPP" ||
        productCode === "FLPC" ||
        productCode === "FLPI"
      ) {
        params = {
          ...commonParams,
          contribution:
            productCode === "FLPP" ||
            productCode === "FLPC" ||
            productCode === "FLPI"
              ? 0
              : quoteForm.contribution,
          beneficiaries: apiPayload,
        };
      } else {
        params = commonParams;
      }

      if (
        productCode === "FLPP" ||
        productCode === "FLPC" ||
        productCode === "FLPI"
      ) {
        params = {
          ...commonParams,
          waivers: [{ name: "basic-premium", units: quoteForm.itemDetail }],
          beneficiaries: apiPayload,
        };
      }

      getProductQuote(params).then((res) => {
        if (res.status && res.status === "success") {
          setQuote(res);
          sessionStorage.setItem(
            "quoteInfo",
            JSON.stringify({
              fullname: quoteForm.fullname,
              phone: quoteForm.phone,
              email: quoteForm.email,
              frequencyOfPayment: res.data.frequency_of_payment,
              amount: res.data.premium,
              policyDuration: res.data.policy_duration,
              product: res.data.product.name,
              quoteId: res.data.id,
            })
          );

          addQuote({
            ...res,
            email: personalInfo.email,
            firstName: personalInfo.firstName,
          }).then((res) => {
            if (res.status && res.status === "success") {
              sessionStorage.setItem(
                "person",
                JSON.stringify({
                  firstName: quoteForm.firstName,
                  middleName: quoteForm.middleName,
                  lastName: quoteForm.lastName,
                  phone: quoteForm.phone,
                  email: quoteForm.email,
                  dob: quoteForm.dob,
                  title: quoteForm.title,
                })
              );
              setSteps(steps + 1);
              alert.success(res.message);
            } else {
              alert.error("Something went wrong");
            }
            setIsGenerating(false);
          });
          return;
        }
        // setErrorMsg(res.response.data.message);
        alert.error(res.response.data.message);
        setIsGenerating(false);
      });
    } catch (error) {
      console.log(error);
      alert.error("Something went wrong.");
      setIsGenerating(false);
    }
  };

  // const getFlpQuote = async () => {
  //   const prodId = [{ id: 40 }, { id: 41 }, { id: 42 }];

  //   try {
  //     setIsGenerating(true);

  //     prodId.forEach(async (prod) => {
  //       let amount;

  //       if (productCode !== "FLSP" && prod?.id === 41) {
  //         amount = quoteForm.itemDetail;
  //       } else if (productCode !== "FLSP" && prod?.id === 42) {
  //         amount = quoteForm.itemDetail;
  //       } else if (productCode !== "FLSP") {
  //         amount = quoteForm.itemDetail;
  //       } else {
  //         amount = 500000;
  //       }

  //       const commonParams = {
  //         product_id: prod?.id,
  //         calculation_type: quoteForm.item,
  //         assured_date_of_birth: personalInfo.dob,
  //         amount: amount,
  //         payment_frequency: quoteForm.frequency,
  //         policy_duration: productCode === "FLP" ? 99 : quoteForm.duration,
  //       };

  //       let params = { ...commonParams };

  //       if (productCode === "GLRP") {
  //         params = {
  //           ...params,
  //           source_of_fund: quoteForm.source_of_fund,
  //           benefit_type: quoteForm.benefit_type,
  //           benefit: quoteForm.benefit,
  //           guaranteed_period: `${quoteForm.guaranteed_period}-years`,
  //           purchase_amount: quoteForm.purchase_amount,
  //         };
  //       } else if (productCode === "FLSP" || productCode === "FLP") {
  //         params = {
  //           ...params,
  //           contribution: productCode === "FLP" ? 0 : quoteForm.contribution,
  //           beneficiaries: apiPayload,
  //         };
  //       }

  //       if (productCode === "FLP") {
  //         params.waivers = [
  //           { name: "basic-premium", units: quoteForm.itemDetail },
  //         ];
  //       }

  //       const res = await getProductQuote(params);
  //       // prods.push(res);
  //       setSteps(steps + 1);
  //       setProds([...prods, res]);
  //       console.log("Prods", prods);

  //       // if (res.status && res.status === "success") {
  //       //   setQuote(res);

  //       //   const quoteInfo = {
  //       //     fullname: quoteForm.fullname,
  //       //     phone: quoteForm.phone,
  //       //     email: quoteForm.email,
  //       //     frequencyOfPayment: res.data.frequency_of_payment,
  //       //     amount: res.data.premium,
  //       //     policyDuration: res.data.policy_duration,
  //       //     product: res.data.product.name,
  //       //     quoteId: res.data.id,
  //       //   };

  //       //   sessionStorage.setItem("quoteInfo", JSON.stringify(quoteInfo));
  //       //   console.log(res);

  //       //   const quoteResponse = await addQuote({
  //       //     ...res,
  //       //     email: personalInfo.email,
  //       //     firstName: personalInfo.firstName,
  //       //   });

  //       //   if (quoteResponse.status && quoteResponse.status === "success") {
  //       //     const personInfo = {
  //       //       firstName: quoteForm.firstName,
  //       //       middleName: quoteForm.middleName,
  //       //       lastName: quoteForm.lastName,
  //       //       phone: quoteForm.phone,
  //       //       email: quoteForm.email,
  //       //       dob: quoteForm.dob,
  //       //       title: quoteForm.title,
  //       //     };
  //       //     sessionStorage.setItem("person", JSON.stringify(personInfo));
  //       //     setSteps(steps + 1);
  //       //     alert.success(quoteResponse.message);
  //       //   } else {
  //       //     alert.error("Something went wrong");
  //       //   }
  //       // } else {
  //       //   alert.error(res.response.data.message);
  //       // }
  //     });
  //   } catch (error) {
  //     console.error(error);
  //     alert.error("Something went wrong.");
  //   } finally {
  //     setIsGenerating(false);
  //   }
  // };

  const getFlpQuote = async () => {
    const prodId = [{ id: 40 }, { id: 41 }, { id: 42 }];

    try {
      setProds([]);
      setIsGenerating(true);

      for (const prod of prodId) {
        let amount;

        if (productCode !== "FLSP" && prod.id === 41) {
          amount = quoteForm.itemDetail;
        } else if (productCode !== "FLSP" && prod?.id === 42) {
          amount = quoteForm.itemDetail;
        } else if (productCode !== "FLSP") {
          amount = quoteForm.itemDetail;
        } else {
          amount = 500000;
        }

        console.log(prod?.id, amount);

        const commonParams = {
          product_id: prod?.id,
          calculation_type: quoteForm.item,
          assured_date_of_birth: personalInfo.dob,
          amount: amount,
          payment_frequency: quoteForm.frequency,
          policy_duration: productCode === "FLP" ? 99 : quoteForm.duration,
        };

        let params = { ...commonParams };

        if (productCode === "GLRP") {
          params = {
            ...params,
            source_of_fund: quoteForm.source_of_fund,
            benefit_type: quoteForm.benefit_type,
            benefit: quoteForm.benefit,
            guaranteed_period: `${quoteForm.guaranteed_period}-years`,
            purchase_amount: quoteForm.purchase_amount,
          };
        } else if (productCode === "FLSP" || productCode === "FLP") {
          params = {
            ...params,
            contribution: productCode === "FLP" ? 0 : quoteForm.contribution,
            beneficiaries: apiPayload,
          };
        }

        let units;

        if (prod?.id === 41) {
          units = quoteForm.itemDetail;
          // units = parseInt(quoteForm.itemDetail) + 2;
        } else {
          units = quoteForm.itemDetail;
        }

        if (productCode === "FLP") {
          params.waivers = [{ name: "basic-premium", units: units }];
        }

        const res = await getProductQuote(params);

        setProds((prevProds) => [...prevProds, res]);
      }
    } catch (error) {
      console.error(error);
      alert.error("Something went wrong.");
    } finally {
      setIsGenerating(false);
      setSteps(steps + 1);
    }
  };

  function premiumPairs(availableAmounts) {
    return (
      <Form.Select
        type="text"
        placeholder="select your answer"
        name="itemDetail"
        value={quoteForm.itemDetail}
        onChange={handleChange}
      >
        <option value="">select an option</option>
        {availableAmounts.map((option, index) => (
          <option key={index} value={parseFloat(option.premium)}>
            {numFormat(option.premium)}
          </option>
        ))}
      </Form.Select>
    );
  }
  function sumAssuredPairs(value, name, onChange, availableAmounts) {
    return (
      <Form.Select
        type="text"
        placeholder="select your answer"
        name={name}
        value={value}
        onChange={onChange}
      >
        <option value="">select an option</option>
        {availableAmounts.map((option, index) => (
          <option
            key={index}
            value={
              productCode === "FLP"
                ? index + 1
                : productCode === "FLPI"
                ? parseInt(index) + 6
                : productCode === "FLPC"
                ? parseInt(index) + 6
                : option.sumAssured
            }
          >
            {numFormat(option.sum_assured)}
          </option>
        ))}
      </Form.Select>
    );
  }

  function widerPairs(value, name, onChange, availableAmounts) {
    return (
      <Form.Select
        type="text"
        placeholder="select your answer"
        name={name}
        value={value}
        onChange={onChange}
      >
        <option value="">select an option</option>
        {widerFamilyAmounts.map((option, index) => (
          <option key={index} value={index + 1}>
            {numFormat(option.sum_assured)}
          </option>
        ))}
      </Form.Select>
    );
  }

  function mainSumAssuredPairs(value, name, onChange, availableAmounts) {
    return (
      <Form.Select
        type="text"
        placeholder="select your answer"
        name={name}
        value={value}
        onChange={onChange}
      >
        <option value="">select an option</option>
        {availableAmounts.map((option, index) => (
          <option
            key={index}
            value={
              productCode === "FLP"
                ? index + 1
                : productCode === "FLPI"
                ? parseInt(index) + 6
                : productCode === "FLPC"
                ? parseInt(index) + 6
                : option.sumAssured
            }
          >
            {numFormat(option.sum_assured)}
          </option>
        ))}
      </Form.Select>
    );
  }
  const options = [];
  for (let i = minDuration; i <= maxDuration; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  let filteredDuration =
    options.length > 0 && options.filter((option) => option.key > 0);

  function selectBenefitType(item) {
    return (
      <Form.Select
        type="text"
        placeholder="select your answer"
        name="benefit_type"
        value={quoteForm.benefit_type}
        onChange={handleChange}
      >
        <option value="">select an option</option>
        {item.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Form.Select>
    );
  }

  let benefits = benefitsData.data.filter(
    (benefit) => benefit.type === quoteForm.benefit_type
  );

  function selectBenefit(item) {
    return (
      <Form.Select
        type="text"
        placeholder="select your answer"
        name="benefit"
        value={quoteForm.benefit}
        onChange={handleChange}
      >
        <option value="">select an option</option>
        {item.map((option) => (
          <option key={option.id} value={option.key}>
            {option.name}
          </option>
        ))}
      </Form.Select>
    );
  }

  function selectSof(item) {
    return (
      <Form.Select
        type="text"
        placeholder="select your answer"
        name="source_of_fund"
        value={quoteForm.source_of_fund}
        onChange={handleChange}
      >
        <option value="">select an option</option>
        {item.map((option) => (
          <option key={option.id} value={option.key}>
            {option.name}
          </option>
        ))}
      </Form.Select>
    );
  }

  const handleModalEnter = () => {
    const modalBody = document.querySelector(".modal-content");
    modalBody.scrollTop = 0;
  };

  const handleSubmit = () => {
    getQuote();
    handleModalEnter();
    return;
  };

  const handleDependantChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "age") {
      const dob = new Date(value);
      const ageInMilliseconds = Date.now() - dob.getTime();
      const ageDate = new Date(ageInMilliseconds);
      const age = Math.abs(ageDate.getUTCFullYear() - 1970);
      setDependantInfo({ ...dependantInfo, [name]: age, dob: value });
    } else {
      setDependantInfo({ ...dependantInfo, [name]: value });
    }

    console.log(name, value);
  };

  const handleAddDependant = () => {
    if (
      dependantInfo.beneficiary === "" ||
      dependantInfo.name === "" ||
      dependantInfo.dob === "" ||
      dependantInfo.sumAssured === ""
    ) {
      alert.error("please fill in all fields");
      return;
    }
    const id = Math.random().toString(36).substr(2, 9);
    dependants.push({
      id: id,
      beneficiary: dependantInfo.beneficiary,
      age: dependantInfo.age,
      name: dependantInfo.name,
      dob: dependantInfo.dob,
      medicals: {
        medQ1: "",
        medQ2: "",
        medQ3: "",
        medQ4: "",
        medQ5: "",
        medQ6: "",
        medQ7: "",
        medQ8: "",
        medQ9: "",
        medQ10: "",
        medQ11: "",
        medQ11Text: "",
        medQ12: "",
        medQ12Text: "",
        height: "",
        weight: "",
        doctorName: "",
        doctorPhone: "",
        hospitalName: "",
        hospitalAddress: "",
        doctorDuration: "",
      },
    });
    dependantUploadInfo.push({
      id: id,
      beneficiary: dependantInfo.beneficiary,
      age: dependantInfo.age,
      name: dependantInfo.name,
      unit: dependantInfo.sumAssured,
    });

    setDependantInfo((prevDependant) => ({
      ...prevDependant,
      beneficiary: "",
      name: "",
      age: "",
      dob: "",
      sumAssured: "",
    }));

    console.log(dependantInfo);
  };

  const handleClearDependantForm = () => {
    setDependantInfo((prevDependant) => ({
      ...prevDependant,
      beneficiary: "",
      name: "",
      age: "",
      dob: "",
      sumAssured: "",
    }));
  };

  const apiPayload = dependantUploadInfo.map(
    ({ name, beneficiary, age, unit }) => ({
      name,
      beneficiary,
      age,
      waivers: [
        {
          name: "basic-premium",
          units: unit,
        },
      ],
    })
  );

  const handleRemoveDependants = (id) => {
    const newDependants = dependants.filter((dependant) => dependant.id !== id);
    const newInfo = dependantUploadInfo.filter((info) => info.id !== id);

    setDependantUploadInfo(newInfo);
    setDependants(newDependants);
  };

  function filterArray(inputValue) {
    if (productCode === "FLPP" && inputValue === "spouse") {
      return availableAmounts.filter(function (item) {
        return parseFloat(item.sum_assured) >= 3000000.0;
      });
    } else if (productCode === "FLPP" && inputValue !== "spouse") {
      return availableAmounts.filter(function (item) {
        return parseFloat(item.sum_assured) === 1000000.0;
      });
    } else if (productCode === "FLPC" && inputValue !== "spouse") {
      return availableAmounts.filter(function (item) {
        return parseFloat(item.sum_assured) === 1000000.0;
      });
    } else {
      return availableAmounts;
    }
  }

  function mainFilterArray() {
    if (productCode === "FLPP") {
      return availableAmounts.filter(function (item) {
        return parseFloat(item.sum_assured) >= 3000000.0;
      });
    } else {
      return availableAmounts;
    }
  }

  return (
    <Form>
      {!showDependencies && (
        <>
          <div className="form-info">
            <p>Quote Calculation</p>
          </div>

          <Row
            className="mb-1 container-fluid mx-auto"
            style={{ maxWidth: "800px" }}
          >
            <Form.Group
              className="mb-4"
              as={Col}
              md={6}
              controlId="formFullName"
            >
              <Form.Label
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "row",
                }}
              >
                What do you want to calculate?{" "}
                <span className="asterik">*</span>
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      <p style={{ textAlign: "left", fontSize: 11 }}>
                        <strong>“Sum Assured"</strong> refers to the final
                        amount that an individual wants to receive at the end of
                        their policy.
                      </p>
                      <p style={{ textAlign: "left", fontSize: 11 }}>
                        <strong>"Premium"</strong> refers to the amount an
                        individual wishes to pay every month for a certain
                        period.
                      </p>
                    </Tooltip>
                  }
                >
                  <p
                    style={{
                      marginBottom: 0,
                      marginLeft: 6,
                    }}
                  >
                    <GoInfo />
                  </p>
                </OverlayTrigger>
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                size="sm"
                onChange={handleChange}
                name="item"
                value={quoteForm.item}
              >
                <option>select your answer</option>
                <option value="premium">Premium</option>
                {productCode !== "FLSP" &&
                  productCode !== "FLP" &&
                  productCode !== "FLPC" &&
                  productCode !== "FLPI" && (
                    <option value="sum assured">Sum Assured</option>
                  )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please select an answer
              </Form.Control.Feedback>
            </Form.Group>
            {productCode !== "FLSP" ? (
              <Form.Group
                className="mb-4"
                as={Col}
                md={6}
                controlId="formPhoneNumber"
              >
                {quoteForm.item !== "" &&
                  quoteForm.item !== "select your answer" && (
                    <>
                      <Form.Label>
                        {quoteForm.item === "sum assured"
                          ? "What is your premium"
                          : "What is the main life sum assured"}{" "}
                        <span className="asterik">*</span>
                      </Form.Label>
                      {availableAmounts.length < 1 ? (
                        <Form.Control
                          type="text"
                          placeholder="enter an amount"
                          name="itemDetail"
                          value={quoteForm.itemDetail}
                          onChange={handleChange}
                        />
                      ) : quoteForm.item === "sum assured" ? (
                        premiumPairs(availableAmounts)
                      ) : (
                        mainSumAssuredPairs(
                          quoteForm.itemDetail,
                          "itemDetail",
                          handleChange,
                          mainFilterArray()
                        )
                      )}
                    </>
                  )}
              </Form.Group>
            ) : (
              quoteForm.item === "premium" && (
                <Form.Group className="mb-4" as={Col} md={6}>
                  <Form.Label>Death Sum Assured</Form.Label>
                  {/* <p>N500,000</p> */}
                  <Form.Control
                    type="text"
                    value="N500,000"
                    disabled
                    style={{ fontWeight: 700 }}
                  />
                </Form.Group>
              )
            )}
            {productCode !== "FLP" && (
              <Form.Group
                className="mb-4"
                as={Col}
                md={6}
                controlId="formBasicEmail"
              >
                <Form.Label>
                  How long do you want to save (years)?{" "}
                  <span className="asterik">*</span>
                </Form.Label>
                {filteredDuration.length > 0 ? (
                  <Form.Select
                    aria-label="Default select example"
                    size="sm"
                    onChange={handleChange}
                    name="duration"
                    value={quoteForm.duration}
                  >
                    <option>select your answer</option>
                    {filteredDuration}
                  </Form.Select>
                ) : (
                  <Form.Control
                    type="text"
                    placeholder="10"
                    name="duration"
                    onChange={handleChange}
                  />
                )}
              </Form.Group>
            )}

            <Form.Group
              className="mb-4"
              as={Col}
              md={
                productCode !== "FLPP" &&
                productCode !== "FLPC" &&
                productCode !== "FLPI"
                  ? 6
                  : 12
              }
              controlId="formDateOfBirth"
            >
              <Form.Label>
                How often do you want to pay? <span className="asterik">*</span>
              </Form.Label>

              <Form.Select
                aria-label="Default select example"
                size="sm"
                onChange={handleChange}
                name="frequency"
                value={quoteForm.frequency}
              >
                <option>select your answer</option>
                <option value="monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
                <option value="half-annually">Half-anually</option>
                <option value="annually">Anually</option>
              </Form.Select>
            </Form.Group>

            {productCode === "FLSP" && (
              <Form.Group
                className="mb-4"
                as={Col}
                md={12}
                controlId="formContribution"
              >
                <Form.Label>
                  How much do you want to pay? (Contribution){" "}
                  <span className="asterik">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="2000"
                  name="contribution"
                  value={quoteForm.contribution}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
            {productCode === "GLRP" && (
              <>
                <Form.Group
                  className="mb-4"
                  as={Col}
                  md={6}
                  controlId="formFullName"
                >
                  <Form.Label>
                    Source of fund <span className="asterik">*</span>
                  </Form.Label>
                  {selectSof(sofData.data)}
                </Form.Group>

                <Form.Group
                  className="mb-4"
                  as={Col}
                  md={6}
                  controlId="formPhoneNumber"
                >
                  <Form.Label>
                    Guaranteed period <span className="asterik">*</span>
                  </Form.Label>
                  <Form.Control
                    type="phone"
                    placeholder="10"
                    name="guaranteed_period"
                    value={quoteForm.guaranteed_period}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-4"
                  as={Col}
                  md={6}
                  controlId="formFullName"
                >
                  <Form.Label>
                    Benefit type <span className="asterik">*</span>
                  </Form.Label>
                  {selectBenefitType(uniqueTypes)}
                </Form.Group>

                <Form.Group
                  className="mb-4"
                  as={Col}
                  md={6}
                  controlId="formPhoneNumber"
                >
                  <Form.Label>
                    Benefit <span className="asterik">*</span>
                  </Form.Label>
                  {selectBenefit(benefits)}
                </Form.Group>

                <Form.Group
                  className="mb-4"
                  as={Col}
                  md={12}
                  controlId="formContribution"
                >
                  <Form.Label>
                    Purchase Amount <span className="asterik">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="600000"
                    name="purchase_amount"
                    value={quoteForm.purchase_amount}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            )}
          </Row>

          <button
            className="blue-button"
            style={{ minWidth: 100, marginTop: 15 }}
            onClick={(e) => {
              console.log(productCode);
              e.preventDefault();
              if (
                productCode !== "FLSP" &&
                productCode !== "FLP"
                // productCode !== "FLPC" &&
                // productCode !== "FLPI"
              ) {
                handleSubmit();
                return;
              } else {
                setShowDependencies(true);
              }
            }}
          >
            {isGenerating ? "Generating Quote..." : "Next"}
          </button>
        </>
      )}

      {showDependencies && (
        <>
          <div className="form-info">
            <p>Dependants</p>
          </div>
          <Row
            className="mb-1 container-fluid mx-auto"
            style={{ maxWidth: "800px" }}
          >
            <Form.Group className="mb-4" as={Col} md={6}>
              <Form.Label
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "row",
                }}
              >
                Who would you like to cover?
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      <p style={{ textAlign: "left", fontSize: 13 }}>
                        Apart from your own investment, is there anyone else for
                        whom you would like to obtain insurance coverage in the
                        event of death?
                      </p>
                    </Tooltip>
                  }
                >
                  <p
                    style={{
                      marginBottom: 0,
                      marginLeft: 6,
                    }}
                  >
                    <GoInfo />
                  </p>
                </OverlayTrigger>
              </Form.Label>
              <Form.Select
                name="beneficiary"
                value={dependantInfo.beneficiary}
                onChange={handleDependantChange}
              >
                <option></option>
                <option value="spouse">Spouse</option>
                <option value="father-in-law">Father-In-Law</option>
                <option value="mother-in-law">Mother-In-Law</option>
                <option value="father">Father</option>
                <option value="mother">Mother</option>

                {productCode === "FLP" && (
                  <>
                    <option value="child">Child</option>
                    <option value="great-grandparent">Great Grandparent</option>
                    <option value="grandparent">Grandparent</option>
                    <option value="aunt">Aunt</option>
                    <option value="uncle">Uncle</option>
                    <option value="brother">Brother</option>
                    <option value="sister">Sister</option>
                    <option value="brother-in-law">Brother-in-law</option>
                    <option value="sister-in-law">Sister-in-law</option>
                    <option value="son-in-law">Son-in-law</option>
                    <option value="daughter-in-law">Daughter-in-law</option>
                    <option value="nephew">Nephew</option>
                    <option value="niece">Niece</option>
                    <option value="grandchild">Grandchild</option>
                    <option value="great-grandchild">Great Grandchild</option>
                    <option value="first-cousin">First Cousin</option>
                  </>
                )}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md={6}>
              <Form.Label>Assured Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Efe Samuel"
                name="name"
                value={dependantInfo.name}
                onChange={handleDependantChange}
              />
            </Form.Group>

            <Form.Group className="mb-4" as={Col} md={12}>
              <Form.Label>Assured date of birth</Form.Label>
              <Form.Control
                type="date"
                placeholder="DD/MM/YYYY"
                name="age"
                value={dependantInfo.dob}
                onChange={handleDependantChange}
              />
            </Form.Group>

            <Form.Group className="mb-4" as={Col} md={12}>
              <Form.Label>Select sum assured</Form.Label>

              {productCode === "FLSP" ? (
                <Form.Select
                  name="sumAssured"
                  value={dependantInfo.sumAssured}
                  onChange={handleDependantChange}
                >
                  <option></option>
                  {dependantInfo.beneficiary !== "spouse" && (
                    <option value="250000">250,000</option>
                  )}
                  {dependantInfo.beneficiary === "spouse" && (
                    <option value="500000">500,000</option>
                  )}
                </Form.Select>
              ) : productCode === "FLP" &&
                dependantInfo?.beneficiary !== "spouse" ? (
                widerPairs(
                  dependantInfo.sumAssured,
                  "sumAssured",
                  handleDependantChange,
                  filterArray(dependantInfo?.beneficiary)
                )
              ) : (
                sumAssuredPairs(
                  dependantInfo.sumAssured,
                  "sumAssured",
                  handleDependantChange,
                  filterArray(dependantInfo?.beneficiary)
                )
              )}
            </Form.Group>

            <div className="add-beneficiary-container">
              <div>
                <button
                  className="save-dependants"
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddDependant();
                  }}
                >
                  Save Dependant
                </button>
                <button
                  style={{
                    marginLeft: "8px",
                    color: "red",
                    backgroundColor: "pink",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClearDependantForm();
                  }}
                >
                  Clear form
                </button>
              </div>

              <span style={{ fontSize: "0.7rem" }}>
                NB: Please click "Save" to add beneficiary before proceeding{" "}
              </span>
            </div>

            <div className="dependants-container">
              <p style={{ marginBottom: "5px" }}>
                <strong>List of dependants:</strong>
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "15px",
                  flexWrap: "wrap",
                }}
              >
                {dependants.length > 0 &&
                  dependants.map((dependant) => {
                    return (
                      <p
                        style={{
                          backgroundColor: "#b0b0b0",
                          color: "#000",
                          border: "none",
                          borderRadius: "6px",
                          padding: "6px",
                          fontSize: "0.7rem",
                          fontWeight: 500,
                          marginBottom: 0,
                        }}
                        key={dependant.id}
                      >
                        {dependant.name} | {dependant.beneficiary} |{" "}
                        {dependant.age} years
                        <BiPencil
                          onClick={() => {
                            handleRemoveDependants(dependant.id);
                            setDependantInfo({
                              ...dependantInfo,
                              beneficiary: dependant.beneficiary,
                              name: dependant.name,
                              dob: dependant.dob,
                              age: dependant.age,
                              sumAssured: dependant.sumAssured,
                            });
                          }}
                          style={{
                            marginRight: "7px",
                            marginLeft: "4px",
                          }}
                        />{" "}
                        <GrClose
                          onClick={() => {
                            handleRemoveDependants(dependant.id);
                          }}
                        />
                      </p>
                    );
                  })}
              </div>
            </div>
          </Row>
          <button
            className="blue-button"
            onClick={(e) => {
              e.preventDefault();
              if (
                dependantInfo.beneficiary !== "" ||
                dependantInfo.name !== "" ||
                dependantInfo.dob !== "" ||
                dependantInfo.sumAssured !== ""
              ) {
                alert.error(
                  "Please save dependant or clear form before proceeding"
                );
                return;
              }
              productCode === "FLP" ? getFlpQuote() : getQuote();
            }}
          >
            {isGenerating ? "Generating Quote..." : "Get Quote"}
          </button>
        </>
      )}
    </Form>
  );
};
