import "./App.css";
import { Homepage } from "./pages/Homepage";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ProductPage } from "./pages/ProductPage";
import { useQuery } from "react-query";
import { useState } from "react";
import { AuthContext } from "./helpers/AuthContext";
import CookieConsent, { Cookies } from "react-cookie-consent";
import {
  getBanks,
  getBenefits,
  getSourcesOfFund,
  getStates,
  getReligions,
  getRelationships,
  getBusinessTypes,
  getOccupations,
  getTitles,
  getCities,
  auth,
} from "./services";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { Verification } from "./components/Verification";

function App() {
  const [showSummary, setShowSummary] = useState(false);
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [token, setToken] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [steps, setSteps] = useState(1);
  const [quote, setQuote] = useState([]);
  const [proposalId, setProposalId] = useState(0);
  const [isLoadingProposal, setIsLoadingProposal] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [formPage, setFormPage] = useState(1);
  const [isUploadPage, setIsUploadPage] = useState(false);
  const [idImage, setIdImage] = useState("");
  const [passportImage, setPassportImage] = useState("");
  const [addressImage, setAddressImage] = useState("");
  const [signatureImage, setSignatureImage] = useState("");
  const [uploadType, setUploadType] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [totalProportions, setTotalProportions] = useState(0);
  const [personalInfoValidated, setPersonalInfoValidated] = useState(false);
  const [proposalDetails, setProposalDetails] = useState([]);
  const [dependants, setDependants] = useState([]);
  const [dependantUploadInfo, setDependantUploadInfo] = useState([]);

  const [dependantInfo, setDependantInfo] = useState({
    id: "",
    beneficiary: "",
    name: "",
    dob: "",
    age: "",
    sumAssured: "",
  });

  const [quoteForm, setQuoteForm] = useState({
    fullname: "",
    phone: "",
    email: "",
    title: "",
    dob: "",
    item: "",
    itemDetail: "",
    duration: "",
    frequency: "",
    contribution: "",
    source_of_fund: "",
    guaranteed_period: "",
    benefit_type: "",
    benefit: "",
    purchase_amount: "",
  });
  const [personalInfo, setPersonalInfo] = useState({
    // fullname: parsedAssured.fullname,
    firstName: "",
    middleName: "",
    lastName: "",
    title: "",
    phone: "",
    dob: "",
    state: "",
    city: "",
    email: "",
    gender: "",
    address: "",
    maritalStatus: "",
    occupation: "",
    businessTypes: "",
    religion: "",
    bankType: "No",
    selectBank: null,
    usCitizen: "No",
    premiumPayment: "Card",
    commencementDate: "",
    ddBank: "",
    ddAccountName: "",
    ddAccountNumber: "",
    ddAccountType: "",
    accountNumber: "",
    accountType: "",
    cardNumber: "",
    inflation: "",
    issuanceDate: "",
    expirationDate: "",
    idCardType: "",
    bvn: "",
    hearAboutUs: "",
    haveReferralCode: "",
    referralCode: "",
    publicOffice: "No",
    uploadIdNum: "",
    medQ1: "No",
    medQ2: "No",
    medQ3: "No",
    medQ4: "No",
    medQ5: "No",
    medQ6: "No",
    medQ7: "No",
    medQ8: "No",
    medQ9: "No",
    medQ10: "No",
    medQ11: "No",
    medQ11Text: "",
    medQ12: "No",
    medQ12Text: "",
    height: "",
    weight: "",
    doctorName: "",
    doctorPhone: "",
    hospitalName: "",
    hospitalAddress: "",
    doctorDuration: "",
    sourceOfFund: "",
  });

  const [beneficiaryInfo, setBeneficiaryInfo] = useState({
    numOfBeneficiaries: 0,
    beneficiaryFullName: "",
    beneficiaryDob: "",
    beneficiaryRelationship: "",
    beneficiaryPhone: "",
    beneficiaryState: "",
    beneficiaryGender: "",
    beneficiaryAddress: "",
    beneficiaryProportion: "",
    beneficiaryGuardianName: "",
    beneficiaryGuardianPhone: "",
    beneficiaryCity: "",
  });
  const [beneficiaries, setBeneficiaries] = useState([]);

  const { data: sofData } = useQuery(
    "sourcesOfFund",
    () => getSourcesOfFund(),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const { data: authData, isLoading: isAuthLoading } = useQuery(
    "auth",
    () =>
      auth().then((res) => {
        sessionStorage.setItem("token", res.data.access_token);
      }),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const { data: benefitsData } = useQuery("benefits", () => getBenefits(), {
    onError: (error) => {
      console.log(error);
    },
  });
  const { data: statesData } = useQuery("states", () => getStates(), {
    onError: (error) => {
      console.log(error);
    },
  });
  const { data: banksData } = useQuery("banks", () => getBanks(), {
    onError: (error) => {
      console.log(error);
    },
  });
  const { data: religionsData } = useQuery("religions", () => getReligions(), {
    onError: (error) => {
      console.log(error);
    },
  });
  const { data: relationshipsData } = useQuery(
    "relationships",
    () => getRelationships(),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const { data: businessTypesData } = useQuery(
    "businessTypes",
    () => getBusinessTypes(),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const { data: occupationsData } = useQuery(
    "occupations",
    () => getOccupations(),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const { data: titlesData } = useQuery("titles", () => getTitles(), {
    onError: (error) => {
      console.log(error);
    },
  });
  const { data: citiesData } = useQuery("cities", () => getCities(), {
    onError: (error) => {
      console.log(error);
    },
  });

  const uniqueTypes =
    benefitsData &&
    benefitsData.data.reduce((types, item) => {
      if (!types.includes(item.type)) {
        types.push(item.type);
      }
      return types;
    }, []);

  const options = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: "30px",
    transition: transitions.SCALE,
  };

  // const prods = [];
  const [prods, setProds] = useState([]);
  const prodFound = prods?.filter((product) => product?.status === "success");

  return (
    <AuthContext.Provider
      value={{
        prodFound,
        prods,
        setProds,
        showSummary,
        setShowSummary,
        sofData,
        benefitsData,
        uniqueTypes,
        statesData,
        banksData,
        titlesData,
        citiesData,
        religionsData,
        relationshipsData,
        businessTypesData,
        occupationsData,
        showPaymentPage,
        setShowPaymentPage,
        authData,
        isAuthLoading,
        token,
        setToken,
        smShow,
        setSmShow,
        fullscreen,
        setFullscreen,
        steps,
        setSteps,
        quote,
        setQuote,
        proposalId,
        setProposalId,
        isLoadingProposal,
        setIsLoadingProposal,
        isGenerating,
        setIsGenerating,
        errorMsg,
        setErrorMsg,
        formPage,
        setFormPage,
        isUploadPage,
        setIsUploadPage,
        idImage,
        setIdImage,
        addressImage,
        setAddressImage,
        signatureImage,
        setSignatureImage,
        uploadType,
        setUploadType,
        isUploading,
        setIsUploading,
        totalProportions,
        setTotalProportions,
        quoteForm,
        setQuoteForm,
        personalInfo,
        setPersonalInfo,
        beneficiaryInfo,
        setBeneficiaryInfo,
        beneficiaries,
        setBeneficiaries,
        personalInfoValidated,
        setPersonalInfoValidated,
        proposalDetails,
        setProposalDetails,
        passportImage,
        setPassportImage,
        dependantInfo,
        setDependantInfo,
        dependants,
        setDependants,
        dependantUploadInfo,
        setDependantUploadInfo,
      }}
    >
      <AlertProvider template={AlertTemplate} {...options}>
        <div className="App">
          <Router>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/product/:abbr" element={<ProductPage />} />
              <Route path="/verification" element={<Verification />} />
            </Routes>
          </Router>
        </div>
      </AlertProvider>
      <CookieConsent
        location="bottom"
        buttonText="I Understand"
        cookieName="consent"
        style={{
          background: "#007dcc",
          textAlign: "center",
          fontSize: "0.8rem",
        }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px", fontWeight: "bold" }}
        expires={150}
      >
        This website uses cookies to offer you the most relevant information. By
        continuing to browse the website, you are agreeing to use our site
        cookies.{" "}
        <a
          href="https://sanlam.com.ng/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#fff" }}
        >
          (Read our Policy)
        </a>
        .
      </CookieConsent>
    </AuthContext.Provider>
  );
}

export default App;
