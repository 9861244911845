import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { AuthContext } from "../helpers/AuthContext";
import {
  handleModalEnter,
  handleNextStep,
  handlePersonalInfoChange,
  selectBanks,
} from "./utils";

export const BankDetails = ({ productCode }) => {
  const [validated, setValidated] = useState(false);
  const { personalInfo, setPersonalInfo, banksData, sofData, setSteps, steps } =
    useContext(AuthContext);
  const today = new Date();
  const todayDate = today.toISOString().substr(0, 10);

  useEffect(() => {
    (productCode === "FLPP" ||
      productCode === "FLPI" ||
      productCode === "FLPC") &&
      setPersonalInfo({ ...personalInfo, inflation: "10" });
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (
      form.checkValidity() === false ||
      (personalInfo.bvn && personalInfo.bvn.length !== 11)
    ) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      handleModalEnter();
      return;
    }

    event.preventDefault();
    handleNextStep(setSteps, steps);
    handleModalEnter();
    setValidated(true);
  };

  function selectSof(item) {
    return (
      <Form.Select
        type="text"
        placeholder="select your answer"
        name="sourceOfFund"
        value={personalInfo.sourceOfFund}
        onChange={(e) => {
          handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
        }}
      >
        <option></option>
        {item.map((option) => (
          <option key={option.id} value={option.key}>
            {option.name}
          </option>
        ))}
      </Form.Select>
    );
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row
        className="mb-1 container-fluid mx-auto"
        style={{ maxWidth: "800px" }}
      >
        {/* <Form.Group className="mb-4" as={Col} md={4} controlId="formBasicEmail">
          <Form.Label>
            Are you a First Bank holder? <span className="asterik">*</span>{" "}
          </Form.Label>
          <Form.Select
            type="text"
            placeholder="Yes"
            name="bankType"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.bankType}
            required
          >
            <option></option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select an option
          </Form.Control.Feedback>
        </Form.Group> */}

        <Form.Group className="mb-4" as={Col} md={4} controlId="formBasicEmail">
          <Form.Label>
            Select Bank <span className="asterik">*</span>{" "}
          </Form.Label>

          {selectBanks(
            banksData.data,
            personalInfo.selectBank,
            handlePersonalInfoChange,
            "selectBank",
            personalInfo,
            setPersonalInfo
          )}
        </Form.Group>

        <Form.Group
          className="mb-4"
          as={Col}
          md={4}
          controlId="formDateOfBirth"
        >
          <Form.Label>
            Account Type <span className="asterik">*</span>{" "}
          </Form.Label>
          <Form.Select
            type="text"
            placeholder="Select account type "
            name="accountType"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.accountType}
            required
          >
            <option></option>
            <option value="Current">Current</option>
            <option value="Savings">Savings</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select an option
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          className="mb-4"
          as={Col}
          md={4}
          controlId="formDateOfBirth"
        >
          <Form.Label>
            Account number <span className="asterik">*</span>{" "}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="1234567890"
            name="accountNumber"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.accountNumber}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter your account number
          </Form.Control.Feedback>
        </Form.Group>
        {/* <Form.Group className="mb-4" as={Col} md={4} controlId="formBasicEmail">
          <Form.Label>
            Mode of premium payment <span className="asterik">*</span>{" "}
          </Form.Label>
          <Form.Select
            type="text"
            placeholder="Select a Mode "
            name="premiumPayment"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.premiumPayment}
            required
          >
            <option></option>
            <option value="Bank Transfer">Bank Transfer</option>
            <option value="Card">Card</option>
            <option value="Cash/Cheque">Cash/Cheque</option>
            <option value="Direct Debit">Direct Debit</option>
            <option value="Standing Order">Standing Order</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select an option
          </Form.Control.Feedback>
        </Form.Group> */}

        <Form.Group
          className="mb-4"
          as={Col}
          md={6}
          controlId="formDateOfBirth"
        >
          <Form.Label>
            How much inflation protection? <span className="asterik">*</span>{" "}
          </Form.Label>
          {productCode !== "FLPP" &&
          productCode !== "FLPI" &&
          productCode !== "FLP" &&
          productCode !== "FLPC" ? (
            <Form.Select
              type="text"
              placeholder="How much Inflation do you want to protect?"
              name="inflation"
              onChange={(e) => {
                handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
              }}
              value={personalInfo.inflation}
              required
            >
              <option></option>
              <option value="10">10%</option>
              <option value="5">5%</option>
              <option value="None">I dont want inflation protection</option>
            </Form.Select>
          ) : (
            <Form.Select
              type="text"
              placeholder="How much Inflation do you want to protect?"
              name="inflation"
              onChange={(e) => {
                handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
              }}
              value={personalInfo.inflation}
              required
            >
              <option></option>
              <option value="10">10%</option>
              <option value="15">15%</option>
              <option value="20">20%</option>
              <option value="None">I dont want inflation protection</option>
            </Form.Select>
          )}
          <Form.Control.Feedback type="invalid">
            Please select an option
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4" as={Col} md={6}>
          <Form.Label>Source of fund</Form.Label>
          {selectSof(sofData.data)}
        </Form.Group>

        <Form.Group
          className="mb-4"
          as={Col}
          md={12}
          controlId="formDateOfBirth"
        >
          <Form.Label>BVN (Optional)</Form.Label>
          <Form.Control
            type="text"
            placeholder="12345678901"
            name="bvn"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.bvn}
          />
          {personalInfo.bvn && personalInfo.bvn.length !== 11 && (
            <Form.Control.Feedback style={{ color: "red" }}>
              BVN must be 11 numbers
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group
          controlId="formDateOfBirth"
          className="mb-4"
          as={Col}
          md={12}
        >
          <Form.Check
            type="checkbox"
            id={`default-checkbox`}
            label={`Have you held/currently holding a public office?`}
            name="publicOffice"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.publicOffice === "Yes" ? "No" : "Yes"}
            checked={personalInfo.publicOffice === "Yes" ? true : false}
            style={{
              fontSize: 10,
              fontWeight: 500,
            }}
          />
        </Form.Group>
      </Row>
      {/* {personalInfo.premiumPayment === "Direct Debit" && (
        <>
          <p
            style={{
              alignSelf: "flex-start",
              paddingLeft: "18px",
              marginTop: "10px",
            }}
          >
            <strong>Direct Debit Details</strong>
          </p>
          <div className="single-input-container">
            <Form.Group className="mb-4" controlId="formDateOfBirth">
              <Form.Label>
                Direct debit commencement date{" "}
                <span className="asterik">*</span>{" "}
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="dd/mm/yyyy"
                name="commencementDate"
                onChange={(e) => {
                  handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
                }}
                value={personalInfo.commencementDate}
                min={todayDate}
              />
            </Form.Group>
          </div>
          <div className="form-group-container">
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <Form.Label>
                Direct debit bank <span className="asterik">*</span>{" "}
              </Form.Label>
              {selectBanks(
                banksData.data,
                personalInfo.ddBank,
                handlePersonalInfoChange,
                "ddBank"
              )}
            </Form.Group>
            <Form.Group className="mb-4" controlId="formDateOfBirth">
              <Form.Label>
                Direct debit account name <span className="asterik">*</span>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter account name"
                name="ddAccountName"
                onChange={(e) => {
                  handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
                }}
                value={personalInfo.ddAccountName}
              />
            </Form.Group>
          </div>

          <div
            className="form-group-container"
            style={{ marginBottom: "20px" }}
          >
            <Form.Group className="mb-4" controlId="formDateOfBirth">
              <Form.Label>
                Direct debit account type <span className="asterik">*</span>{" "}
              </Form.Label>
              <Form.Select
                type="text"
                placeholder="Select account type "
                name="ddAccountType"
                onChange={(e) => {
                  handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
                }}
                value={personalInfo.ddAccountType}
              >
                <option value="">Select an option</option>
                <option value="Current">Current</option>
                <option value="Savings">Savings</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-4" controlId="formDateOfBirth">
              <Form.Label>
                Direct debit account number <span className="asterik">*</span>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="10 digit number"
                name="ddAccountNumber"
                onChange={(e) => {
                  handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
                }}
                value={personalInfo.ddAccountNumber}
              />
            </Form.Group>
          </div>
        </>
      )} */}
      {/* ------------------------------------------------------------------------- */}
      {/* </div> */}

      <button className="blue-button" style={{ minWidth: 100 }} type="submit">
        Next
      </button>
    </Form>
  );
};
