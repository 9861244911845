import React from "react";
import { Packages } from "./body/Packages";
// import { data } from "../../data";
import line from "../../assets/VectorLine.png";
import { useQuery } from "react-query";
import { getProducts, getSourcesOfFund } from "../../services";
import { Card, Placeholder } from "react-bootstrap";

export const Body = () => {
  const { data, status, refetch, isSuccess, isLoading } = useQuery(
    "allProducts",
    () => getProducts(),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const filteredData = data?.data?.filter(
    (item) =>
      item.abbreviation !== "FLPP" &&
      item.abbreviation !== "FLPC" &&
      item.abbreviation !== "FLPI"
  );

  console.log(filteredData);

  const placeholderLength = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div className="body-container">
      <img src={line} alt="line" className="line" />
      <div className="body-header">
        <h1>Our Products</h1>
        <p>Our Products help you live with Confidence</p>
      </div>
      {isLoading ? (
        <div className="packages-wrapper">
          {placeholderLength.map((placeholder) => {
            return (
              <Card style={{ width: "18rem" }} key={placeholder}>
                <Placeholder as={Card.Title} animation="glow">
                  <Placeholder className="icon-container" animation="glow" />
                </Placeholder>
                <Card.Body>
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={10} />
                  </Placeholder>
                  <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                    <Placeholder xs={4} /> <Placeholder xs={10} />{" "}
                    <Placeholder xs={8} />
                  </Placeholder>
                  <Placeholder.Button variant="primary" xs={10} />
                </Card.Body>
              </Card>
            );
          })}
        </div>
      ) : (
        <div className="packages-wrapper">
          {filteredData?.map((item) => {
            return (
              <Packages
                heading={item.name}
                desc={item.short_description}
                icon={item.default_image}
                keyPoints={item.keyPoints}
                image={item.default_image}
                details={item.details}
                id={item.id}
                abbr={item.abbreviation}
                key={item.id}
              />
            );
          })}
          <Packages
            heading={"Family Love Plans"}
            desc={
              "Family Love Plan is a funeral product that is designed to provide financial protection to you and loved families to finance the cost of funeral expenses of your Family members."
            }
            icon={
              "https://fbn-service-staging.s3.eu-west-1.amazonaws.com/files/DPGTGrndr9S9JjEsyHZ54UfCYPEe9SwtxmwPtjI0.png"
            }
            keyPoints={""}
            image={
              "https://fbn-service-staging.s3.eu-west-1.amazonaws.com/files/DPGTGrndr9S9JjEsyHZ54UfCYPEe9SwtxmwPtjI0.png"
            }
            details={""}
            id={"9999"}
            abbr={"FLP"}
            key={"9999"}
          />
        </div>
      )}
    </div>
  );
};
