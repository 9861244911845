import React, { useContext, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { AuthContext } from "../helpers/AuthContext";
import {
  handleNextStep,
  handlePersonalInfoChange,
  selectBusinessTypes,
  selectOccupations,
  selectReligions,
  selectStates,
  handleModalEnter,
} from "./utils";

export const PersonalInformation = () => {
  const [validated, setValidated] = useState(false);
  const { personalInfoValidated, setPersonalInfoValidated } =
    useContext(AuthContext);
  const {
    titlesData,
    citiesData,
    personalInfo,
    setPersonalInfo,
    statesData,
    religionsData,
    occupationsData,
    businessTypesData,
    setSteps,
    steps,
  } = useContext(AuthContext);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setPersonalInfoValidated(true);
      handleModalEnter();
      return;
    }

    event.preventDefault();
    handleNextStep(setSteps, steps);
    handleModalEnter();
    setPersonalInfoValidated(true);
  };

  let currentStateCities = citiesData.data.filter(
    (city) => city.state === personalInfo.state
  );

  function selectTitle(item) {
    return (
      <Form.Select
        type="text"
        placeholder="select your answer"
        name="title"
        value={personalInfo.title}
        onChange={(e) => {
          handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
        }}
        required
      >
        <option></option>
        {item.map((option) => (
          <option key={option.id} value={option.key}>
            {option.name}
          </option>
        ))}
      </Form.Select>
    );
  }
  function selectCities(item) {
    return (
      <Form.Select
        type="text"
        placeholder="select your answer"
        name="city"
        value={personalInfo.city}
        onChange={(e) => {
          handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
        }}
        required
      >
        <option></option>
        {item.map((option) => (
          <option key={option.id} value={option.key}>
            {option.name}
          </option>
        ))}
      </Form.Select>
    );
  }

  return (
    <Form noValidate validated={personalInfoValidated} onSubmit={handleSubmit}>
      <Row
        className="mb-1 container-fluid mx-auto"
        style={{ maxWidth: "800px" }}
      >
        <Form.Group className="mb-4" as={Col} md={4}>
          <Form.Label>
            Title <span className="asterik">*</span>
          </Form.Label>
          {selectTitle(titlesData.data)}
          <Form.Control.Feedback type="invalid">
            Please select a title
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4" as={Col} md={4} controlId="formFullName">
          <Form.Label>
            First Name <span className="asterik">*</span>{" "}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Tolulope"
            name="firstName"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.firstName}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter your first name
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4" as={Col} md={4}>
          <Form.Label>
            Middle Name <span className="asterik">*</span>{" "}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="John"
            name="middleName"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.middleName}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter your middle name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={4} controlId="formFullName">
          <Form.Label>
            Last Name <span className="asterik">*</span>{" "}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Adeyekan"
            name="lastName"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.lastName}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter your last name
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          className="mb-4"
          as={Col}
          md={4}
          controlId="formPhoneNumber"
        >
          <Form.Label>
            Phone number <span className="asterik">*</span>{" "}
          </Form.Label>
          <Form.Control
            type="phone"
            placeholder="07067896171"
            name="phone"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.phone}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter your phone number
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          className="mb-4"
          as={Col}
          md={4}
          controlId="formDateOfBirth"
        >
          <Form.Label>
            Date of birth <span className="asterik">*</span>{" "}
          </Form.Label>
          <Form.Control
            type="date"
            placeholder="DD/MM/YYYY"
            name="dob"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.dob}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter your date of birth
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4" as={Col} md={4} controlId="formBasicEmail">
          <Form.Label>
            Email address <span className="asterik">*</span>{" "}
          </Form.Label>
          <Form.Control
            type="email"
            placeholder="tobiowoade@gmail.com"
            name="email"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.email}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter your email address
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          md={4}
          className="mb-4"
          controlId="formDateOfBirth"
        >
          <Form.Label>
            Gender <span className="asterik">*</span>{" "}
          </Form.Label>
          <Form.Select
            type="text"
            name="gender"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.gender}
            required
          >
            <option></option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select your gender
          </Form.Control.Feedback>
        </Form.Group>

        {personalInfo.usCitizen === "No" && (
          <>
            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formBasicEmail"
            >
              <Form.Label>
                State of Residence <span className="asterik">*</span>{" "}
              </Form.Label>
              {selectStates(
                statesData.data,
                personalInfo.state,
                "state",
                personalInfo,
                setPersonalInfo
              )}
              <Form.Control.Feedback type="invalid">
                Please select your state of residence
              </Form.Control.Feedback>
            </Form.Group>
          </>
        )}
        {personalInfo.usCitizen === "No" && (
          <>
            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formBasicEmail"
            >
              <Form.Label>
                City <span className="asterik">*</span>{" "}
              </Form.Label>
              {selectCities(currentStateCities)}
              <Form.Control.Feedback type="invalid">
                Please select your city
              </Form.Control.Feedback>
            </Form.Group>
          </>
        )}
        <Form.Group
          className="mb-4"
          as={Col}
          md={8}
          controlId="formDateOfBirth"
        >
          <Form.Label>
            Street address <span className="asterik">*</span>{" "}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="725 Adeola Hopewell, Victoria Island Lagos"
            name="address"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.address}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter your home address
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="mb-4"
          as={Col}
          md={6}
          controlId="formMaritalStatus"
        >
          <Form.Label>
            Marital Status <span className="asterik">*</span>{" "}
          </Form.Label>
          <Form.Select
            type="text"
            placeholder="Single"
            name="maritalStatus"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.maritalStatus}
            required
          >
            <option></option>
            <option value="Married">Married</option>
            <option value="Single">Single</option>
            <option value="Divorced">Divorced</option>
            <option value="Separated">Separated</option>
            <option value="Others">Others</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select your marital status
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4" as={Col} md={6} controlId="formBasicEmail">
          <Form.Label>
            Religion <span className="asterik">*</span>{" "}
          </Form.Label>
          {selectReligions(
            religionsData.data,
            personalInfo.religion,
            "religion",
            personalInfo,
            setPersonalInfo
          )}
          <Form.Control.Feedback type="invalid">
            Please select a religion
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          className="mb-4"
          as={Col}
          md={6}
          controlId="formDateOfBirth"
        >
          <Form.Label>
            Occupation <span className="asterik">*</span>{" "}
          </Form.Label>
          {selectOccupations(
            occupationsData.data,
            personalInfo.occupation,
            "occupation",
            personalInfo,
            setPersonalInfo
          )}
          <Form.Control.Feedback type="invalid">
            Please select an occupation
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          className="mb-4"
          as={Col}
          md={6}
          controlId="formDateOfBirth"
        >
          <Form.Label>
            Business Type <span className="asterik">*</span>{" "}
          </Form.Label>
          {selectBusinessTypes(
            businessTypesData.data,
            personalInfo.businessTypes,
            "businessTypes",
            personalInfo,
            setPersonalInfo
          )}
          <Form.Control.Feedback type="invalid">
            Please select your business type
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          className="mb-4"
          as={Col}
          md={12}
          controlId="formDateOfBirth"
        >
          {/* <Form.Check
            type="checkbox"
            id={`default-checkbox`}
            label={`Are you a US Citizen?`}
            name="usCitizen"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.usCitizen === "Yes" ? "No" : "Yes"}
            checked={personalInfo.usCitizen === "Yes" ? true : false}
          /> */}
        </Form.Group>
      </Row>

      <div className="single-input-container"></div>
      <button className="blue-button" style={{ minWidth: 100 }} type="submit">
        Next
      </button>
    </Form>
  );
};
