import React from "react";
// import "./MultiStepProgressBar.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const MultiStepProgressBar = ({ steps, onPageNumberClick, setSteps }) => {
  var stepPercentage = 0;
  if (steps === 4) {
    stepPercentage = 3.67;
  } else if (steps === 5) {
    stepPercentage = 18.37;
  } else if (steps === 6) {
    stepPercentage = 38.6;
  } else if (steps === 7) {
    stepPercentage = 59.67;
  } else if (steps === 8) {
    stepPercentage = 78.34;
  } else if (steps === 9) {
    stepPercentage = 98;
  } else {
    stepPercentage = 100;
  }

  return (
    <ProgressBar percent={stepPercentage}>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            // onClick={() => setSteps(4)}
          ></div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            // onClick={() => setSteps(5)}
          ></div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            // onClick={() => setSteps(6)}
          ></div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            // onClick={() => setSteps(7)}
          ></div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            // onClick={() => setSteps(8)}
          ></div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
            // onClick={() => setSteps(9)}
          ></div>
        )}
      </Step>
    </ProgressBar>
  );
};

export default MultiStepProgressBar;
