import React, { useContext } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { AuthContext } from "../../helpers/AuthContext";
import {
  fullDetails,
  handlePersonalInfoChange,
  handleSubmit,
  selectTitle,
} from "../utils";

export const PersonalInformation = ({ validated, setValidated }) => {
  const {
    titlesData,
    steps,
    setSteps,
    isGenerating,
    idImage,
    addressImage,
    signatureImage,
    personalInfo,
    setPersonalInfo,
    beneficiaries,
  } = useContext(AuthContext);
  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={(event) => {
        handleSubmit(
          event,
          setValidated,
          steps,
          fullDetails(
            personalInfo,
            beneficiaries,
            idImage,
            addressImage,
            signatureImage
          ),
          setSteps
        );
      }}
    >
      <div className="form-info">
        <p>Personal Info</p>
      </div>
      <Row
        className="mb-1 container-fluid mx-auto"
        style={{ maxWidth: "800px" }}
      >
        <Form.Group className="mb-4" as={Col} md={6}>
          <Form.Label>
            Title <span className="asterik">*</span>
          </Form.Label>
          {selectTitle(
            titlesData && titlesData.data,
            personalInfo,
            setPersonalInfo
          )}
          <Form.Control.Feedback type="invalid">
            Please select a title
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            First Name <span className="asterik">*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Ciroma"
            name="firstName"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.firstName}
          />
          <Form.Control.Feedback type="invalid">
            Please enter your first name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6}>
          <Form.Label>
            Middle Name <span className="asterik">*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="chukwuma"
            name="middleName"
            value={personalInfo.middleName}
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
          />
          <Form.Control.Feedback type="invalid">
            Please enter your middle name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Last Name <span className="asterik">*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Abiola"
            name="lastName"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.lastName}
          />
          <Form.Control.Feedback type="invalid">
            Please enter your last name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="mb-4"
          as={Col}
          md={6}
          controlId="formDateOfBirth"
        >
          <Form.Label>
            Date of birth <span className="asterik">*</span>
          </Form.Label>
          <Form.Control
            required
            type="date"
            placeholder="DD/MM/YYYY"
            name="dob"
            value={personalInfo.dob}
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a vaild date of birth
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          className="mb-4"
          as={Col}
          md={6}
          controlId="formPhoneNumber"
        >
          <Form.Label>
            Phone number <span className="asterik">*</span>
          </Form.Label>
          <Form.Control
            required
            type="phone"
            placeholder="07067896171"
            name="phone"
            value={personalInfo.phone}
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid phone number
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="mb-4"
          as={Col}
          md={12}
          controlId="formBasicEmail"
        >
          <Form.Label>
            Email address <span className="asterik">*</span>
          </Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="tobiowoade@gmail.com"
            name="email"
            value={personalInfo.email}
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid email address
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <button
        className="blue-button"
        style={{ minWidth: 100, marginTop: 15 }}
        type="submit"
      >
        {isGenerating ? "Generating Quote..." : "Next"}
      </button>
    </Form>
  );
};
