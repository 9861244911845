import React from "react";
import { Modal } from "react-bootstrap";
import recurrentImage from "../assets/recurrent.png";
import { MdOutlineCancel } from "react-icons/md";

export const RecurrentModal = ({
  isRecurrent,
  setIsRecurrent,
  payment,
  amount,
}) => {
  return (
    <Modal
      size="md"
      show={isRecurrent}
      onHide={() => setIsRecurrent(false)}
      aria-labelledby="example-modal-sizes-title-sm"
      // fullscreen={"sm-down"}
      centered
      className="jaf"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <p></p>
        <img src={recurrentImage} alt="recurrent" />
        <MdOutlineCancel
          onClick={() => {
            setIsRecurrent(false);
          }}
          style={{ cursor: "pointer" }}
        />
      </div>
      <Modal.Body>
        <div className="recurrent-container">
          <h6>Account Renewal Setup</h6>
          <p>
            If you accept, this means your policy premium,{" "}
            <span>
              N
              {parseFloat(amount).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>{" "}
            will be paid monthly directly from debit/credit card with no
            additional effort on your part.
          </p>
          <p>You can cancel this subscription at anytime.</p>
          <button
            className="blue-button"
            onClick={() => {
              payment();
              setIsRecurrent(false);
            }}
          >
            Proceed
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
