import flexiEdu from "../src/assets/flexi-edu-plan_copy.png";
import flexiCash from "../src/assets/sabi-savers-plan_copy.png";
import sabiSavers from "../src/assets/sabi-savers-plan_copy.png";
import flexiSave from "../src/assets/flexi-save-plan_copy.png";
import personalRetirement from "../src/assets/personal-retirement-plan_copy.png";
import savingsPlus from "../src/assets/savings-plus-plan_copy.png";
import familyShield from "../src/assets/family-shield-plan_copy.png";
import familyShieldPlus from "../src/assets/family-shield-plus_copy.png";
import smartProtection from "../src/assets/smart-protection_copy.png";
import glrip from "../src/assets/GLRIP_copy.png";
import flexiEduFull from "../src/assets/flexi-edu-full_copy.png";
import flexiCashFull from "../src/assets/flexi-cash-full_copy.png";
import flexiSaveFull from "../src/assets/flexi-save-full_copy.png";
import sabiSaversFull from "../src/assets/sabi-savers-full_copy.png";
import flpp from "../src/assets/flpp.png";
import personalRetirementFull from "../src/assets/personal-retirement-full_copy.png";
import rbp from "../src/assets/rbp_copy.png";
import sabiSaversTable from "../src/assets/sabi-savers-table_copy.png";
import savingsPlusFull from "../src/assets/savings-plus-full_copy.png";
import savingsPlusTable1 from "../src/assets/savings-plus-table-1_copy.png";
import savingsPlusTable2 from "../src/assets/savings-plus-table-2_copy.png";
import familyShiedFull from "../src/assets/family-shield-full_copy.png";
import familyShiedPlusFull from "../src/assets/family-shield-plus-full_copy.png";
import familyShiedPlusTable from "../src/assets/family-shield-plus-table_copy.png";
import smartProtectionPlanFull from "../src/assets/smart-protection-plus-full_copy.png";
import glripFull from "../src/assets/glrip-full_copy.png";

export const data = [
  {
    id: 7,
    name: (
      <h1>
        flexi-edu <span>plan</span>
      </h1>
    ),
    desc: "Plan the future of your children by ensuring their education at all levels of education",
    icon: flexiEdu,
    keyPoints: [
      "Ensure your children have the very best, come what may.",
      "Plan a bright future for your child/ward no matter the uncertainties of life.",
      "Open to parents/sponsors of wards/children across all levels of education.",
    ],
    image: flexiEduFull,
    details: [
      {
        key: "0",
        title: "Life Cover",
        desc: (
          <ul>
            <li>Minimum monthly premium of N5,000</li>
            <li>Policy provides a minimum death benefit of N300,000</li>
            <li>
              Medical examination may be required depending on the sum assured
              and the entry age of the policyholder
            </li>
            <li>Minimum policy term: 5 years</li>
            <li>Maximum policy term: 20 years</li>
            <li>Maximum age at maturity: 65 years</li>
            <li>
              Premium payment mode: Monthly/Quarterly/Half
              Yearly/Annually/Single Premium
            </li>
          </ul>
        ),
      },
      {
        key: "1",
        title: "Maturity benefit",
        desc: (
          <ul>
            <li>
              Maturity benefit is paid as a lump sum or in four (4) equal,
              annual investments
            </li>
          </ul>
        ),
      },
      {
        key: "2",
        title: "Death benefit",
        desc: (
          <ul>
            <li>
              In the event of the death of the policyholder before maturity, 10%
              of
            </li>
            <li>
              the sum assured will be payable annually to the beneficiary
              subject to a limit of 100% of the sum assured.
            </li>
            <li>
              The guaranteed maturity benefits will still be paid at maturity of
              the policy.
            </li>
            <li>All future premiums due for payment will be waived.</li>
          </ul>
        ),
      },
      {
        key: "3",
        title: "Eligibility Criteria",
        desc: (
          <ul>
            <li>Minimum age at entry: 18 years</li>
            <li>Maximum age at entry: 60 years</li>
          </ul>
        ),
      },
      {
        key: "4",
        title: "Surrender Value",
        desc: (
          <ul>
            <li>
              You can surrender your policy after 12 months if you pay your
              premium regularly but there will be a surrender charge
            </li>
          </ul>
        ),
      },
      {
        key: "5",
        title: "Money Back Guarantee",
        desc: (
          <ul>
            <li>
              The Policy holder has the right to cancel the policy within 30
              days of commencement and receive all premiums paid provided that
              no benefit has been paid or claimed
            </li>
          </ul>
        ),
      },
      {
        key: "6",
        title: "Waiting Period",
        desc: (
          <ul>
            <li>There is a 6 month waiting period on this plan</li>
            <li>
              The Sum Assured will only be payable for death resulting from
              accident.
            </li>
            <li>
              For death resulting from natural causes, all premiums paid to the
              date of death will be returned.
            </li>
            <li>
              Where policyholders renew automatically, there will be no waiting
              period from the first and subsequent renewals. This will also only
              apply if the policyholder continuously renews
            </li>
          </ul>
        ),
      },
      {
        key: "7",
        title: "Grace Period",
        desc: (
          <ul>
            <li>There is a grace period of 30days for payment of premium</li>
          </ul>
        ),
      },
    ],
  },
  {
    id: 19,
    name: (
      <h1>
        flexi-cash <span>plan</span>
      </h1>
    ),
    desc: "Save Periodically and benefit from lump sums at specific intervals over a period",
    icon: flexiCash,
    keyPoints: [
      "Live life to the fullest today and at the same time stay protected",
      "Enjoy the flexibility of receiving a specified percentage of your sum assured at specified intervals....",
    ],
    image: flexiCashFull,
    details: [
      {
        key: "0",
        title: "Maturity benefit",
        desc: (
          <ul>
            <li>
              25% of the sum assured will be paid at the end of the fourth year
            </li>
            <li>
              25% of the sum assured will be paid mid-way through the
              outstanding duration after the first payment.
            </li>
            <li>
              100% of the sum assured plus all accrued bonuses will be paid at
              maturity
            </li>
            <li>Minimum monthly premium is N5,000</li>
            <li>Maximum age at maturity: 65 years</li>
            <li>Minimum policy term: 6 years</li>
            <li>Maximum policy term: 16 years</li>
          </ul>
        ),
      },
      {
        key: "1",
        title: "Wealth Creation",
        desc: (
          <ul>
            <li>
              Full Sum Assured plus accrued bonus is payable in the event of the
              death of the policyholder. This is in addition to the partial
              maturity benefit already paid to date.
            </li>
          </ul>
        ),
      },
      {
        key: "2",
        title: "Eligibility Criteria",
        desc: (
          <ul>
            <li>Minimum age at entry: 18 years</li>
            <li>Maximum age at entry: 59 years</li>
          </ul>
        ),
      },
      {
        key: "3",
        title: "Surrender Value",
        desc: (
          <ul>
            <li>
              You can surrender your policy after 12 months if you pay your
              premium regularly but there will be a surrender charge
            </li>
          </ul>
        ),
      },
      {
        key: "4",
        title: "Money Back Guarantee",
        desc: (
          <ul>
            <li>
              The Policy holder has the right to cancel the policy within 30
              days of commencement and receive all premiums paid provided that
              no benefit has been paid or claimed
            </li>
          </ul>
        ),
      },
      {
        key: "5",
        title: "Waiting Period",
        desc: (
          <ul>
            <li>There is a 6 month waiting period on this plan</li>
            <li>
              The Sum Assured will only be payable for death resulting from
              accident.
            </li>
            <li>
              For death resulting from natural causes, all premiums paid to the
              date of death will be returned.
            </li>
            <li>
              Where policyholders renew automatically, there will be no waiting
              period from the first and subsequent renewals. This will also only
              apply if the policyholder continuously renews
            </li>
            <li>There is a grace period of 30 days for payment of premium.</li>
          </ul>
        ),
      },
    ],
  },
  {
    id: 2,
    name: (
      <h1>
        flexi save <span>plan</span>
      </h1>
    ),
    desc: "Save in the medium and long term and benefit from a flat-rate benefit at the end of the selected term.",
    icon: flexiSave,
    keyPoints: [
      "Medium to long term savings...",
      "Enjoy a lump sum benefit at the end of the selected duration...",
    ],
    image: flexiSaveFull,
    details: [
      {
        key: "0",
        title: "Life Cover",
        desc: (
          <ul>
            <li>
              In case of death during the duration of the policy, there is a
              guaranteed funeral expense benefit payable to the beneficiary
            </li>
            <li>
              This guaranteed funeral expense benefit is equal to 10% of total
              premium payable during the duration of the policy subject to a
              minimum of N100,000 and a maximum of N500,000
            </li>
            <li>
              Accidental Death Benefit: 200% of the total premium paid is
              payable for the entire period of cover, subject to a maximum limit
              of N500,000
            </li>
            <li>Minimum monthly premium of N10,000</li>
            <li>Maximum age at maturity: 65 years</li>
            <li>Minimum policy term: 5 years</li>
            <li>Maximum policy term: 20 years</li>
            <li>
              Premium payment mode: Monthly/Quarterly/Half
              Yearly/Annually/Single Premium
            </li>
            <li>
              Provide tax free lump sum at the end of the selected policy
              duration
            </li>
          </ul>
        ),
      },
      {
        key: "1",
        title: "Maturity benefit",
        desc: (
          <ul>
            <li>
              At maturity, you will receive full refund of investment premiums
              and accrued interest on your contribution
            </li>
          </ul>
        ),
      },
      {
        key: "2",
        title: "Surrender Value",
        desc: (
          <ul>
            <li>
              You can surrender your policy after 12 months if you pay your
              premium regularly but there will be a surrender charge
            </li>
          </ul>
        ),
      },
      {
        key: "3",
        title: "Eligibility Criteria",
        desc: (
          <ul>
            <li>Minimum age at entry: 18 years</li>
            <li>Maximum age at entry: 60 years</li>
          </ul>
        ),
      },
      {
        key: "4",
        title: "Money Back Guarantee",
        desc: (
          <ul>
            <li>
              The Policy holder has the right to cancel the policy within 30
              days of commencement and receive all premiums paid provided that
              no benefit has been paid or claimed
            </li>
          </ul>
        ),
      },
      {
        key: "6",
        title: "How to Claim your Benefit?",
        desc: (
          <ul>
            <span>Maturity Benefit</span>
            <li>Complete a Maturity/liquidation form</li>
            <li>
              Send the duly completed form to any of our sales outlet/office
              nearest to you, our head office or wecare@sanlam.com.ng
            </li>
            <span>
              In the event of the death of the policyholder, please take the
              following steps:
            </span>
            <li>
              Lodge a formal request by walking into any of our outlets or by
              sending an email to wecare@sanlam.com.ng
            </li>
            <span>Submit copies of the following documents:</span>
            <li>Last attending Doctor’s report</li>
            <li>Death certificate from National Population Commission</li>
            <li>
              Federal Road Safety Corps report or Police report if death is as a
              result of accident
            </li>
            <li>Valid ID card of beneficiaries</li>
            <li>Policy Schedule or form (where applicable)</li>
            <span>Additional documentation may be required</span>
          </ul>
        ),
      },
      {
        key: "7",
        title: "Grace Period",
        desc: (
          <ul>
            <li>There is a grace period of 30days for payment of premium</li>
          </ul>
        ),
      },
    ],
  },
  {
    id: 36,
    name: (
      <h1>
        personal retirement <span>plan</span>
      </h1>
    ),
    desc: "Create your own retirement capital and live your golden age as you wish.",
    icon: personalRetirement,
    keyPoints: [
      "A retirement savings plan that ensures that you are in control of your life even during your golden years...",
      "It is a simple, hassle free plan...",
      "Create your own pension pot and live your retirement the way you want it.",
    ],
    image: personalRetirementFull,
    details: [
      {
        key: "0",
        title: "How does it work?",
        desc: (
          <ul>
            <div>
              <span>Step 1: Choose your policy premium</span>
              <p>
                Like any other plan that allows you to save for retirement, you
                decide the premium you want. This represents the regular
                contribution amount during the savings period. The premium
                amount can be paid monthly, quarterly, semi-annually, annually
                or one-off.
              </p>
            </div>
            <div>
              <span>Step 2: Decide the Deferred / Savings period</span>
              <p>
                You also decide the deferred period. This is the period when
                your contributions will accumulate. It is the period when you
                make contributions towards building your retirement fund.
              </p>
            </div>
            <div>
              <span>Step 3: Start growing your fund</span>
              <p>
                Your funds will grow at an annual interest rate which will be
                communicated to you quarterly.
              </p>
            </div>
            <div>
              <span>
                Step 4: Earn additional bonus on your fund as you save
              </span>
              <p>
                The plan also promises you an additional amount referred to as
                the Retirement Bonus which is a defined percentage of your total
                fund, the sum of which is dependent on how long you save
                (deferred period). This is earned at the point of exit which is
                either at maturity or on termination (provided it is greater
                than five years).
              </p>
            </div>
            <div>
              <span>Step 5: Convert to regular monthly income</span>
              <p>
                You have the option to convert your fund balance (allocated
                contribution plus interest plus bonus) to annuity or a lumpsum
              </p>
            </div>
          </ul>
        ),
      },
      {
        key: "1",
        title: "Plan Benefits",
        desc: (
          <ul>
            <p>
              The plan also offers the following benefits:(i) Death Benefit (ii)
              Guaranteed Retirement Bonus Benefit
            </p>

            <div>
              <span>(i) Death Benefit</span>
              <p>
                If the policyholder dies during the deferred period
                (contributory period), the plan will pay to the beneficiaries
                the higher of:
              </p>
              <li>Guaranteed Death Benefit; or</li>
              <li>
                Fund balance in the investment account as at the date of death.
              </li>

              <p>
                The Guaranteed Death Benefit is calculated as the total premium
                payable during the plan contributory stage (deferred period).
                The Death Benefit shall always be determined as of the date we
                receive notification of the death of the Life Insured.
              </p>
            </div>

            <div>
              <span>(ii) Guaranteed Retirement Bonus Benefit.</span>
              <p>
                The Guaranteed Retirement Bonus Benefit is an amount which will
                be added to the accrued fund value at the end of the savings
                period. The Retirement Bonus also applies to early termination
                provided the policy has been in force for at least five years.
              </p>
            </div>
          </ul>
        ),
      },
      {
        key: "2",
        title: "Retirement bonus percentages",
        desc: (
          <ul>
            <p>
              This depends on the duration for which the policy has been in
              force at the time of exit. This is independent of the original
              term chosen by the policyholder. The longer this term, the higher
              the Retirement Bonus percentage. The Retirement Bonus percentages
              for different terms are as shown in the table below:
            </p>

            <img src={rbp} alt="rbp" className="rbp" />

            <p>
              Note: If the policy is terminated before the end of the term i.e
              less than five years, it does not qualify for a retirement bonus.
            </p>
          </ul>
        ),
      },
      {
        key: "3",
        title: "Maturity Benefit",
        desc: (
          <ul>
            <li>
              At the maturity of the benefit, you can elect to convert your
              maturity proceed (balance in the investment account) plus bonus to
              immediate annuity that will pay the customer from the date of
              maturity.
            </li>
          </ul>
        ),
      },
      {
        key: "4",
        title: "Surrender Benefit",
        desc: (
          <ul>
            <li>
              The surrender value payable is the balance in the investment
              account together with the applicable bonus, if any.
            </li>
          </ul>
        ),
      },
      {
        key: "5",
        title: "Eligibility Criteria",
        desc: (
          <ul>
            <li>Minimum age at entry: 18 years</li>
            <li>Maximum age at entry: 60 years</li>
          </ul>
        ),
      },
      {
        key: "6",
        title: "Minimum Criteria",
        desc: (
          <ul>
            <li>
              The contribution amount will be chosen by the client at inception
              subject to a minimum monthly premium of NGN10,000:00. However,
              customers may elect to increase the regular premium, with prior
              notification
            </li>
          </ul>
        ),
      },
      {
        key: "7",
        title: "Term",
        desc: (
          <ul>
            <li>
              This refers to the plan duration selected by the policyholder.
              This is subject to a minimum duration of five years. However, the
              plan can only be converted to immediate annuity when the
              policyholder clocks 50 years.
            </li>
          </ul>
        ),
      },
    ],
  },

  {
    id: 37,
    name: (
      <h1>
        sabi savers <span>plan</span>
      </h1>
    ),
    desc: "Benefit from insurance that adapts to your earnings and savings needs",
    icon: sabiSavers,
    keyPoints: [
      "Flexible Product",
      "Choose your own earning mode...",
      "Save based on your unique savings needs...",
    ],
    image: sabiSaversFull,
    details: [
      {
        key: "0",
        title: "Life Cover",
        desc: (
          <ul>
            <p>
              The product provides a compulsory member Life Cover benefit with
              an option to include other members at a cost. The cover available
              to the main life is N500,000 with an annual premium of N5,000.
              Life cover is for whole Life, subject to the payment of the annual
              premium. The table below shows the cover amount and other plan
              features for the Life cover:
            </p>
            <img src={sabiSaversTable} alt="sabi savers table" />
          </ul>
        ),
      },
      {
        key: "1",
        title: "Savings Component",
        desc: (
          <ul>
            <li>
              Choose any level of savings, subject to a minimum monthly savings
              of N5,000.
            </li>
            <li>
              Make regular or irregular savings contributions as you wish;
              subject to the minimum monthly amount.
            </li>
            <li>
              Savings will grow at a declared interest rate, which may be
              reviewed quarterly based on the interest environment.
            </li>
            <li>
              The policyholder has access to funds after making contributions
              for a minimum of 6months
            </li>
            <li>
              Policyholders are allowed to withdraw any amount up to the full
              value of the savings account balance after the minimum
              contributory period of 6months There are no penalties when savings
              are withdrawn.
            </li>
          </ul>
        ),
      },
      {
        key: "2",
        title: "Product Flexibility",
        desc: (
          <ul>
            <p>
              The policyholder can make almost any changes to their policy at
              any time:
            </p>
            <li>
              Has mandatory cover for the main life (subject to 3 months waiting
              period)
            </li>
            <li>
              Can include optional cover for additional life, spouse etc. -
              (subject to the maximum of 6lives and a waiting period of 6months)
              Can remove cover for any life
            </li>
            <li>
              Can amend the savings contribution at any time– increase or reduce
            </li>
            <li>Can add, remove or amend the beneficiary of the policy</li>
          </ul>
        ),
      },
      {
        key: "3",
        title: "How to pay your premium",
        desc: (
          <ul>
            <span>Premium can be paid though the following channels:</span>

            <li>
              Sanlam One-Stop Payment Service (SOPS): https://pay.sanlam.com.ng/
            </li>
            <li>Quickteller:https://www.quickteller.com/fbninsuranceltd</li>
            <li>PayDirect:Any Commercial Bank in Nigeria</li>

            <p>Please include your name and policy number onall payments.</p>
            <p>Do not pay cash to/through our Financial Advisor or Staff.</p>
          </ul>
        ),
      },
      {
        key: "4",
        title: "How to Claim your Benefit?",
        desc: (
          <ul>
            <span>Maturity Benefit</span>
            <li>Complete a Maturity/liquidation form</li>
            <li>
              Send the duly completed form to any of our sales outlet/office
              nearest to you, our head office or wecare@sanlam.com.ng
            </li>
            <span>
              In the event of the death of the policyholder, please take the
              following steps:
            </span>
            <li>
              Lodge a formal request by walking into any of our outlets or by
              sending an email to wecare@sanlam.com.ng
            </li>
            <span>Submit copies of the following documents:</span>
            <li>Last attending Doctor’s report</li>
            <li>Death certificate from National Population Commission</li>
            <li>
              Federal Road Safety Corps report or Police report if death is as a
              result of accident
            </li>
            <li>Valid ID card of beneficiaries</li>
            <li>Policy Schedule or form (where applicable)</li>
            <span>Additional documentation may be required</span>
          </ul>
        ),
      },
    ],
  },
  {
    id: 35,
    name: (
      <h1>
        savings plus <span>plan</span>
      </h1>
    ),
    desc: "Determine the amount of your savings and benefit from regular returns throughout the term",
    icon: savingsPlus,
    keyPoints: [
      "Designed to support disciplined savings...",
      "Ensures steady returns throughout the policy duration...",
      "Enjoy free insurance coverage of NGN1million to take care of your loved ones in case of an unfortunate event...",
    ],
    image: savingsPlusFull,
    details: [
      {
        key: "0",
        title: "Why you should buy the savings plus plan?",
        desc: (
          <ul>
            <li>
              Lumpsum payment at maturity – savings plus accrued interest at
              maturity
            </li>
            <li>
              Flexibility to choose contribution amount (minimum of NGN20,000)
              and payment frequency (Monthly, Quarterly (M), Half-yearly (H),
              Yearly (Y) and Single-Payment/One-Off (S) that matches your
              lifestyle
            </li>
            <li>Free Life Insurance Cover of NGN1million per life.</li>
            <li>
              Additional protection can be purchased for your loved ones through
              life insurance cover and the optional Term Assurance rider
            </li>
          </ul>
        ),
      },
      {
        key: "1",
        title: "How does it work?",
        desc: (
          <ul>
            <li>
              <span>Step 1:</span> Choose Your Policy Premium: Decide your
              regular savings amount (minimum of NGN20,000 monthly)
            </li>
            <li>
              <span>Step 2:</span> Decide Your Savings Contribution Frequency:
              You also decide the savings contributory pattern that suits you
              (Monthly, Quarterly, Half Yearly, Yearly, Single-
              Payment/One-Off).
            </li>
            <li>
              <span>Step 3:</span> Start Growing Your Fund: You start growing
              your savings fund as your contribution is credited with promised
              returns.
            </li>
            <li>
              <span>Step 4:</span> Decide Additional fiisk Cover (Term Assurance
              fiider): Secure your family from any financial burden in your
              absence by purchasing additional life cover (Optional Term
              Assurance fiider)
            </li>
            <li>
              <span>Step 5:</span> Obtain Your Maturity Value: You receive your
              savings contribution plus accrued interest at the end of the plan
              duration – Maturity Lumpsum.
            </li>
          </ul>
        ),
      },
      {
        key: "2",
        title: "Plan Benefits",
        desc: (
          <ul>
            <span>The plan also offers the following benefits:</span>
            <li>
              Maturity benefits (contributions plus accrued interest) payable at
              the end of the plan duration (minimum of 3years)
            </li>
            <li>
              Free Life Cover - Death (all causes) and total permanent
              disability arising from accident only
            </li>
            <li>Additional Life Benefit (Term Assurance fiider)</li>

            <span>Death and Total Permanent Disability Benefit</span>

            <p>
              If the policyholder dies during the plan duration or becomes
              totally and permanently disabled (resulting from accident only),
              the plan will pay to the beneficiaries the:
            </p>

            <li>Free life cover amount (NGN1,000,000) plus</li>
            <li>
              Balance in the investment account (savings contribution plus
              accrued interest) as at the date of the incident
            </li>

            <span>Additional Life Cover (Term Assurance Rider – Optional)</span>

            <p>
              If chosen, the benefit amount will be paid alongside the benefits
              above in the event that the policyholder dies or becomes totally
              and permanently disabled
            </p>
          </ul>
        ),
      },
      {
        key: "3",
        title: "Surrender Benefit",
        desc: (
          <ul>
            <p>
              Policyholder may elect to surrender their policy for cash. To
              qualify for surrender benefits, the policy must be in force with
              all premiums paid, for a minimum period of 12months. The table
              below shows the surrender benefits payable when policyholder elect
              to surrender their policy:.
            </p>

            <img src={savingsPlusTable1} alt="savings plus table 1" />

            <span>Guaranteed Returns to Policyholder</span>
            <p>
              The interest rate promised to policyholders is as shown in the
              table below:
            </p>
            <img src={savingsPlusTable2} alt="savings plus table " />

            <span>
              The interest will be reviewed every quarter and communicated to
              the policyholders
            </span>
          </ul>
        ),
      },
      {
        key: "4",
        title: "Eligibility Criteria",
        desc: (
          <ul>
            <li>Minimum Minimum age at entry: 18 years</li>
            <li>Maximum age at entry: 62 years</li>
          </ul>
        ),
      },
      {
        key: "5",
        title: "Minimum Savings Contribution Amount",
        desc: (
          <ul>
            <li>
              The contribution amount will be chosen by the client at inception
              subject to a minimum monthly premium of NGN20,000. However,
              customers may elect to increase the regular savings contribution
              at any point. This will not change the sum at risk (Free Life
              Cover of NGN1million).
            </li>
          </ul>
        ),
      },
      {
        key: "6",
        title: "Term",
        desc: (
          <ul>
            <li>The plan duration is a minimum of 3years.</li>
          </ul>
        ),
      },
      {
        key: "7",
        title: "Premium Payment Frquency",
        desc: (
          <ul>
            <li>
              Premium payment can be made monthly, quarterly, half- yearly,
              yearly and single payment (one-off).
            </li>
          </ul>
        ),
      },
      {
        key: "8",
        title: "How to Claim your Benefit?",
        desc: (
          <ul>
            <span>Maturity Benefit</span>
            <li>Complete a Maturity/liquidation form</li>
            <li>
              Send the duly completed form to any of our sales outlet/office
              nearest to you, our head office or wecare@sanlam.com.ng
            </li>
            <span>
              In the event of the death of the policyholder, please take the
              following steps:
            </span>
            <li>
              Lodge a formal request by walking into any of our outlets or by
              sending an email to wecare@sanlam.com.ng
            </li>
            <span>Submit copies of the following documents:</span>
            <li>Last attending Doctor’s report</li>
            <li>Death certificate from National Population Commission</li>
            <li>
              Federal Road Safety Corps report or Police report if death is as a
              result of accident
            </li>
            <li>Valid ID card of beneficiaries</li>
            <li>Policy Schedule or form (where applicable)</li>
            <span>Additional documentation may be required</span>
          </ul>
        ),
      },
    ],
  },
  {
    id: 3,
    name: (
      <h1>
        family shield <span>plan</span>
      </h1>
    ),
    desc: "Take advantage of an annual renewable plan that provides death and permanent disability coverage.",
    icon: familyShield,
    keyPoints: [
      "Annual renewable plan...",
      "Provides cover for death and permanent disability (resulting from accident only)...",
      "Additional lumpsum amount payable to cover for funeral expenses...",
    ],
    image: familyShiedFull,
    details: [
      {
        key: "0",
        title: "Product Features",
        desc: (
          <ul>
            <li>A minimum annual premium of N10,000 per annum</li>
            <li>Duration of plan: One Year</li>
            <li>No medicals required</li>
          </ul>
        ),
      },
      {
        key: "1",
        title: "Benefits",
        desc: (
          <ul>
            <li>Death benefit amount (NGN1million)</li>
            <li>Permanent Disability amount (NGN1million)</li>
            <li>Funeral benefit amount of NGN100,000</li>
            <li>Payable only on death (from all causes) of the customer</li>
            <li>
              There is a 3-month waiting period for death from natural causes
            </li>
            <li>
              If death is as a result of an accident, when the policy is in the
              waiting period, the Company will pay out the full amount of
              cover..
            </li>
          </ul>
        ),
      },
      {
        key: "4",
        title: "Eligibility Criteria",
        desc: (
          <ul>
            <li>Minimum Minimum age at entry: 18 years</li>
            <li>Maximum age at entry: 64 years</li>
          </ul>
        ),
      },
      {
        key: "2",
        title: "Waiting Period",
        desc: (
          <ul>
            <li>
              There is a waiting period of 3 months for death resulting from
              natural causes. This will only apply in the first policy year.
              Where policyholders renew automatically, there will be no waiting
              period from the first and subsequent renewals. This will also only
              apply if policyholder continuously renew..
            </li>
          </ul>
        ),
      },
      {
        key: "3",
        title: "Policy Duration",
        desc: (
          <ul>
            <li>
              The plan duration is 1 year. Policyholder can elect to renew cover
              after a 12-month period.
            </li>
          </ul>
        ),
      },
      {
        key: "5",
        title: "Cancellation",
        desc: (
          <ul>
            <li>There is no maturity payment in the plan</li>
          </ul>
        ),
      },
      {
        key: "6",
        title: "Maturity Benefit",
        desc: (
          <ul>
            <li>There is no maturity payment in the planF</li>
          </ul>
        ),
      },
      {
        key: "7",
        title: "Surrender Benefit",
        desc: (
          <ul>
            <li>
              This a pure risk policy and do not have any surrender benefit.
              There is no maturity payment in the plan.
            </li>
          </ul>
        ),
      },
      {
        key: "8",
        title: "Claims",
        desc: (
          <ul>
            <span>
              The company will consider Death Claims subject to the following
              conditions:
            </span>
            <li>The Policy must be active</li>
            <li>
              Claims will be paid only for accidental deaths occurring during
              the three-month waiting period.
            </li>
          </ul>
        ),
      },
      {
        key: "9",
        title: "Claims Payment",
        desc: (
          <ul>
            <li>
              In case of the policyholder’s demise, a claim can be made on the
              policy provided the company has accepted the application, received
              the premium and had confirmed this information to you either via
              email or text message. However, there is a waiting period on this
              policy.
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    id: 55,
    name: (
      <h1>
        family shield <span>plus</span>
      </h1>
    ),
    desc: "Get Coverage to help manage expenses in the event of death or hospitalization.",
    icon: familyShieldPlus,
    keyPoints: [
      "Annual renewable plan...",
      "Provides cover for death and permanent disability (resulting from accident only)...",
      "Additional lumpsum amount payable to cover for funeral expenses...",
    ],
    image: familyShiedPlusFull,
    details: [
      {
        key: "0",
        title: "Product Options & Benefits",
        desc: (
          <ul>
            <p>
              The product comes with two (2) options depending on the death and
              hospitalisation amount payable as shown below:
            </p>
            <img src={familyShiedPlusTable} alt="family shield plus table" />

            <span>
              Policyholders are free to choose the option they want between the
              two options available.
            </span>
          </ul>
        ),
      },
      {
        key: "1",
        title: "Hospitalization Cash Benefit",
        desc: (
          <ul>
            <span>
              {" "}
              The plan covers the following hospitalisation cash benefits:
            </span>
            <li>
              Pays a fixed amount per day (a complete 24-hour period) the
              policyholder spends at the hospital regardless of the actual
              expense incurred
            </li>
            <li>
              The hospitalisation benefit payment is subject to 5 days per visit
              and a maximum of 10 days in a year Up to a maximum of two (2)
              visit per year is covered in case of hospitalisation
            </li>
          </ul>
        ),
      },
      {
        key: "2",
        title: "Death Benefit",
        desc: (
          <ul>
            <li>
              There is a ?at death benefit amount payable only on death (from
              all causes) of the customer
            </li>
            <li>
              There is a 3-month waiting period for death from natural causes
            </li>
            <li>
              If death is as a result of an accident any time during the waiting
              period, the Company will pay out the full amount of cover.
            </li>
          </ul>
        ),
      },
      {
        key: "4",
        title: "Surrender/Maturity Benefit",
        desc: (
          <ul>
            <li>
              This a pure risk policy and does not have any surrender nor
              maturity benefit.
            </li>
          </ul>
        ),
      },
      {
        key: "3",
        title: "Premium Payment & Benefits Claim",
        desc: (
          <ul>
            <span>Premium can be paid though the following channels:</span>

            <li>Complete a direct debit form</li>
            <li>Complete a standing instruction form with your bank</li>
            <li>
              Electronic transfer from your account to our account (See details
              below)
            </li>
            <li>Deposit into our bank account (See details below)</li>

            <span>
              Please include your name and policy number on all payments.
            </span>
            <span>
              Do not pay cash to/through our Financial Advisor or sta?. Our
              account details are as follows:
            </span>

            <p>FBNInsurance Limited First Bank Account: 2017174914</p>

            <span>
              In the event of the death of the policyholder, please take the
              following steps:
            </span>

            <li>
              Lodge a formal request by walking into any of our outlets or by
              sending an email to wecare@sanlam.com.ng
            </li>
            <li>
              Submit copies of the following documents:(1) Last attending
              Doctor’s report(2) Death certificate from National Population
              Commission(3) Federal fioad Safety Corps report or Police report
              if death is as a result of accident(4) Valid ID card of
              beneficiaries(5) Policy Schedule or form (where applicable)
            </li>
            <li>Additional documentation may be required</li>
          </ul>
        ),
      },
    ],
  },
  {
    id: 32,
    name: (
      <h1>
        smart protection <span>plan</span>
      </h1>
    ),
    desc: "Treat yourself to full protection in the event of death and total permanent disability",
    icon: smartProtection,
    keyPoints: [
      "Plan against future emergencies; secure the future of our loved ones...",
      "A pure protection plan that also promises to pay back all the premiums that you have paid during the policy duration...",
      "Get your money back when the policy expires ...",
    ],
    image: smartProtectionPlanFull,
    details: [
      {
        key: "0",
        title: "Product Features",
        desc: (
          <ul>
            <li>
              Comprehensive Protection – Death and Total Permanent Disability
              (with inbuilt Accidental Death Benefits)
            </li>
            <li>
              The Base Policy Sum Assured becomes payable when the policyholder
              dies or becomes totally and permanently disabled within the policy
              termAdditional 50% of the base Sum Assured payable to the named
              beneficiaries where death resulted from accident. This implies
              that the benefit in the event of death resulting from accident
              will be 150% of the Sum Assured
            </li>
          </ul>
        ),
      },
      {
        key: "1",
        title: "Return of Premium on Survival",
        desc: (
          <ul>
            <li>
              Guaranteed return of 70% of the total premiums paid in the event
              of survival of the life insured at the end of the plan duration
            </li>
          </ul>
        ),
      },
      {
        key: "2",
        title: "Critical Illness (CI) Benefit",
        desc: (
          <ul>
            <li>
              This is the situation where the Sum Assured amount becomes payable
              when the life is diagnosed of any of the critical illnesses listed
              by the company. This is provided the policy is in force and meets
              the terms and conditions.The CI benefits is 100% of the Sum
              Assured and the policy terminates after the payment.
            </li>
          </ul>
        ),
      },
      {
        key: "4",
        title: "Eligibility Criteria",
        desc: (
          <ul>
            <li>Minimum age at entry: 18 years</li>
            <li>Maximum age at entry: 65 years</li>
            <li>Minimum Premium Amount: N10,000</li>
          </ul>
        ),
      },
      {
        key: "3",
        title: "Why you should buy the Protection Plus Plan?",
        desc: (
          <ul>
            <li>
              Policy provides premium reimbursement at the end of the plan
              duration, therefore, the insured does not lose the premium paid
              during the coverage period.
            </li>
            <li>
              Critical illness benefits – comprehensive protection against
              critical illnesses, including kidney failure, heart attack and
              cancer
            </li>
            <li>Flexible premium payment options as per your budget</li>
            <li>
              Additional accidental death cover should death result from
              accident
            </li>
            <li>
              Tax benefit as premiums paid is exempted from tax computation
            </li>
          </ul>
        ),
      },
      {
        key: "5",
        title: "Why you should buy the Protection Plus Plan?",
        desc: (
          <ul>
            <li>
              Premium payment can be made Quarterly, Half-yearly, Yearly and
              one-off (single)
            </li>
          </ul>
        ),
      },
      {
        key: "6",
        title: "Surrender Benefit",
        desc: (
          <ul>
            <li>
              This is a pure protection plan and do not attract surrender value.
              There is no refund of premium paid under this plan when plan is
              terminated earlier than the plan expiry date. Waiting Period
            </li>
          </ul>
        ),
      },
      {
        key: "7",
        title: "Waiting Period",
        desc: (
          <ul>
            <li>
              There is a three (3) month waiting period in the event of a claim.
              If death occurs within the first three months, the premium paid
              will be refunded and the policy cancelled. The exception is when
              death occurs by accidental causes, in which case the full benefits
              are payable.
            </li>
          </ul>
        ),
      },
      {
        key: "8",
        title: "Term",
        desc: (
          <ul>
            <li>Minimum Duration: 3 years</li>
            <li>Maximum Duration: 25 years</li>
          </ul>
        ),
      },
    ],
  },
  {
    id: 10,
    name: (
      <h1>
        guaranteed lifetime retirement <span>plan</span>
      </h1>
    ),
    desc: "Maintain your standard of living in retirement by ensuring a regular and stable income.",
    icon: glrip,
    keyPoints: [
      "An immediate annuity plan that seeks to help you maintain your desired lifestyle in retirement...",
      "Regular income to a dependent in the event of the annuitant’s demise, if the option is chosen...",
      "Guaranteed income for life subject to a minimum of 10 years...",
    ],
    image: glripFull,
    details: [
      {
        key: "0",
        title: "Product Features",
        desc: (
          <ul>
            <li>Guaranteed and steady income for life.</li>

            <span>What’s in it for YOU?</span>
            <li>
              Guaranteed income for life subject to a minimum of 10 years.
            </li>
            <li>
              Regular income to a dependent in the event of the annuitant’s
              demise, if the option is chosen.
            </li>
            <li>
              Depending on the chosen option, income received can be protected
              from inflation or remain at a flat rate throughout the duration of
              the policy.
            </li>

            <span>
              Further to SECTION 4.0 RULES OF GENERAL APPLICATION of the revised
              Retiree Life Annuity (RLA) regulation.See attached the CPS
              Retirement Pack.
            </span>
          </ul>
        ),
      },
      {
        key: "1",
        title: "Who is it for?",
        desc: (
          <ul>
            <li>
              Prospective retirees who intend to convert their RSA account
              balance to regular income streams
            </li>
            <li>
              Those who want a product which ensures that they do not outlive
              their assets
            </li>
            <li>
              Individuals who have a personal lump sum they want to convert to
              regular income
            </li>
            <li>Those who desire a plan that will pay them for life</li>
          </ul>
        ),
      },
      {
        key: "2",
        title: "Your Commitment",
        desc: (
          <ul>
            <li>
              Use your RSA balance (or lumpsum) to buy a Retirement Income Plan
              from us, to last the rest of your life
            </li>
            <li>Decide on the kind of income you want</li>
          </ul>
        ),
      },
      {
        key: "4",
        title: "How to pay your premium",
        desc: (
          <ul>
            <span>Premium can be paid through the following channels:</span>
            <li>Complete a direct debit form</li>
            <li>Complete a standing instruction form with your bank</li>
            <li>Electronic transfer from your account to our account</li>
            <li>
              Deposit into our bank account (Please include your name and policy
              number on all payments).
            </li>

            <span>
              Do not pay cash to/through our Financial Advisor or Staff .
            </span>
          </ul>
        ),
      },
    ],
  },
  {
    id: 64,
    name: (
      <h1>
        Flexible Education Plus<span>plan</span>
      </h1>
    ),
    // desc: "Maintain your standard of living in retirement by ensuring a regular and stable income.",
    icon: flexiEdu,
    // keyPoints: [
    //   "An immediate annuity plan that seeks to help you maintain your desired lifestyle in retirement...",
    //   "Regular income to a dependent in the event of the annuitant’s demise, if the option is chosen...",
    //   "Guaranteed income for life subject to a minimum of 10 years...",
    // ],
    image: flexiEduFull,
    // details: [
    //   {
    //     key: "0",
    //     title: "Product Features",
    //     desc: (
    //       <ul>
    //         <li>Guaranteed and steady income for life.</li>

    //         <span>What’s in it for YOU?</span>
    //         <li>
    //           Guaranteed income for life subject to a minimum of 10 years.
    //         </li>
    //         <li>
    //           Regular income to a dependent in the event of the annuitant’s
    //           demise, if the option is chosen.
    //         </li>
    //         <li>
    //           Depending on the chosen option, income received can be protected
    //           from inflation or remain at a flat rate throughout the duration of
    //           the policy.
    //         </li>

    //         <span>
    //           Further to SECTION 4.0 RULES OF GENERAL APPLICATION of the revised
    //           Retiree Life Annuity (RLA) regulation.See attached the CPS
    //           Retirement Pack.
    //         </span>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "1",
    //     title: "Who is it for?",
    //     desc: (
    //       <ul>
    //         <li>
    //           Prospective retirees who intend to convert their RSA account
    //           balance to regular income streams
    //         </li>
    //         <li>
    //           Those who want a product which ensures that they do not outlive
    //           their assets
    //         </li>
    //         <li>
    //           Individuals who have a personal lump sum they want to convert to
    //           regular income
    //         </li>
    //         <li>Those who desire a plan that will pay them for life</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "2",
    //     title: "Your Commitment",
    //     desc: (
    //       <ul>
    //         <li>
    //           Use your RSA balance (or lumpsum) to buy a Retirement Income Plan
    //           from us, to last the rest of your life
    //         </li>
    //         <li>Decide on the kind of income you want</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "4",
    //     title: "How to pay your premium",
    //     desc: (
    //       <ul>
    //         <span>Premium can be paid through the following channels:</span>
    //         <li>Complete a direct debit form</li>
    //         <li>Complete a standing instruction form with your bank</li>
    //         <li>Electronic transfer from your account to our account</li>
    //         <li>
    //           Deposit into our bank account (Please include your name and policy
    //           number on all payments).
    //         </li>

    //         <span>
    //           Do not pay cash to/through our Financial Advisor or Staff .
    //         </span>
    //       </ul>
    //     ),
    //   },
    // ],
  },
  {
    id: 68,
    name: <h1>FAMILY LOVE PLAN (PRESTIGE)</h1>,
    // desc: "Maintain your standard of living in retirement by ensuring a regular and stable income.",
    icon: flexiEdu,
    // keyPoints: [
    //   "An immediate annuity plan that seeks to help you maintain your desired lifestyle in retirement...",
    //   "Regular income to a dependent in the event of the annuitant’s demise, if the option is chosen...",
    //   "Guaranteed income for life subject to a minimum of 10 years...",
    // ],
    image: flpp,
    // details: [
    //   {
    //     key: "0",
    //     title: "Product Features",
    //     desc: (
    //       <ul>
    //         <li>Guaranteed and steady income for life.</li>

    //         <span>What’s in it for YOU?</span>
    //         <li>
    //           Guaranteed income for life subject to a minimum of 10 years.
    //         </li>
    //         <li>
    //           Regular income to a dependent in the event of the annuitant’s
    //           demise, if the option is chosen.
    //         </li>
    //         <li>
    //           Depending on the chosen option, income received can be protected
    //           from inflation or remain at a flat rate throughout the duration of
    //           the policy.
    //         </li>

    //         <span>
    //           Further to SECTION 4.0 RULES OF GENERAL APPLICATION of the revised
    //           Retiree Life Annuity (RLA) regulation.See attached the CPS
    //           Retirement Pack.
    //         </span>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "1",
    //     title: "Who is it for?",
    //     desc: (
    //       <ul>
    //         <li>
    //           Prospective retirees who intend to convert their RSA account
    //           balance to regular income streams
    //         </li>
    //         <li>
    //           Those who want a product which ensures that they do not outlive
    //           their assets
    //         </li>
    //         <li>
    //           Individuals who have a personal lump sum they want to convert to
    //           regular income
    //         </li>
    //         <li>Those who desire a plan that will pay them for life</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "2",
    //     title: "Your Commitment",
    //     desc: (
    //       <ul>
    //         <li>
    //           Use your RSA balance (or lumpsum) to buy a Retirement Income Plan
    //           from us, to last the rest of your life
    //         </li>
    //         <li>Decide on the kind of income you want</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "4",
    //     title: "How to pay your premium",
    //     desc: (
    //       <ul>
    //         <span>Premium can be paid through the following channels:</span>
    //         <li>Complete a direct debit form</li>
    //         <li>Complete a standing instruction form with your bank</li>
    //         <li>Electronic transfer from your account to our account</li>
    //         <li>
    //           Deposit into our bank account (Please include your name and policy
    //           number on all payments).
    //         </li>

    //         <span>
    //           Do not pay cash to/through our Financial Advisor or Staff .
    //         </span>
    //       </ul>
    //     ),
    //   },
    // ],
  },
  {
    id: 70,
    name: <h1>FAMILY LOVE PLAN (CLASSIC)</h1>,
    // desc: "Maintain your standard of living in retirement by ensuring a regular and stable income.",
    icon: flexiEdu,
    // keyPoints: [
    //   "An immediate annuity plan that seeks to help you maintain your desired lifestyle in retirement...",
    //   "Regular income to a dependent in the event of the annuitant’s demise, if the option is chosen...",
    //   "Guaranteed income for life subject to a minimum of 10 years...",
    // ],
    image: flpp,
    // details: [
    //   {
    //     key: "0",
    //     title: "Product Features",
    //     desc: (
    //       <ul>
    //         <li>Guaranteed and steady income for life.</li>

    //         <span>What’s in it for YOU?</span>
    //         <li>
    //           Guaranteed income for life subject to a minimum of 10 years.
    //         </li>
    //         <li>
    //           Regular income to a dependent in the event of the annuitant’s
    //           demise, if the option is chosen.
    //         </li>
    //         <li>
    //           Depending on the chosen option, income received can be protected
    //           from inflation or remain at a flat rate throughout the duration of
    //           the policy.
    //         </li>

    //         <span>
    //           Further to SECTION 4.0 RULES OF GENERAL APPLICATION of the revised
    //           Retiree Life Annuity (RLA) regulation.See attached the CPS
    //           Retirement Pack.
    //         </span>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "1",
    //     title: "Who is it for?",
    //     desc: (
    //       <ul>
    //         <li>
    //           Prospective retirees who intend to convert their RSA account
    //           balance to regular income streams
    //         </li>
    //         <li>
    //           Those who want a product which ensures that they do not outlive
    //           their assets
    //         </li>
    //         <li>
    //           Individuals who have a personal lump sum they want to convert to
    //           regular income
    //         </li>
    //         <li>Those who desire a plan that will pay them for life</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "2",
    //     title: "Your Commitment",
    //     desc: (
    //       <ul>
    //         <li>
    //           Use your RSA balance (or lumpsum) to buy a Retirement Income Plan
    //           from us, to last the rest of your life
    //         </li>
    //         <li>Decide on the kind of income you want</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "4",
    //     title: "How to pay your premium",
    //     desc: (
    //       <ul>
    //         <span>Premium can be paid through the following channels:</span>
    //         <li>Complete a direct debit form</li>
    //         <li>Complete a standing instruction form with your bank</li>
    //         <li>Electronic transfer from your account to our account</li>
    //         <li>
    //           Deposit into our bank account (Please include your name and policy
    //           number on all payments).
    //         </li>

    //         <span>
    //           Do not pay cash to/through our Financial Advisor or Staff .
    //         </span>
    //       </ul>
    //     ),
    //   },
    // ],
  },
  {
    id: 42,
    name: <h2>FAMILY LOVE PLAN (CLASSIC)</h2>,
    // desc: "Maintain your standard of living in retirement by ensuring a regular and stable income.",
    icon: flexiEdu,
    // keyPoints: [
    //   "An immediate annuity plan that seeks to help you maintain your desired lifestyle in retirement...",
    //   "Regular income to a dependent in the event of the annuitant’s demise, if the option is chosen...",
    //   "Guaranteed income for life subject to a minimum of 10 years...",
    // ],
    image: flpp,
    // details: [
    //   {
    //     key: "0",
    //     title: "Product Features",
    //     desc: (
    //       <ul>
    //         <li>Guaranteed and steady income for life.</li>

    //         <span>What’s in it for YOU?</span>
    //         <li>
    //           Guaranteed income for life subject to a minimum of 10 years.
    //         </li>
    //         <li>
    //           Regular income to a dependent in the event of the annuitant’s
    //           demise, if the option is chosen.
    //         </li>
    //         <li>
    //           Depending on the chosen option, income received can be protected
    //           from inflation or remain at a flat rate throughout the duration of
    //           the policy.
    //         </li>

    //         <span>
    //           Further to SECTION 4.0 RULES OF GENERAL APPLICATION of the revised
    //           Retiree Life Annuity (RLA) regulation.See attached the CPS
    //           Retirement Pack.
    //         </span>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "1",
    //     title: "Who is it for?",
    //     desc: (
    //       <ul>
    //         <li>
    //           Prospective retirees who intend to convert their RSA account
    //           balance to regular income streams
    //         </li>
    //         <li>
    //           Those who want a product which ensures that they do not outlive
    //           their assets
    //         </li>
    //         <li>
    //           Individuals who have a personal lump sum they want to convert to
    //           regular income
    //         </li>
    //         <li>Those who desire a plan that will pay them for life</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "2",
    //     title: "Your Commitment",
    //     desc: (
    //       <ul>
    //         <li>
    //           Use your RSA balance (or lumpsum) to buy a Retirement Income Plan
    //           from us, to last the rest of your life
    //         </li>
    //         <li>Decide on the kind of income you want</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "4",
    //     title: "How to pay your premium",
    //     desc: (
    //       <ul>
    //         <span>Premium can be paid through the following channels:</span>
    //         <li>Complete a direct debit form</li>
    //         <li>Complete a standing instruction form with your bank</li>
    //         <li>Electronic transfer from your account to our account</li>
    //         <li>
    //           Deposit into our bank account (Please include your name and policy
    //           number on all payments).
    //         </li>

    //         <span>
    //           Do not pay cash to/through our Financial Advisor or Staff .
    //         </span>
    //       </ul>
    //     ),
    //   },
    // ],
  },
  {
    id: 41,
    name: <h1>FAMILY LOVE PLAN (CLASSIC)</h1>,
    // desc: "Maintain your standard of living in retirement by ensuring a regular and stable income.",
    icon: flexiEdu,
    // keyPoints: [
    //   "An immediate annuity plan that seeks to help you maintain your desired lifestyle in retirement...",
    //   "Regular income to a dependent in the event of the annuitant’s demise, if the option is chosen...",
    //   "Guaranteed income for life subject to a minimum of 10 years...",
    // ],
    image: flpp,
    // details: [
    //   {
    //     key: "0",
    //     title: "Product Features",
    //     desc: (
    //       <ul>
    //         <li>Guaranteed and steady income for life.</li>

    //         <span>What’s in it for YOU?</span>
    //         <li>
    //           Guaranteed income for life subject to a minimum of 10 years.
    //         </li>
    //         <li>
    //           Regular income to a dependent in the event of the annuitant’s
    //           demise, if the option is chosen.
    //         </li>
    //         <li>
    //           Depending on the chosen option, income received can be protected
    //           from inflation or remain at a flat rate throughout the duration of
    //           the policy.
    //         </li>

    //         <span>
    //           Further to SECTION 4.0 RULES OF GENERAL APPLICATION of the revised
    //           Retiree Life Annuity (RLA) regulation.See attached the CPS
    //           Retirement Pack.
    //         </span>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "1",
    //     title: "Who is it for?",
    //     desc: (
    //       <ul>
    //         <li>
    //           Prospective retirees who intend to convert their RSA account
    //           balance to regular income streams
    //         </li>
    //         <li>
    //           Those who want a product which ensures that they do not outlive
    //           their assets
    //         </li>
    //         <li>
    //           Individuals who have a personal lump sum they want to convert to
    //           regular income
    //         </li>
    //         <li>Those who desire a plan that will pay them for life</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "2",
    //     title: "Your Commitment",
    //     desc: (
    //       <ul>
    //         <li>
    //           Use your RSA balance (or lumpsum) to buy a Retirement Income Plan
    //           from us, to last the rest of your life
    //         </li>
    //         <li>Decide on the kind of income you want</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "4",
    //     title: "How to pay your premium",
    //     desc: (
    //       <ul>
    //         <span>Premium can be paid through the following channels:</span>
    //         <li>Complete a direct debit form</li>
    //         <li>Complete a standing instruction form with your bank</li>
    //         <li>Electronic transfer from your account to our account</li>
    //         <li>
    //           Deposit into our bank account (Please include your name and policy
    //           number on all payments).
    //         </li>

    //         <span>
    //           Do not pay cash to/through our Financial Advisor or Staff .
    //         </span>
    //       </ul>
    //     ),
    //   },
    // ],
  },
  {
    id: 40,
    name: <h1>FAMILY LOVE PLAN (PRESTIGE)</h1>,
    // desc: "Maintain your standard of living in retirement by ensuring a regular and stable income.",
    icon: flexiEdu,
    // keyPoints: [
    //   "An immediate annuity plan that seeks to help you maintain your desired lifestyle in retirement...",
    //   "Regular income to a dependent in the event of the annuitant’s demise, if the option is chosen...",
    //   "Guaranteed income for life subject to a minimum of 10 years...",
    // ],
    image: flpp,
    // details: [
    //   {
    //     key: "0",
    //     title: "Product Features",
    //     desc: (
    //       <ul>
    //         <li>Guaranteed and steady income for life.</li>

    //         <span>What’s in it for YOU?</span>
    //         <li>
    //           Guaranteed income for life subject to a minimum of 10 years.
    //         </li>
    //         <li>
    //           Regular income to a dependent in the event of the annuitant’s
    //           demise, if the option is chosen.
    //         </li>
    //         <li>
    //           Depending on the chosen option, income received can be protected
    //           from inflation or remain at a flat rate throughout the duration of
    //           the policy.
    //         </li>

    //         <span>
    //           Further to SECTION 4.0 RULES OF GENERAL APPLICATION of the revised
    //           Retiree Life Annuity (RLA) regulation.See attached the CPS
    //           Retirement Pack.
    //         </span>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "1",
    //     title: "Who is it for?",
    //     desc: (
    //       <ul>
    //         <li>
    //           Prospective retirees who intend to convert their RSA account
    //           balance to regular income streams
    //         </li>
    //         <li>
    //           Those who want a product which ensures that they do not outlive
    //           their assets
    //         </li>
    //         <li>
    //           Individuals who have a personal lump sum they want to convert to
    //           regular income
    //         </li>
    //         <li>Those who desire a plan that will pay them for life</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "2",
    //     title: "Your Commitment",
    //     desc: (
    //       <ul>
    //         <li>
    //           Use your RSA balance (or lumpsum) to buy a Retirement Income Plan
    //           from us, to last the rest of your life
    //         </li>
    //         <li>Decide on the kind of income you want</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "4",
    //     title: "How to pay your premium",
    //     desc: (
    //       <ul>
    //         <span>Premium can be paid through the following channels:</span>
    //         <li>Complete a direct debit form</li>
    //         <li>Complete a standing instruction form with your bank</li>
    //         <li>Electronic transfer from your account to our account</li>
    //         <li>
    //           Deposit into our bank account (Please include your name and policy
    //           number on all payments).
    //         </li>

    //         <span>
    //           Do not pay cash to/through our Financial Advisor or Staff .
    //         </span>
    //       </ul>
    //     ),
    //   },
    // ],
  },
  {
    id: 69,
    name: <h1>FAMILY LOVE PLAN (INCLUSIVE)</h1>,
    // desc: "Maintain your standard of living in retirement by ensuring a regular and stable income.",
    icon: flexiEdu,
    // keyPoints: [
    //   "An immediate annuity plan that seeks to help you maintain your desired lifestyle in retirement...",
    //   "Regular income to a dependent in the event of the annuitant’s demise, if the option is chosen...",
    //   "Guaranteed income for life subject to a minimum of 10 years...",
    // ],
    image: flpp,
    // details: [
    //   {
    //     key: "0",
    //     title: "Product Features",
    //     desc: (
    //       <ul>
    //         <li>Guaranteed and steady income for life.</li>

    //         <span>What’s in it for YOU?</span>
    //         <li>
    //           Guaranteed income for life subject to a minimum of 10 years.
    //         </li>
    //         <li>
    //           Regular income to a dependent in the event of the annuitant’s
    //           demise, if the option is chosen.
    //         </li>
    //         <li>
    //           Depending on the chosen option, income received can be protected
    //           from inflation or remain at a flat rate throughout the duration of
    //           the policy.
    //         </li>

    //         <span>
    //           Further to SECTION 4.0 RULES OF GENERAL APPLICATION of the revised
    //           Retiree Life Annuity (RLA) regulation.See attached the CPS
    //           Retirement Pack.
    //         </span>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "1",
    //     title: "Who is it for?",
    //     desc: (
    //       <ul>
    //         <li>
    //           Prospective retirees who intend to convert their RSA account
    //           balance to regular income streams
    //         </li>
    //         <li>
    //           Those who want a product which ensures that they do not outlive
    //           their assets
    //         </li>
    //         <li>
    //           Individuals who have a personal lump sum they want to convert to
    //           regular income
    //         </li>
    //         <li>Those who desire a plan that will pay them for life</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "2",
    //     title: "Your Commitment",
    //     desc: (
    //       <ul>
    //         <li>
    //           Use your RSA balance (or lumpsum) to buy a Retirement Income Plan
    //           from us, to last the rest of your life
    //         </li>
    //         <li>Decide on the kind of income you want</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "4",
    //     title: "How to pay your premium",
    //     desc: (
    //       <ul>
    //         <span>Premium can be paid through the following channels:</span>
    //         <li>Complete a direct debit form</li>
    //         <li>Complete a standing instruction form with your bank</li>
    //         <li>Electronic transfer from your account to our account</li>
    //         <li>
    //           Deposit into our bank account (Please include your name and policy
    //           number on all payments).
    //         </li>

    //         <span>
    //           Do not pay cash to/through our Financial Advisor or Staff .
    //         </span>
    //       </ul>
    //     ),
    //   },
    // ],
  },
  {
    id: 29,
    name: (
      <h1>
        Flexible Education Plus<span>plan</span>
      </h1>
    ),
    // desc: "Maintain your standard of living in retirement by ensuring a regular and stable income.",
    icon: flexiEdu,
    // keyPoints: [
    //   "An immediate annuity plan that seeks to help you maintain your desired lifestyle in retirement...",
    //   "Regular income to a dependent in the event of the annuitant’s demise, if the option is chosen...",
    //   "Guaranteed income for life subject to a minimum of 10 years...",
    // ],
    image: flexiEduFull,
    // details: [
    //   {
    //     key: "0",
    //     title: "Product Features",
    //     desc: (
    //       <ul>
    //         <li>Guaranteed and steady income for life.</li>

    //         <span>What’s in it for YOU?</span>
    //         <li>
    //           Guaranteed income for life subject to a minimum of 10 years.
    //         </li>
    //         <li>
    //           Regular income to a dependent in the event of the annuitant’s
    //           demise, if the option is chosen.
    //         </li>
    //         <li>
    //           Depending on the chosen option, income received can be protected
    //           from inflation or remain at a flat rate throughout the duration of
    //           the policy.
    //         </li>

    //         <span>
    //           Further to SECTION 4.0 RULES OF GENERAL APPLICATION of the revised
    //           Retiree Life Annuity (RLA) regulation.See attached the CPS
    //           Retirement Pack.
    //         </span>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "1",
    //     title: "Who is it for?",
    //     desc: (
    //       <ul>
    //         <li>
    //           Prospective retirees who intend to convert their RSA account
    //           balance to regular income streams
    //         </li>
    //         <li>
    //           Those who want a product which ensures that they do not outlive
    //           their assets
    //         </li>
    //         <li>
    //           Individuals who have a personal lump sum they want to convert to
    //           regular income
    //         </li>
    //         <li>Those who desire a plan that will pay them for life</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "2",
    //     title: "Your Commitment",
    //     desc: (
    //       <ul>
    //         <li>
    //           Use your RSA balance (or lumpsum) to buy a Retirement Income Plan
    //           from us, to last the rest of your life
    //         </li>
    //         <li>Decide on the kind of income you want</li>
    //       </ul>
    //     ),
    //   },
    //   {
    //     key: "4",
    //     title: "How to pay your premium",
    //     desc: (
    //       <ul>
    //         <span>Premium can be paid through the following channels:</span>
    //         <li>Complete a direct debit form</li>
    //         <li>Complete a standing instruction form with your bank</li>
    //         <li>Electronic transfer from your account to our account</li>
    //         <li>
    //           Deposit into our bank account (Please include your name and policy
    //           number on all payments).
    //         </li>

    //         <span>
    //           Do not pay cash to/through our Financial Advisor or Staff .
    //         </span>
    //       </ul>
    //     ),
    //   },
    // ],
  },
];
