import React from "react";

export const Declaration = () => {
  return (
    <div className="declaration-container">
      <div
        style={{
          padding: "13px",
          fontSize: 17,
        }}
      >
        <div style={{ marginBottom: "15px" }}>
          I agree that this application is subject to Policy terms and
          conditions. I declare that all information provided above is correct.
          I understand that the information provided by me and any documents
          required by Sanlam shall be the basis of the policy. I declare that
          all the information provided by me, whether in my own handwriting or
          not, is true and complete. I accept that I am curtailing my right to
          privacy to facilitate the assessment of the risk and of any claims for
          benefits under any policy issued in respect of this application.
        </div>
      </div>

      <div style={{ padding: "13px", fontSize: 17 }}>
        <h3 style={{ fontSize: 17 }}>I irrevocably authorize:</h3>
        <ul style={{ padding: "10px", margin: 0 }}>
          <li>
            Sanlam to obtain from any person, whom I hereby so authorize and
            request to give, any information which Sanlam deems necessary, and
            to share with other insurers that information and any information
            contained in this application or in any related policy or other
            document.
          </li>
          <br />
          <li>
            I acknowledge receipt of the policy terms and conditions associated
            with this Policy application
          </li>
          <br />
          <li>
            I acknowledge That I have read and understood these declarations.
          </li>
        </ul>
      </div>
      {/* <Summary setIsUploadPage={setIsUploadPage} /> */}
    </div>
  );
};
