import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdOutlineCancel } from "react-icons/md";
import OtpInput from "react-otp-input";
import { useAlert } from "react-alert";
import { verifyOtp } from "../services";
import { AuthContext } from "../helpers/AuthContext";
import otpimg from "../assets/otpemail.jpg";

export const Otp = ({ isOtp, setIsOtp, quoteId, email }) => {
  const [otp, setOtp] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);

  const { steps, setSteps } = useContext(AuthContext);

  const alert = useAlert();

  const verifyOtps = () => {
    setIsVerifying(true);
    verifyOtp(quoteId, otp)
      .then((res) => {
        if (res.status === "success") {
          setSteps(steps + 1);
          setIsOtp(false);
          setIsVerifying(false);
          setOtp("");
          alert.success(res.message);
        } else {
          alert.error(res.message);
          setIsVerifying(false);
          setOtp("");
        }
      })
      .catch((error) => {
        alert.error("something went wrong");
        setOtp("");
        setIsVerifying(false);
      });
  };
  return (
    <Modal
      size="md"
      show={isOtp}
      onHide={() => setIsOtp(false)}
      aria-labelledby="example-modal-sizes-title-sm"
      // fullscreen={"sm-down"}
      centered
      className="jaf"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <p></p>
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "100%",
            backgroundColor: "#f3f3f3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={otpimg} alt="otp-email" width="30px" />
        </div>

        <MdOutlineCancel
          onClick={() => {
            setIsOtp(false);
          }}
          style={{ cursor: "pointer" }}
        />
      </div>
      <Modal.Body
        style={{
          width: "90%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "90%",
          }}
        >
          <h6 style={{ fontSize: "1.4rem" }}>Verify your email address</h6>

          <p
            style={{
              textAlign: "center",
              marginTop: "10px",
              color: "#818181",
              fontSize: "0.9rem",
            }}
          >
            We sent a 4 digit code to <b>{email}</b>. Input the code to confirm
            your email address
          </p>

          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            // renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
            containerStyle={{ width: "100%", justifyContent: "center" }}
            inputStyle={{
              width: "3em",
              marginTop: "10px",
              marginBottom: "30px",
              marginRight: "5px",
            }}
          />
          <button
            className="blue-button"
            onClick={() => {
              verifyOtps();
            }}
          >
            {isVerifying ? "Verifying otp..." : "Verify"}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
