import React, { useContext, useState } from "react";
import { Form, Row, Col, Accordion } from "react-bootstrap";
import { AuthContext } from "../helpers/AuthContext";
import { handlePersonalInfoChange } from "./utils";

export const Medicals = ({ productId, productCode }) => {
  const { personalInfo, setPersonalInfo, dependants, setDependants } =
    useContext(AuthContext);
  const [currentDependant, setCurrentDependant] = useState();

  const handleChange = (index, field, value) => {
    setDependants((prevState) => {
      const updatedDependants = [...prevState];
      updatedDependants[index].medicals[field] = value;

      console.log(updatedDependants);
      return updatedDependants;
    });
  };

  const dependantMedicalInfo = (id, index) => {
    return (
      <Row
        className="mb-1 container-fluid mx-auto"
        style={{ maxWidth: "800px" }}
      >
        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever suffered from any serious personal accident involving
            fractured skull and bones or unconciousness or other injury?{" "}
            <span className="asterik">*</span>
          </Form.Label>
          <div key={`inline-radio`} className="mb-3">
            <Form.Check
              inline
              label="No"
              name={`medQ1_${id}`}
              onChange={(e) => {
                handleChange(index, "medQ1", e.target.value);
              }}
              type={"radio"}
              id={`inline-radio-1`}
              value="No"
              // checked={personalInfo.medQ1_main === "No" ? true : false}
            />
            <Form.Check
              inline
              label="Yes"
              name={`medQ1_${id}`}
              type={"radio"}
              onChange={(e) => {
                handleChange(index, "medQ1", e.target.value);
              }}
              id={`inline-radio-2`}
              value="Yes"
              // checked={personalInfo.medQ1_main === "Yes" ? true : false}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever suffered from epilepsy or other mental disturbances?{" "}
            <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name={`medQ2_${id}`}
            onChange={(e) => {
              handleChange(index, "medQ2", e.target.value);
            }}
            type={"radio"}
            id={`inline-radio-3`}
            value="No"
            // checked={personalInfo.medQ2_main === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name={`medQ2_${id}`}
            type={"radio"}
            onChange={(e) => {
              handleChange(index, "medQ2", e.target.value);
            }}
            id={`inline-radio-4`}
            value="Yes"
            // checked={personalInfo.medQ2_main === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever been treated for tuberculosis, asthma, pneumonia or
            any other chest related diseases? <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name={`medQ3_${id}`}
            onChange={(e) => {
              handleChange(index, "medQ3", e.target.value);
            }}
            type={"radio"}
            id={`inline-radio-5`}
            value="No"
            // checked={personalInfo.medQ3_main === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name={`medQ3_${id}`}
            type={"radio"}
            onChange={(e) => {
              handleChange(index, "medQ3", e.target.value);
            }}
            id={`inline-radio-6`}
            value="Yes"
            // checked={personalInfo.medQ3_main === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you experienced recurrent or persistent fever or skin disorder?{" "}
            <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name={`medQ4_${id}`}
            onChange={(e) => {
              handleChange(index, "medQ4", e.target.value);
            }}
            type={"radio"}
            id={`inline-radio-7`}
            value="No"
            // checked={personalInfo.medQ4_main === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name={`medQ4_${id}`}
            type={"radio"}
            onChange={(e) => {
              handleChange(index, "medQ4", e.target.value);
            }}
            id={`inline-radio-8`}
            value="Yes"
            // checked={personalInfo.medQ4_main === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever been diagnosed of any kidney, liver, vital internal
            organs infection or blood in urine?{" "}
            <span className="asterik">*</span>
          </Form.Label>

          <Form.Check
            inline
            label="No"
            name={`medQ5_${id}`}
            onChange={(e) => {
              handleChange(index, "medQ5", e.target.value);
            }}
            type={"radio"}
            id={`inline-radio-9`}
            value="No"
            // checked={personalInfo.medQ5_main === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name={`medQ5_${id}`}
            type={"radio"}
            onChange={(e) => {
              handleChange(index, "medQ5", e.target.value);
            }}
            id={`inline-radio-10`}
            value="Yes"
            // checked={personalInfo.medQ5_main === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever been diagnosed or suffered from any cancer,
            cancer-related disease, tumour, any other terminal illness disease?{" "}
            <span className="asterik">*</span>
          </Form.Label>

          <Form.Check
            inline
            label="No"
            name={`medQ6_${id}`}
            onChange={(e) => {
              handleChange(index, "medQ6", e.target.value);
            }}
            type={"radio"}
            id={`inline-radio-11`}
            value="No"
            // checked={personalInfo.medQ6_main === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name={`medQ6_${id}`}
            type={"radio"}
            onChange={(e) => {
              handleChange(index, "medQ6", e.target.value);
            }}
            id={`inline-radio-12`}
            value="Yes"
            // checked={personalInfo.medQ6_main === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever been diagnosed or suffered from heart disease, high
            blood pressure, stroke or any cardiovascular related diseases?{" "}
            <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name={`medQ7_${id}`}
            onChange={(e) => {
              handleChange(index, "medQ7", e.target.value);
            }}
            type={"radio"}
            id={`inline-radio-13`}
            value="No"
            // checked={personalInfo.medQ7_main === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name={`medQ7_${id}`}
            type={"radio"}
            onChange={(e) => {
              handleChange(index, "medQ7", e.target.value);
            }}
            id={`inline-radio-14`}
            value="Yes"
            // checked={personalInfo.medQ7_main === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever been diagnosed or suffered from any blood sugar
            related disease, diabetes and/or diabetic related illness?{" "}
            <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name={`medQ8_${id}`}
            onChange={(e) => {
              handleChange(index, "medQ8", e.target.value);
            }}
            type={"radio"}
            id={`inline-radio-15`}
            value="No"
            // checked={personalInfo.medQ8_main === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name={`medQ8_${id}`}
            type={"radio"}
            onChange={(e) => {
              handleChange(index, "medQ8", e.target.value);
            }}
            id={`inline-radio-16`}
            value="Yes"
            // checked={personalInfo.medQ8_main === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever had any minor or major organ transplant?{" "}
            <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name={`medQ9_${id}`}
            onChange={(e) => {
              handleChange(index, "medQ9", e.target.value);
            }}
            type={"radio"}
            id={`inline-radio-17`}
            value="No"
            // checked={personalInfo.medQ9_main === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name={`medQ9_${id}`}
            type={"radio"}
            onChange={(e) => {
              handleChange(index, "medQ9", e.target.value);
            }}
            id={`inline-radio-18`}
            value="Yes"
            // checked={personalInfo.medQ9_main === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever been diagnosed or suffered from any other ailment or
            illness not mentioned in 1-9 above? If yes, please state the ailment
            or illnwss and the date it was discovered or diagnosed.{" "}
            <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name={`medQ10_${id}`}
            onChange={(e) => {
              handleChange(index, "medQ10", e.target.value);
            }}
            type={"radio"}
            id={`inline-radio-19`}
            value="No"
            // checked={personalInfo.medQ10_main === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name={`medQ10_${id}`}
            type={"radio"}
            onChange={(e) => {
              handleChange(index, "medQ10", e.target.value);
            }}
            id={`inline-radio-20`}
            value="Yes"
            // checked={personalInfo.medQ10_main === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Do you consume alcohol? If Yes, please state your daily consumption
            in litres. <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name={`medQ11_${id}`}
            onChange={(e) => {
              handleChange(index, "medQ11", e.target.value);
            }}
            type={"radio"}
            id={`inline-radio-21`}
            value="No"
            // checked={personalInfo.medQ11_main === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name={`medQ11_${id}`}
            type={"radio"}
            onChange={(e) => {
              handleChange(index, "medQ11", e.target.value);
            }}
            id={`inline-radio-22`}
            value="Yes"
            // checked={personalInfo.medQ11_main === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Do you consume tobacco? If Yes, please state your daily consumption
            in sticks. <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name={`medQ12_${id}`}
            onChange={(e) => {
              handleChange(index, "medQ12", e.target.value);
            }}
            type={"radio"}
            id={`inline-radio-23`}
            value="No"
            // checked={personalInfo.medQ12_main === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name={`medQ12_${id}`}
            type={"radio"}
            onChange={(e) => {
              handleChange(index, "medQ12", e.target.value);
            }}
            id={`inline-radio-24`}
            value="Yes"
            // checked={personalInfo.medQ12_main === "Yes" ? true : false}
          />
        </Form.Group>
        {(dependants[index].medicals.medQ11 === "Yes" ||
          dependants[index].medicals.medQ12 === "Yes") && (
          <>
            {dependants[index].medicals.medQ11 === "Yes" && (
              <Form.Group className="mb-4" as={Col} md={6}>
                <Form.Label>Enter number (In Litres)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="2"
                  name={`medQ11Text_${id}`}
                  onChange={(e) => {
                    handleChange(index, "medQ11Text", e.target.value);
                  }}
                />
              </Form.Group>
            )}
            {dependants[index].medicals.medQ12 === "Yes" && (
              <Form.Group className="mb-4" as={Col} md={6}>
                <Form.Label>Enter stick amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="5"
                  name={`medQ12Text_${id}`}
                  onChange={(e) => {
                    handleChange(index, "medQ12Text", e.target.value);
                  }}
                />
              </Form.Group>
            )}
          </>
        )}

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Height <span className="asterik">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Height (without shoes) in CM"
            name="height"
            onChange={(e) => {
              handleChange(index, "height", e.target.value);
            }}
            value={dependants[index].medicals.height}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Weight <span className="asterik">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Weight In KG"
            name="weight"
            onChange={(e) => {
              handleChange(index, "weight", e.target.value);
            }}
            value={dependants[index].medicals.weight}
          />
        </Form.Group>

        {(dependants[index].medicals.medQ1 === "Yes" ||
          dependants[index].medicals.medQ2 === "Yes" ||
          dependants[index].medicals.medQ3 === "Yes" ||
          dependants[index].medicals.medQ4 === "Yes" ||
          dependants[index].medicals.medQ5 === "Yes" ||
          dependants[index].medicals.medQ6 === "Yes" ||
          dependants[index].medicals.medQ7 === "Yes" ||
          dependants[index].medicals.medQ8 === "Yes" ||
          dependants[index].medicals.medQ9 === "Yes" ||
          dependants[index].medicals.medQ10 === "Yes") && (
          <>
            <div className="single-input-container">
              <Form.Group className="mb-3">
                <Form.Label>
                  Based on your "YES" reply to a question above, please provide
                  details below:
                </Form.Label>
              </Form.Group>
            </div>

            <Form.Group className="mb-4" as={Col} md={6}>
              <Form.Label>Doctor's Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="John Snow"
                name="doctorName"
                onChange={(e) => {
                  handleChange(index, "doctorName", e.target.value);
                }}
                value={dependants[index].medicals.doctorName}
              />
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md={6}>
              <Form.Label>Doctor's Phone number</Form.Label>
              <Form.Control
                type="phone"
                placeholder="07067896171"
                name="doctorPhone"
                onChange={(e) => {
                  handleChange(index, "doctorPhone", e.target.value);
                }}
                value={dependants[index].medicals.doctorPhone}
              />
            </Form.Group>

            <Form.Group className="mb-4" as={Col} md={7}>
              <Form.Label>Name of hospital</Form.Label>
              <Form.Control
                type="text"
                placeholder="Hospital Name"
                name="hospitalName"
                onChange={(e) => {
                  handleChange(index, "hospitalName", e.target.value);
                }}
                value={dependants[index].medicals.hospitalName}
              />
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md={5}>
              <Form.Label>
                How long have you been a patient of this doctor
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="6 years"
                name="doctorDuration"
                onChange={(e) => {
                  handleChange(index, "doctorDuration", e.target.value);
                }}
                value={dependants[index].medicals.doctorDuration}
              />
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md={12}>
              <Form.Label>Hospital address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Hospital Address"
                name="hospitalAddress"
                onChange={(e) => {
                  handleChange(index, "hospitalAddress", e.target.value);
                }}
                value={dependants[index].medicals.hospitalAddress}
              />
            </Form.Group>
          </>
        )}
      </Row>
    );
  };

  const medicalInfo = () => {
    return (
      <Row
        className="mb-1 container-fluid mx-auto"
        style={{ maxWidth: "800px" }}
      >
        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever suffered from any serious personal accident involving
            fractured skull and bones or unconciousness or other injury?{" "}
            <span className="asterik">*</span>
          </Form.Label>
          <div key={`inline-radio`} className="mb-3">
            <Form.Check
              inline
              label="No"
              name="medQ1"
              onChange={(e) => {
                handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
              }}
              type={"radio"}
              id={`inline-radio-1`}
              value="No"
              checked={personalInfo.medQ1 === "No" ? true : false}
            />
            <Form.Check
              inline
              label="Yes"
              name="medQ1"
              type={"radio"}
              onChange={(e) => {
                handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
              }}
              id={`inline-radio-2`}
              value="Yes"
              checked={personalInfo.medQ1 === "Yes" ? true : false}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever suffered from epilepsy or other mental disturbances?{" "}
            <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name="medQ2"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            type={"radio"}
            id={`inline-radio-3`}
            value="No"
            checked={personalInfo.medQ2 === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name="medQ2"
            type={"radio"}
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            id={`inline-radio-4`}
            value="Yes"
            checked={personalInfo.medQ2 === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever been treated for tuberculosis, asthma, pneumonia or
            any other chest related diseases? <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name="medQ3"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            type={"radio"}
            id={`inline-radio-5`}
            value="No"
            checked={personalInfo.medQ3 === "No" ? true : false}
            // checked
          />
          <Form.Check
            inline
            label="Yes"
            name="medQ3"
            type={"radio"}
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            id={`inline-radio-6`}
            value="Yes"
            checked={personalInfo.medQ3 === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you experienced recurrent or persistent fever or skin disorder?{" "}
            <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name="medQ4"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            type={"radio"}
            id={`inline-radio-7`}
            value="No"
            checked={personalInfo.medQ4 === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name="medQ4"
            type={"radio"}
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            id={`inline-radio-8`}
            value="Yes"
            checked={personalInfo.medQ4 === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever been diagnosed of any kidney, liver, vital internal
            organs infection or blood in urine?{" "}
            <span className="asterik">*</span>
          </Form.Label>

          <Form.Check
            inline
            label="No"
            name="medQ5"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            type={"radio"}
            id={`inline-radio-9`}
            value="No"
            checked={personalInfo.medQ5 === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name="medQ5"
            type={"radio"}
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            id={`inline-radio-10`}
            value="Yes"
            checked={personalInfo.medQ5 === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever been diagnosed or suffered from any cancer,
            cancer-related disease, tumour, any other terminal illness disease?{" "}
            <span className="asterik">*</span>
          </Form.Label>

          <Form.Check
            inline
            label="No"
            name="medQ6"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            type={"radio"}
            id={`inline-radio-11`}
            value="No"
            checked={personalInfo.medQ6 === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name="medQ6"
            type={"radio"}
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            id={`inline-radio-12`}
            value="Yes"
            checked={personalInfo.medQ6 === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever been diagnosed or suffered from heart disease, high
            blood pressure, stroke or any cardiovascular related diseases?{" "}
            <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name="medQ7"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            type={"radio"}
            id={`inline-radio-13`}
            value="No"
            checked={personalInfo.medQ7 === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name="medQ7"
            type={"radio"}
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            id={`inline-radio-14`}
            value="Yes"
            checked={personalInfo.medQ7 === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever been diagnosed or suffered from any blood sugar
            related disease, diabetes and/or diabetic related illness?{" "}
            <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name="medQ8"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            type={"radio"}
            id={`inline-radio-15`}
            value="No"
            checked={personalInfo.medQ8 === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name="medQ8"
            type={"radio"}
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            id={`inline-radio-16`}
            value="Yes"
            checked={personalInfo.medQ8 === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever had any minor or major organ transplant?{" "}
            <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name="medQ9"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            type={"radio"}
            id={`inline-radio-17`}
            value="No"
            checked={personalInfo.medQ9 === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name="medQ9"
            type={"radio"}
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            id={`inline-radio-18`}
            value="Yes"
            checked={personalInfo.medQ9 === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Have you ever been diagnosed or suffered from any other ailment or
            illness not mentioned in 1-9 above? If yes, please state the ailment
            or illnwss and the date it was discovered or diagnosed.{" "}
            <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name="medQ10"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            type={"radio"}
            id={`inline-radio-19`}
            value="No"
            checked={personalInfo.medQ10 === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name="medQ10"
            type={"radio"}
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            id={`inline-radio-20`}
            value="Yes"
            checked={personalInfo.medQ10 === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Do you consume alcohol? If Yes, please state your daily consumption
            in litres. <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name="medQ11"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            type={"radio"}
            id={`inline-radio-21`}
            value="No"
            checked={personalInfo.medQ11 === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name="medQ11"
            type={"radio"}
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            id={`inline-radio-22`}
            value="Yes"
            checked={personalInfo.medQ11 === "Yes" ? true : false}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Do you consume tobacco? If Yes, please state your daily consumption
            in sticks. <span className="asterik">*</span>
          </Form.Label>
          <Form.Check
            inline
            label="No"
            name="medQ12"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            type={"radio"}
            id={`inline-radio-23`}
            value="No"
            checked={personalInfo.medQ12 === "No" ? true : false}
          />
          <Form.Check
            inline
            label="Yes"
            name="medQ12"
            type={"radio"}
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            id={`inline-radio-24`}
            value="Yes"
            checked={personalInfo.medQ12 === "Yes" ? true : false}
          />
        </Form.Group>
        {(personalInfo.medQ11 === "Yes" || personalInfo.medQ12 === "Yes") && (
          <>
            {personalInfo.medQ11 === "Yes" && (
              <Form.Group className="mb-4" as={Col} md={6}>
                <Form.Label>Enter number (In Litres)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="2"
                  name="medQ11Text"
                  onChange={(e) => {
                    handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
                  }}
                />
              </Form.Group>
            )}
            {personalInfo.medQ12 === "Yes" && (
              <Form.Group className="mb-4" as={Col} md={6}>
                <Form.Label>Enter stick amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="5"
                  name="medQ12Text"
                  onChange={(e) => {
                    handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
                  }}
                />
              </Form.Group>
            )}
          </>
        )}

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Height <span className="asterik">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Height (without shoes) in CM"
            name="height"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.height}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} md={6} controlId="formFullName">
          <Form.Label>
            Weight <span className="asterik">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Weight In KG"
            name="weight"
            onChange={(e) => {
              handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
            }}
            value={personalInfo.weight}
          />
        </Form.Group>

        {(personalInfo.medQ1 === "Yes" ||
          personalInfo.medQ2 === "Yes" ||
          personalInfo.medQ3 === "Yes" ||
          personalInfo.medQ4 === "Yes" ||
          personalInfo.medQ5 === "Yes" ||
          personalInfo.medQ6 === "Yes" ||
          personalInfo.medQ7 === "Yes" ||
          personalInfo.medQ8 === "Yes" ||
          personalInfo.medQ9 === "Yes" ||
          personalInfo.medQ10 === "Yes") && (
          <>
            <div className="single-input-container">
              <Form.Group className="mb-3">
                <Form.Label>
                  Based on your "YES" reply to a question above, please provide
                  details below:
                </Form.Label>
              </Form.Group>
            </div>

            <Form.Group className="mb-4" as={Col} md={6}>
              <Form.Label>Doctor's Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="John Snow"
                name="doctorName"
                onChange={(e) => {
                  handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
                }}
                value={personalInfo.doctorName}
              />
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md={6}>
              <Form.Label>Doctor's Phone number</Form.Label>
              <Form.Control
                type="phone"
                placeholder="07067896171"
                name="doctorPhone"
                onChange={(e) => {
                  handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
                }}
                value={personalInfo.doctorPhone}
              />
            </Form.Group>

            <Form.Group className="mb-4" as={Col} md={7}>
              <Form.Label>Name of hospital</Form.Label>
              <Form.Control
                type="text"
                placeholder="Hospital Name"
                name="hospitalName"
                onChange={(e) => {
                  handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
                }}
                value={personalInfo.hospitalName}
              />
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md={5}>
              <Form.Label>
                How long have you been a patient of this doctor
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="6 years"
                name="doctorDuration"
                onChange={(e) => {
                  handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
                }}
                value={personalInfo.doctorDuration}
              />
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md={12}>
              <Form.Label>Hospital address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Hospital Address"
                name="hospitalAddress"
                onChange={(e) => {
                  handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
                }}
                value={personalInfo.hospitalAddress}
              />
            </Form.Group>
          </>
        )}
      </Row>
    );
  };

  return productCode === "FLSP" ? (
    <Accordion defaultActiveKey="0">
      {
        <>
          <div className="dependants-header">
            <h1>Main Life</h1>
            <p>
              Fill in the right medical responses for the main life of this
              policy
            </p>
          </div>
          {medicalInfo()}
        </>
      }
      <div className="dependants-header">
        <h1>Dependants</h1>
        <p>
          Fill in the right medical responses for the dependants of this policy
        </p>
      </div>
      {dependants.map((dependant, index) => {
        return (
          <Accordion.Item eventKey={dependant.id} key={dependant.id}>
            <Accordion.Header>
              <h6>
                {dependant.name} ({dependant.beneficiary})
              </h6>
            </Accordion.Header>
            <Accordion.Body>
              {dependantMedicalInfo(dependant.id, index)}
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  ) : (
    medicalInfo()
  );
};
