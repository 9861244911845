import React from "react";
import instagram from "../../assets/instagram.png";
import twitter from "../../assets/twitter.png";
import facebook from "../../assets/facebook_copy.png";
import linkedin from "../../assets/linkedin.png";
import footerLogo from "../../assets/logo.png";
import { MdCall, MdLocationPin, MdMail } from "react-icons/md";
import { FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { ImFacebook2 } from "react-icons/im";
import { Col, Row } from "react-bootstrap";
import { Divider } from "@mui/material";

export const Footer = () => {
  return (
    <>
      <Row style={{ marginTop: "20px", width: "100%", paddingLeft: "40px" }}>
        <Col sm={6} className="footer-cont" style={{ marginTop: "20px" }}>
          <img
            src={footerLogo}
            alt="logo"
            style={{ width: "220px", marginBottom: "30px" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "10px",
              // justifyContent: "center",
            }}
          >
            <MdCall style={{ marginRight: "10px" }} />
            <div style={{ color: "#464e58" }}>
              <p>01-9054444</p>
              <p>01-9054365</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "10px",
              // justifyContent: "center",
            }}
          >
            <MdMail style={{ marginRight: "10px" }} />

            <p style={{ color: "#464e58" }}>wecare@sanlam.com.ng</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "10px",
              // justifyContent: "center",
            }}
          >
            <MdLocationPin style={{ marginRight: "10px" }} />

            <p style={{ color: "#464e58" }}>
              NIPOST Building Marina, 34 Marina Rd, Lagos, Nigeria
            </p>
          </div>
        </Col>
        <Col sm={2} style={{ marginTop: "20px" }}>
          <h5 style={{ color: "#007dcc", marginBottom: "30px" }}>
            Our Products
          </h5>
          <ul>
            <li>
              <a
                href="https://sanlam.com.ng/retail"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#464e58", textDecoration: "none" }}
              >
                Retail
              </a>
            </li>
            <li>
              <a
                href="https://sanlam.com.ng/corporate"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#464e58", textDecoration: "none" }}
              >
                Corporate
              </a>
            </li>
          </ul>
        </Col>
        <Col sm={2} style={{ marginTop: "20px" }}>
          <h5 style={{ color: "#007dcc", marginBottom: "30px" }}>About Us</h5>
          <ul style={{ color: "#464e58" }}>
            <li>
              <a
                href="https://sanlam.com.ng/about-us"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#464e58", textDecoration: "none" }}
              >
                Sanlam Nigeria
              </a>
            </li>
            <li>
              <a
                href="https://sanlam.com.ng/our-purpose"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#464e58", textDecoration: "none" }}
              >
                Our Values
              </a>
            </li>
            <li>
              <a
                href="https://sanlam.com.ng/self-service/faqs"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#464e58", textDecoration: "none" }}
              >
                FAQs
              </a>
            </li>
            <li>
              <a
                href="https://sanlam.com.ng/whistleblowing-policy"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#464e58", textDecoration: "none" }}
              >
                Whistleblowing-policy
              </a>
            </li>
            <li>
              <a
                href="https://sanlam.com.ng/privacy-statement"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#464e58", textDecoration: "none" }}
              >
                Privacy Statement
              </a>
            </li>
            <li>
              <a
                href="https://sanlam.com.ng/privacy-policy"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#464e58", textDecoration: "none" }}
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </Col>
        <Col sm={2} style={{ marginTop: "20px" }}>
          <h5 style={{ color: "#007dcc", marginBottom: "30px" }}>Contact Us</h5>
          <ul style={{ color: "#464e58" }}>
            <li>
              <a
                href="https://sanlam.com.ng/contact-us"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#464e58", textDecoration: "none" }}
              >
                Contact Us
              </a>
            </li>
            <li>
              <a
                href="https://sanlam.com.ng/find-our-sales-outlets"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#464e58", textDecoration: "none" }}
              >
                Find our sales outlets
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      <Divider style={{ color: "#000" }} />
      <Row
        style={{
          width: "100%",
          paddingLeft: "40px",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <Col
          sm={8}
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            fontSize: "0.7rem",
            color: "#667085",
          }}
        >
          <span>Copyright © 2022. Sanlam Life Insurance Ltd. RC 707564</span>
          <span>
            All Rights Reserved and also Authorized and Regulated by the
            National Insurance Commission. RIC NO. 050
          </span>
        </Col>
        <Col sm={4}>
          <section className="footer-icons-container">
            <a
              href="https://www.youtube.com/channel/UCu1oR5YXn3H73dWYCe30bKw"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#007dcc", fontSize: "1.3rem" }}
            >
              <FaYoutube />
            </a>
            <a
              href="https://twitter.com/SanlamNigeria"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#007dcc", fontSize: "1.3rem" }}
            >
              <FaTwitter />
            </a>
            <a
              href="https://www.instagram.com/sanlamnigeria/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#007dcc", fontSize: "1.3rem" }}
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.facebook.com/SanlamNigeria"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#007dcc", fontSize: "1.3rem" }}
            >
              <ImFacebook2 />
            </a>
            <a
              href="https://www.linkedin.com/company/sanlamnigeria/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#007dcc", fontSize: "1.3rem" }}
            >
              <FaLinkedin />
            </a>
          </section>
        </Col>
      </Row>
    </>
  );
};
