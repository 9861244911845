import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { BiMenuAltLeft } from "react-icons/bi";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../assets/logo_copy.png";
import { Button, Form, Offcanvas } from "react-bootstrap";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

export const AppNavbar = ({ name }) => {
  return (
    <div className="navbar-container">
      <Navbar expand="lg" className="navbar">
        <div className="nav-controller">
          <Navbar.Brand href="/" className="logo">
            <img src={logo} alt="logo" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <BiMenuAltLeft />
          </Navbar.Toggle>
        </div>
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-xxl}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-xxl}`}
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xxl}`}>
              <img src={logo} alt="logo" className="logo" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-start align-items-center flex-grow-1 pe-3">
              <Nav.Link href="/">Home</Nav.Link>
              <Link
                to="body-container"
                spy={true}
                smooth={true}
                offset={-20}
                duration={2000}
              >
                <Nav.Link href="#products">Products</Nav.Link>
              </Link>
              <Nav.Link
                href="https://sanlam.com.ng/media/blog"
                target="_blank"
                rel="noreferrer"
              >
                Blog
              </Nav.Link>
              <Nav.Link
                href="https://sanlam.com.ng/contact-us"
                target="_blank"
                rel="noreferrer"
              >
                Contact
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </div>
  );
};
