import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export const Packages = ({ icon, heading, desc, id, abbr }) => {
  return (
    <>
      <div className="package-container">
        <div>
          <div className="icon-container">
            {icon && <img src={icon} alt="icon" />}
          </div>
          <h1>{heading}</h1>
          <p>{desc}</p>
        </div>
        <div className="btn-buy-now me-2 mb-2">
          <Link
            to={`/product/${abbr}`}
            onClick={() => {
              sessionStorage.setItem(
                "person",
                JSON.stringify({
                  firstName: "",
                  middleName: "",
                  lastName: "",
                  phone: "",
                  email: "",
                  dob: "",
                })
              );
            }}
          >
            Buy now
          </Link>
        </div>
      </div>
    </>
  );
};
