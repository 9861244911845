import React, { useContext } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { AuthContext } from "../helpers/AuthContext";
import { handlePersonalInfoChange } from "./utils";

export const Referral = () => {
  const { personalInfo, setPersonalInfo } = useContext(AuthContext);
  return (
    <Row className="mb-1 container-fluid mx-auto" style={{ maxWidth: "800px" }}>
      <Form.Group className="mb-4" as={Col} md={6} controlId="formBasicEmail">
        <Form.Label>How did you hear about us?</Form.Label>
        <Form.Control
          type="text"
          placeholder="Select an answer"
          name="hearAboutUs"
          onChange={(e) => {
            handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
          }}
          value={personalInfo.hearAboutUs}
        />
      </Form.Group>

      <Form.Group className="mb-4" as={Col} md={6} controlId="formDateOfBirth">
        <Form.Label>Do you have a referral code?</Form.Label>
        <Form.Select
          type="text"
          placeholder="Yes"
          name="haveReferralCode"
          onChange={(e) => {
            handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
          }}
          value={personalInfo.haveReferralCode}
        >
          <option value="">Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      {personalInfo.haveReferralCode === "Yes" && (
        <>
          <Form.Group
            className="mb-4"
            as={Col}
            md={12}
            controlId="formDateOfBirth"
          >
            <Form.Label>Referral code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Input referral code"
              name="referralCode"
              className="single-input"
              onChange={(e) => {
                handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
              }}
              value={personalInfo.referralCode}
            />
          </Form.Group>
        </>
      )}
    </Row>
  );
};
