import React, { useContext } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { AuthContext } from "../helpers/AuthContext";
import { handlePersonalInfoChange, upload } from "./utils";

export const UploadDocuments = ({
  idRef,
  passportRef,
  addressRef,
  signatureRef,
}) => {
  const {
    personalInfo,
    setPersonalInfo,
    setUploadType,
    setIsUploading,
    uploadType,
    setIdImage,
    setAddressImage,
    setSignatureImage,
    idImage,
    isUploading,
    addressImage,
    signatureImage,
    passportImage,
    setPassportImage,
  } = useContext(AuthContext);

  const shouldShowExpirationDate =
    personalInfo.idCardType === "Drivers License" ||
    personalInfo.idCardType === "International Passport";

  const today = new Date();
  const todayDate = today.toISOString().substr(0, 10);

  return (
    <>
      <div className="upload-container">
        <div className="upload-details">
          <h1>
            Means of Identification <span className="asterik">*</span>
          </h1>
          <p>(National ID, Voters ID, Passport or Drivers License)</p>
          <Row className="mb-1 container-fluid mx-auto" style={{ padding: 0 }}>
            <Form.Group
              className="mb-4"
              as={Col}
              md={6}
              controlId="formBasicEmail"
              style={{ padding: 0 }}
            >
              <Form.Label>
                ID card type <span className="asterik">*</span>{" "}
              </Form.Label>

              <Form.Select
                type="text"
                placeholder="Select Id"
                name="idCardType"
                onChange={(e) => {
                  handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
                }}
                value={personalInfo.idCardType}
                required
              >
                <option></option>
                <option value="Drivers License">Drivers License</option>
                <option value="International Passport">
                  International Passport
                </option>
                <option value="National ID">National ID</option>
                <option value="Voters Card">Voters Card</option>
                <option value="Work ID">Work ID</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please select an id card type
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-4"
              as={Col}
              md={6}
              controlId="formDateOfBirth"
              style={{ padding: 0 }}
            >
              <Form.Label>
                Id number <span className="asterik">*</span>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="ID card number"
                name="cardNumber"
                onChange={(e) => {
                  handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
                }}
                value={personalInfo.cardNumber}
                style={{ height: "76px !important" }}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter your id card number
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-4"
              as={Col}
              md={6}
              controlId="formDateOfBirth"
              style={{ padding: 0 }}
            >
              <Form.Label>
                Issuance Date <span className="asterik">*</span>{" "}
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="dd/mm/yyyy"
                name="issuanceDate"
                onChange={(e) => {
                  handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
                }}
                value={personalInfo.issuanceDate}
                required
                max={todayDate}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter the id issuance date
              </Form.Control.Feedback>
            </Form.Group>
            {/* {shouldShowExpirationDate && ( */}
            <Form.Group
              className="mb-4"
              as={Col}
              md={6}
              controlId="formExpirationDate"
              style={{ padding: 0 }}
            >
              <Form.Label>
                Expiration date <span className="asterik">*</span>{" "}
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="dd/mm/yyyy"
                name="expirationDate"
                onChange={(e) => {
                  handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
                }}
                value={personalInfo.expirationDate}
                required
                min={todayDate}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter the expiration date
              </Form.Control.Feedback>
            </Form.Group>
            {/* )} */}
          </Row>
          <span
            onClick={() => {
              idRef.current.click();
            }}
          >
            Upload
          </span>
        </div>

        <div className="upload-image">
          <input
            className="upload-image"
            type="file"
            onClick={() => {
              setUploadType("id");
            }}
            onChange={(e) => {
              upload(
                e,
                setIsUploading,
                uploadType,
                setIdImage,
                setAddressImage,
                setSignatureImage
              );
            }}
            name="img"
            style={{
              opacity: 0,
              display: idImage === "" ? "flex" : "none",
            }}
            ref={idRef}
          />

          {uploadType === "id" && isUploading && (
            <p
              style={{
                fontSize: "12px",
                color: "#007dcc",
                fontWeight: 500,
              }}
            >
              Uploading...
            </p>
          )}

          {idImage !== "" && (
            <img
              src={idImage}
              alt="ID"
              style={{ width: "100%", height: "100%" }}
            />
          )}
        </div>
      </div>

      <div className="upload-container">
        <div className="upload-details">
          <h1>
            Passport <span className="asterik">*</span>
          </h1>
          <p>(A clear Passport photograph)</p>
          <span
            onClick={() => {
              passportRef.current.click();
            }}
          >
            Upload
          </span>
        </div>

        <div className="upload-image">
          <input
            className="upload-image"
            type="file"
            onClick={() => {
              setUploadType("passport");
            }}
            onChange={(e) => {
              setUploadType("passport");
              upload(
                e,
                setIsUploading,
                uploadType,
                setIdImage,
                setAddressImage,
                setSignatureImage,
                setPassportImage
              );
            }}
            name="img"
            style={{
              opacity: 0,
              display: passportImage === "" ? "flex" : "none",
            }}
            ref={passportRef}
          />

          {uploadType === "passport" && isUploading && (
            <p
              style={{
                fontSize: "12px",
                color: "#007dcc",
                fontWeight: 500,
              }}
            >
              Uploading...
            </p>
          )}

          {passportImage !== "" && (
            <img
              src={passportImage}
              alt="passport"
              style={{ width: "100%", height: "100%" }}
            />
          )}
        </div>
      </div>
      <div className="upload-container">
        <div className="upload-details">
          <h1>
            Evidence of address <span className="asterik">*</span>
          </h1>
          <p>(Electricity bill, water bill, Phone bill, Bank statement)</p>
          <span
            onClick={() => {
              addressRef.current.click();
            }}
          >
            Upload
          </span>
        </div>

        <div className="upload-image">
          <input
            className="upload-image"
            type="file"
            onClick={() => {
              setUploadType("address");
            }}
            onChange={(e) => {
              setUploadType("address");
              upload(
                e,
                setIsUploading,
                uploadType,
                setIdImage,
                setAddressImage,
                setSignatureImage
              );
            }}
            name="img"
            style={{
              opacity: 0,
              display: addressImage === "" ? "flex" : "none",
            }}
            ref={addressRef}
          />

          {uploadType === "address" && isUploading && (
            <p
              style={{
                fontSize: "12px",
                color: "#007dcc",
                fontWeight: 500,
              }}
            >
              Uploading...
            </p>
          )}

          {addressImage !== "" && (
            <img
              src={addressImage}
              alt="proof"
              style={{ width: "100%", height: "100%" }}
            />
          )}
        </div>
      </div>
      <div className="upload-container">
        <div className="upload-details">
          <h1>
            Upload signature <span className="asterik">*</span>
          </h1>
          <p>(Upload a clear signature on a white sheet of paper)</p>
          <span
            onClick={() => {
              signatureRef.current.click();
            }}
          >
            Upload
          </span>
        </div>

        <div className="upload-image">
          <input
            className="upload-image"
            type="file"
            onClick={() => {
              setUploadType("signature");
            }}
            onChange={(e) => {
              setUploadType("signature");
              upload(
                e,
                setIsUploading,
                uploadType,
                setIdImage,
                setAddressImage,
                setSignatureImage
              );
            }}
            name="img"
            style={{
              opacity: 0,
              display: signatureImage === "" ? "flex" : "none",
            }}
            ref={signatureRef}
          />
          {uploadType === "signature" && isUploading && (
            <p
              style={{
                fontSize: "12px",
                color: "#007dcc",
                fontWeight: 500,
              }}
            >
              Uploading...
            </p>
          )}
          {signatureImage !== "" && (
            <img
              src={signatureImage}
              alt="signature"
              style={{ width: "100%", height: "100%" }}
            />
          )}
        </div>
      </div>
      <p></p>
    </>
  );
};
