import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import verificationImage from "../assets/verificationImage.png";
import { addPayment } from "../services";

export const VerificationModal = ({
  isVerification,
  setIsVerification,
  responseData,
}) => {
  const quoteInfo = sessionStorage.getItem("quoteInfo");
  const parsedQuote = JSON.parse(quoteInfo);

  // useEffect(() => {
  //   addPayment(responseData?.data).then((res) => {
  //     console.log(res);
  //   });
  // }, []);

  return (
    <Modal
      size="md"
      show={isVerification}
      onHide={() => setIsVerification(false)}
      aria-labelledby="example-modal-sizes-title-sm"
      // fullscreen={"sm-down"}
      centered
      backdrop="static"
      className="jaf"
    >
      <Modal.Header>
        <p></p>
        <img src={verificationImage} alt="recurrent" />
        <p></p>
      </Modal.Header>
      <Modal.Body>
        <div className="recurrent-container">
          <h6>
            {responseData && responseData.status === "success"
              ? "Payment Received!"
              : "Something went wrong"}
          </h6>
          <p style={{ textAlign: "center" }}>
            {responseData && responseData.status === "success"
              ? `We are pleased to confirm that we have received your payment for the ${parsedQuote.product} policy. Your policy will be effective after 72 hours. Please tap the button below to go to the homepage`
              : responseData && responseData.message}
          </p>
          <button
            className="blue-button"
            onClick={(e) => {
              e.preventDefault();
              window.open(`${process.env.REACT_APP_PROD_HOME_URL}`);
            }}
          >
            Okay
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
