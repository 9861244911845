import React, { useContext } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { BiPencil } from "react-icons/bi";
import { GrClose } from "react-icons/gr";
import { AuthContext } from "../helpers/AuthContext";
import {
  handleAddBeneficiary,
  handleBeneficiaryChange,
  handleRemoveBeneficiary,
  isMinor,
  selectBeneficiaryRelationships,
  selectBeneficiaryState,
  selectCities,
} from "./utils";
import { useAlert } from "react-alert";

export const Beneficiaries = ({ currentStateCities }) => {
  const {
    beneficiaryInfo,
    setBeneficiaryInfo,
    relationshipsData,
    statesData,
    beneficiaries,
    setBeneficiaries,
    totalProportions,
  } = useContext(AuthContext);

  const alert = useAlert();

  return (
    <Form>
      <Row
        className="mb-1 container-fluid mx-auto"
        style={{ maxWidth: "800px" }}
      >
        <Form.Group className="mb-4" as={Col} md={12}>
          <Form.Label>Number of beneficiaries?</Form.Label>
          <Form.Control
            type="text"
            placeholder="Number of beneficiaries"
            name="numOfBeneficiaries"
            onChange={(e) => {
              handleBeneficiaryChange(e, beneficiaryInfo, setBeneficiaryInfo);
            }}
            value={beneficiaryInfo.numOfBeneficiaries}
          />
        </Form.Group>
        {beneficiaryInfo.numOfBeneficiaries > 0 && (
          <>
            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formBasicEmail"
            >
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Adeyekan Temitope"
                name="beneficiaryFullName"
                onChange={(e) => {
                  handleBeneficiaryChange(
                    e,
                    beneficiaryInfo,
                    setBeneficiaryInfo
                  );
                }}
                value={beneficiaryInfo.beneficiaryFullName}
              />
            </Form.Group>

            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formDateOfBirth"
            >
              <Form.Label>Date of birth</Form.Label>
              <Form.Control
                type="date"
                placeholder="dd/mm/yyyy"
                name="beneficiaryDob"
                onChange={(e) => {
                  handleBeneficiaryChange(
                    e,
                    beneficiaryInfo,
                    setBeneficiaryInfo
                  );
                }}
                value={beneficiaryInfo.beneficiaryDob}
              />
            </Form.Group>

            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formBasicEmail"
            >
              <Form.Label>Select relationship</Form.Label>
              {selectBeneficiaryRelationships(
                relationshipsData.data,
                beneficiaryInfo,
                setBeneficiaryInfo
              )}
            </Form.Group>

            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formDateOfBirth"
            >
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="07067896171"
                name="beneficiaryPhone"
                onChange={(e) => {
                  handleBeneficiaryChange(
                    e,
                    beneficiaryInfo,
                    setBeneficiaryInfo
                  );
                }}
                value={beneficiaryInfo.beneficiaryPhone}
              />
            </Form.Group>

            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formBasicEmail"
            >
              <Form.Label>State</Form.Label>
              {selectBeneficiaryState(
                statesData.data,
                beneficiaryInfo,
                setBeneficiaryInfo
              )}
            </Form.Group>
            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formBasicEmail"
            >
              <Form.Label>Cities</Form.Label>
              {selectCities(
                currentStateCities,
                beneficiaryInfo,
                setBeneficiaryInfo
              )}
            </Form.Group>

            <Form.Group
              className="mb-4"
              as={Col}
              md={4}
              controlId="formDateOfBirth"
            >
              <Form.Label>Gender</Form.Label>
              <Form.Select
                type="text"
                placeholder="Select a gender"
                name="beneficiaryGender"
                onChange={(e) => {
                  handleBeneficiaryChange(
                    e,
                    beneficiaryInfo,
                    setBeneficiaryInfo
                  );
                }}
                value={beneficiaryInfo.beneficiaryGender}
              >
                <option value="">Select an option</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Form.Select>
            </Form.Group>

            <Form.Group
              className="mb-4"
              as={Col}
              md={8}
              controlId="formDateOfBirth"
            >
              <Form.Label>Home address</Form.Label>
              <Form.Control
                type="text"
                placeholder="725 Adeola Hopewell, Victoria Island Lagos"
                name="beneficiaryAddress"
                onChange={(e) => {
                  handleBeneficiaryChange(
                    e,
                    beneficiaryInfo,
                    setBeneficiaryInfo
                  );
                }}
                value={beneficiaryInfo.beneficiaryAddress}
              />
            </Form.Group>
            {isMinor(beneficiaryInfo.beneficiaryDob) && (
              <>
                <Form.Group
                  className="mb-4"
                  as={Col}
                  md={4}
                  controlId="formDateOfBirth"
                >
                  <Form.Label>Guardian Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="John Doe"
                    name="beneficiaryGuardianName"
                    onChange={(e) => {
                      handleBeneficiaryChange(
                        e,
                        beneficiaryInfo,
                        setBeneficiaryInfo
                      );
                    }}
                    value={beneficiaryInfo.beneficiaryGuardianName}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-4"
                  as={Col}
                  md={4}
                  controlId="formDateOfBirth"
                >
                  <Form.Label>Guardian Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="07067896171"
                    name="beneficiaryGuardianPhone"
                    onChange={(e) => {
                      handleBeneficiaryChange(
                        e,
                        beneficiaryInfo,
                        setBeneficiaryInfo
                      );
                    }}
                    value={beneficiaryInfo.beneficiaryGuardianPhone}
                  />
                </Form.Group>
              </>
            )}
            {beneficiaryInfo.numOfBeneficiaries > 1 && (
              <>
                <Form.Group
                  className="mb-4"
                  as={Col}
                  md={4}
                  controlId="formDateOfBirth"
                >
                  <Form.Label>Beneficiary Proportion %</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="20"
                    name="beneficiaryProportion"
                    onChange={(e) => {
                      handleBeneficiaryChange(
                        e,
                        beneficiaryInfo,
                        setBeneficiaryInfo
                      );
                    }}
                    value={beneficiaryInfo.beneficiaryProportion}
                  />
                </Form.Group>
              </>
            )}
            {beneficiaries.length < beneficiaryInfo.numOfBeneficiaries && (
              <div className="add-beneficiary-container">
                <button
                  onClick={(event) => {
                    handleAddBeneficiary(
                      event,
                      beneficiaryInfo,
                      beneficiaries,
                      setBeneficiaries,
                      setBeneficiaryInfo,
                      alert
                    );
                  }}
                >
                  {beneficiaryInfo.numOfBeneficiaries > 1
                    ? "+ Save/Add another beneficiary"
                    : "Save beneficiary"}
                </button>
                <span style={{ fontSize: "0.7rem" }}>
                  NB: Please click "Save" to add beneficiary before proceeding{" "}
                </span>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                paddingLeft: "13px",
                gap: "15px",
                flexWrap: "wrap",
              }}
            >
              {beneficiaries.length > 0 &&
                beneficiaries.map((beneficiary) => {
                  return (
                    <p
                      style={{
                        backgroundColor: "#b0b0b0",
                        color: "#000",
                        border: "none",
                        borderRadius: "6px",
                        padding: "6px",
                        fontSize: "0.7rem",
                        fontWeight: 600,
                      }}
                      key={beneficiary.id}
                    >
                      {beneficiary.firstname} {beneficiary.percentage}%{" "}
                      <BiPencil
                        onClick={() => {
                          setBeneficiaryInfo({
                            ...beneficiaryInfo,
                            beneficiaryFullName: beneficiary.firstname,
                            beneficiaryDob: beneficiary.dob,
                            beneficiaryRelationship: beneficiary.relationship,
                            beneficiaryPhone: beneficiary.beneficiaryPhone,
                            beneficiaryState: beneficiary.state,
                            beneficiaryGender: beneficiary.beneficiaryGender,
                            beneficiaryAddress: beneficiary.street_address,
                            beneficiaryProportion: beneficiary.percentage,
                            beneficiaryGuardianName: beneficiary.guardian,
                            beneficiaryGuardianPhone:
                              beneficiary.beneficiaryGuardianPhone,
                          });
                          handleRemoveBeneficiary(
                            beneficiary.id,
                            beneficiaries,
                            setBeneficiaries
                          );
                        }}
                        style={{
                          marginRight: "7px",
                          marginLeft: "4px",
                        }}
                      />{" "}
                      <GrClose
                        onClick={() => {
                          handleRemoveBeneficiary(
                            beneficiary.id,
                            beneficiaries,
                            setBeneficiaries
                          );
                        }}
                      />
                    </p>
                  );
                })}
            </div>
            {beneficiaryInfo.numOfBeneficiaries > 1 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  width: "95%",
                }}
              >
                <p
                  style={{ marginBottom: 0, fontSize: "0.8rem" }}
                >{`Total Proportion: ${totalProportions}% / 100%`}</p>
                {totalProportions !== 100 && (
                  <p style={{ color: "red", fontSize: "0.7rem" }}>
                    Total proportion cannot be lesser or greater than 100%{" "}
                  </p>
                )}
              </div>
            )}
          </>
        )}
      </Row>
    </Form>
  );
};
