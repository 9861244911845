import React from "react";
import { AppNavbar } from "../components/AppNavbar";
import { Body } from "../components/homepage/Body";
import { Footer } from "../components/homepage/Footer";
import { Header } from "../components/homepage/Header";

export const Homepage = () => {
  return (
    <div className="homepage">
      <AppNavbar />
      <Header />
      <Body />
      <Footer />
    </div>
  );
};
