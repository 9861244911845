import React, { useEffect, useState } from "react";
import { AppNavbar } from "../components/AppNavbar";
import { Footer } from "../components/homepage/Footer";
import { useParams } from "react-router-dom";
import { data } from "../data";
import GoToTop from "../components/GoToTop";
import { Link } from "react-scroll";
import flpImage from "../assets/flpp.png";
import { KYC } from "../components/KYC";
import { PrestigeDetails } from "../components/PrestigeDetails";
import { ClassicDetails } from "../components/ClassicDetails";
import { InclusiveDetails } from "../components/InclusiveDetails";

export const ProductPage = () => {
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [minDuration, setMinDuration] = useState(0);
  const [maxDuration, setMaxDuration] = useState(0);
  const [productId, setProductId] = useState(0);
  const [productCode, setProductCode] = useState("");
  const [details, setDetails] = useState(null);
  const [image, setImage] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [availableAmounts, setAvailableAmounts] = useState([]);
  const { abbr } = useParams();
  const [fullProduct, setFullProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const productData = data.filter((product) => product.id === productId);

  const getProduct = async () => {
    setIsLoading(true);
    const product = await fetch(
      `${process.env.REACT_APP_PROD_URL}product/${abbr}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
    setIsLoading(false);
    setFullProduct(product);
  };

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    if (
      fullProduct &&
      fullProduct.status === "success" &&
      fullProduct.data !== null
    ) {
      setName(fullProduct && fullProduct.data.name);
      setDesc(fullProduct && fullProduct.data.short_description);
      setImage(fullProduct && fullProduct.data.default_image);
      setDetails(fullProduct && fullProduct.data.description);
      setMinDuration(fullProduct && fullProduct.data.policy_term_min);
      setMaxDuration(fullProduct && fullProduct.data.policy_term_max);
      setProductId(fullProduct && fullProduct.data.id);
      setProductCode(fullProduct && fullProduct.data.abbreviation);
      setAvailableAmounts(fullProduct && fullProduct.data.available_amounts);
    }
    return;
  }, [fullProduct, abbr]);

  const productImage = productData.map((product) => {
    return product.image;
  });

  // console.log(fullProduct && fullProduct);
  return (
    <>
      <AppNavbar />
      <div className="product-page">
        <div className="product-page-container">
          {abbr === "FLP" ? (
            <>
              <div className="product-page-header">
                <div className="product-page-texts">
                  <div className="product-page-header-info">
                    <h1>Family Love Plans</h1>
                    <p>
                      Family Love Plan is a funeral product that is designed to
                      provide financial protection to you and loved families to
                      finance the cost of funeral expenses of your Family
                      members.
                    </p>
                  </div>
                  <div className="header-btns">
                    <KYC
                      name={"Family Love Plan"}
                      setShowForm={setShowForm}
                      minDuration={minDuration}
                      maxDuration={maxDuration}
                      productId={"9999"}
                      availableAmounts={[
                        {
                          premium: "0.00",
                          sum_assured: "500000.00",
                        },
                        {
                          premium: "0.00",
                          sum_assured: "1000000.00",
                        },
                        {
                          premium: "0.00",
                          sum_assured: "1500000.00",
                        },
                        {
                          premium: "0.00",
                          sum_assured: "2000000.00",
                        },
                        {
                          premium: "0.00",
                          sum_assured: "2500000.00",
                        },
                        {
                          premium: "0.00",
                          sum_assured: "3000000.00",
                        },
                        {
                          premium: "0.00",
                          sum_assured: "3500000.00",
                        },
                        {
                          premium: "0.00",
                          sum_assured: "4000000.00",
                        },
                        {
                          premium: "0.00",
                          sum_assured: "4500000.00",
                        },
                        {
                          premium: "0.00",
                          sum_assured: "5000000.00",
                        },
                      ]}
                      productCode={"FLP"}
                      costPerUnit={fullProduct?.data?.cost_per_unit}
                    />
                    <span>OR</span>
                    <Link
                      to="product-info-container"
                      spy={true}
                      smooth={true}
                      offset={-20}
                      duration={2000}
                    >
                      <button className="white-button">See products</button>
                    </Link>
                  </div>
                </div>

                <img src={flpImage} alt="productImage" />
              </div>

              <div className="product-info-container">
                {selectedProduct === null && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p>Select a family love product to see details</p>
                  </div>
                )}
                <div className="flp-btns">
                  <button
                    className={selectedProduct === "p" && "active-flp-btn"}
                    onClick={() => {
                      setSelectedProduct("p");
                    }}
                  >
                    PRESTIGE
                  </button>
                  <button
                    className={selectedProduct === "i" && "active-flp-btn"}
                    onClick={() => {
                      setSelectedProduct("i");
                    }}
                  >
                    INCLUSIVE
                  </button>
                  <button
                    className={selectedProduct === "c" && "active-flp-btn"}
                    onClick={() => {
                      setSelectedProduct("c");
                    }}
                  >
                    CLASSIC
                  </button>
                </div>
                {selectedProduct === "p" && <PrestigeDetails />}
                {selectedProduct === "i" && <InclusiveDetails />}
                {selectedProduct === "c" && <ClassicDetails />}
                {selectedProduct !== null && (
                  <KYC
                    name={"Family Love Plan"}
                    setShowForm={setShowForm}
                    minDuration={minDuration}
                    maxDuration={maxDuration}
                    productId={"9999"}
                    availableAmounts={[
                      {
                        premium: "0.00",
                        sum_assured: "500000.00",
                      },
                      {
                        premium: "0.00",
                        sum_assured: "1000000.00",
                      },
                      {
                        premium: "0.00",
                        sum_assured: "1500000.00",
                      },
                      {
                        premium: "0.00",
                        sum_assured: "2000000.00",
                      },
                      {
                        premium: "0.00",
                        sum_assured: "2500000.00",
                      },
                      {
                        premium: "0.00",
                        sum_assured: "3000000.00",
                      },
                      {
                        premium: "0.00",
                        sum_assured: "3500000.00",
                      },
                      {
                        premium: "0.00",
                        sum_assured: "4000000.00",
                      },
                      {
                        premium: "0.00",
                        sum_assured: "4500000.00",
                      },
                      {
                        premium: "0.00",
                        sum_assured: "5000000.00",
                      },
                    ]}
                    productCode={"FLP"}
                    costPerUnit={fullProduct?.data?.cost_per_unit}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              {isLoading ? (
                <div className="getting-product">
                  <h1>Getting Product...</h1>
                </div>
              ) : (
                <div className="product-page-header">
                  <div className="product-page-texts">
                    <div className="product-page-header-info">
                      <h1>{name}</h1>
                      <p>{desc}</p>
                    </div>
                    <div className="header-btns">
                      <KYC
                        name={name}
                        setShowForm={setShowForm}
                        minDuration={minDuration}
                        maxDuration={maxDuration}
                        productId={productId}
                        availableAmounts={availableAmounts}
                        productCode={productCode}
                        costPerUnit={fullProduct?.data?.cost_per_unit}
                      />
                      <span>OR</span>
                      <Link
                        to="product-info-container"
                        spy={true}
                        smooth={true}
                        offset={-20}
                        duration={2000}
                      >
                        <button className="white-button">
                          See product details
                        </button>
                      </Link>
                    </div>
                  </div>
                  {productImage && productImage.length > 0 ? (
                    <img src={productImage} alt="productImage" />
                  ) : (
                    <img
                      src="https://fbn-service-staging.s3.eu-west-1.amazonaws.com/files/O9u3DrWWYq5zj29ZGyYFSD4b0gyH9txFSPWKNEjZ.jpg"
                      alt="productImage"
                    />
                  )}
                </div>
              )}
              {!isLoading && (
                <div className="product-info-container">
                  <h1>Product Details</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${details}`,
                    }}
                  ></div>
                  {/* <KYC
        name={name}
        setShowForm={setShowForm}
        minDuration={minDuration}
        maxDuration={maxDuration}
        productId={productId}
        availableAmounts={availableAmounts}
        productCode={productCode}
      /> */}
                </div>
              )}
            </>
          )}
        </div>
        <Footer />
        <GoToTop />
      </div>
    </>
  );
};
