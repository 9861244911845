import { Form } from "react-bootstrap";
import { addProposal } from "../services";
import { postReferral } from "./CommonActions";

export function isMinor(dateString) {
  const inputDate = new Date(dateString);
  const today = new Date();
  let ageDiff = today.getFullYear() - inputDate.getFullYear();
  const monthDiff = today.getMonth() - inputDate.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < inputDate.getDate())
  ) {
    ageDiff--;
  }
  return ageDiff < 18;
}
export const handleBeneficiaryChange = (
  e,
  beneficiaryInfo,
  setBeneficiaryInfo
) => {
  const name = e.target.name;
  const value =
    name === "beneficiaryPhone" || name === "numOfBeneficiaries"
      ? e.target.value.replace(/\D/g, "")
      : e.target.value;
  setBeneficiaryInfo({ ...beneficiaryInfo, [name]: value });
};
export const handlePersonalInfoChange = (e, personalInfo, setPersonalInfo) => {
  const name = e.target.name;
  const value =
    name === "phone" ||
    name === "accountNumber" ||
    name === "bvn" ||
    name === "height" ||
    name === "weight" ||
    name === "doctorsPhone"
      ? e.target.value.replace(/\D/g, "")
      : e.target.value;
  setPersonalInfo({ ...personalInfo, [name]: value });
};

export const handleAddBeneficiary = (
  event,
  beneficiaryInfo,
  beneficiaries,
  setBeneficiaries,
  setBeneficiaryInfo,
  alert
) => {
  event.preventDefault();
  if (beneficiaries.length < beneficiaryInfo.numOfBeneficiaries) {
    if (
      beneficiaryInfo.beneficiaryCity === "" ||
      beneficiaryInfo.beneficiaryDob === "" ||
      beneficiaryInfo.beneficiaryFullName === "" ||
      beneficiaryInfo.numOfBeneficiaries === "" ||
      beneficiaryInfo.beneficiaryRelationship === "" ||
      beneficiaryInfo.beneficiaryState === "" ||
      beneficiaryInfo.beneficiaryAddress === ""
    ) {
      alert.error("Please fill in all beneficiary info");
      return;
    }
    const beneficiary = {
      city: beneficiaryInfo.beneficiaryCity,
      dob: beneficiaryInfo.beneficiaryDob,
      firstname: beneficiaryInfo.beneficiaryFullName,
      guardian: beneficiaryInfo.beneficiaryGuardianName,
      lastname: beneficiaryInfo.beneficiaryFullName,
      percentage:
        parseInt(beneficiaryInfo.numOfBeneficiaries) === 1
          ? 100
          : beneficiaryInfo.beneficiaryProportion,
      relationship: beneficiaryInfo.beneficiaryRelationship,
      state: beneficiaryInfo.beneficiaryState,
      street_address: beneficiaryInfo.beneficiaryAddress,
    };
    setBeneficiaries([...beneficiaries, beneficiary]);
    setBeneficiaryInfo({
      ...beneficiaryInfo,
      beneficiaryFullName: "",
      beneficiaryDob: "",
      beneficiaryRelationship: "",
      beneficiaryPhone: "",
      beneficiaryState: "",
      beneficiaryGender: "",
      beneficiaryAddress: "",
      beneficiaryProportion: "",
      beneficiaryGuardianName: "",
      beneficiaryGuardianPhone: "",
      beneficiaryCity: "",
    });
    return;
  }
};

export const handleRemoveBeneficiary = (
  id,
  beneficiaries,
  setBeneficiaries
) => {
  const newBeneficiaries = beneficiaries.filter(
    (beneficiary) => beneficiary.id !== id
  );
  setBeneficiaries(newBeneficiaries);
};

export function handleShow(breakpoint, setFullscreen, setSmShow) {
  setFullscreen(breakpoint);
  setSmShow(true);
}

export const handleNextStep = (setSteps, steps) => {
  setSteps(steps + 1);
};
export const handlePrevStep = (setSteps, steps) => {
  if (steps > 1) {
    setSteps(steps - 1);
    return;
  }
};

export const formHeadings = [
  {
    step: 4,
    title: "Personal Info",
    subtitle: "Home address, Occupation etc...",
  },
  {
    step: 5,
    title: "Bank Details",
    subtitle: "Account number, Account name etc...",
  },
  {
    step: 6,
    title: "Beneficiaries",
    subtitle: "Please enter the details for the beneficiary of this policy...",
  },
  {
    step: 7,
    title: "Referral",
    subtitle: "Please let us know how you heard about us...",
  },
  {
    step: 8,
    title: "Medical History",
    subtitle: "Answer some Yes/No questions concerning your health.",
  },
  {
    step: 9,
    title: "Upload Documents",
    subtitle: "Upload Clear pictures of the stated documents.",
  },
  // {
  //   step: 10,
  //   title: "Declaration",
  // },
];

export const stepHeading = (steps) => {
  let curStep;
  curStep = formHeadings.filter((item) => item.step === steps);

  let details =
    curStep.length > 0 &&
    curStep.map((item) => {
      return (
        <div key={item.step} className="form-heading">
          <h5>{item.title}</h5>
          <p>{item.subtitle}</p>
        </div>
      );
    });

  return details;
};

export const excludeKeys = [
  "selectBank",
  "bvn",
  "commencementDate",
  "hearAboutUs",
  "haveReferralCode",
  "referralCode",
  "uploadIdNum",
  "address",
  "state",
  "ddBank",
  "ddAccountName",
  "ddAccountNumber",
  "ddAccountType",
];

export const onCompleteForm = (fullDetails, dependants) => {
  sessionStorage.setItem(
    "details",
    JSON.stringify({ ...fullDetails, dependants })
  );
};

export function selectTitle(item, personalInfo, setPersonalInfo) {
  return (
    <Form.Select
      type="text"
      placeholder="select your answer"
      name="title"
      value={personalInfo.title}
      onChange={(e) => {
        handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
      }}
      required
    >
      <option></option>
      {item &&
        item.map((option) => (
          <option key={option.id} value={option.key}>
            {option.name}
          </option>
        ))}
    </Form.Select>
  );
}

export function selectStates(item, value, name, personalInfo, setPersonalInfo) {
  return (
    <Form.Select
      type="text"
      placeholder="select your answer"
      name={name}
      value={value}
      onChange={(e) => {
        handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
      }}
      required
    >
      <option></option>
      {item.map((option) => (
        <option key={option.id} value={option.key}>
          {option.name}
        </option>
      ))}
    </Form.Select>
  );
}
export function selectBanks(
  item,
  value,
  handleChange,
  name,
  personalInfo,
  setPersonalInfo
) {
  return (
    <Form.Select
      type="text"
      placeholder="select your answer"
      name={name}
      value={value}
      onChange={(e) => {
        handleChange(e, personalInfo, setPersonalInfo);
      }}
    >
      <option value="">select an option</option>
      {item.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </Form.Select>
  );
}
export function selectReligions(
  item,
  value,
  name,
  personalInfo,
  setPersonalInfo
) {
  return (
    <Form.Select
      type="text"
      placeholder="select your answer"
      name={name}
      value={value}
      onChange={(e) => {
        handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
      }}
      required
    >
      <option></option>
      {item.map((option) => (
        <option key={option.id} value={option.name}>
          {option.name}
        </option>
      ))}
    </Form.Select>
  );
}
export function selectRelationships(item, value, handleChange, name) {
  return (
    <Form.Select
      type="text"
      placeholder="select your answer"
      name={name}
      value={value}
      onChange={handleChange}
    >
      <option value="">select relationship</option>
      {item.map((option) => (
        <option key={option.id} value={option.key}>
          {option.name}
        </option>
      ))}
    </Form.Select>
  );
}
export function selectBeneficiaryRelationships(
  item,
  beneficiaryInfo,
  setBeneficiaryInfo
) {
  return (
    <Form.Select
      type="text"
      placeholder="select your answer"
      name="beneficiaryRelationship"
      value={beneficiaryInfo.beneficiaryRelationship}
      onChange={(e) => {
        handleBeneficiaryChange(e, beneficiaryInfo, setBeneficiaryInfo);
      }}
    >
      <option value="">select relationship</option>
      {item.map((option) => (
        <option key={option.id} value={option.key}>
          {option.name}
        </option>
      ))}
    </Form.Select>
  );
}
export function selectCities(item, beneficiaryInfo, setBeneficiaryInfo) {
  return (
    <Form.Select
      type="text"
      placeholder="select your answer"
      name="beneficiaryCity"
      value={beneficiaryInfo.beneficiaryCity}
      onChange={(e) => {
        handleBeneficiaryChange(e, beneficiaryInfo, setBeneficiaryInfo);
      }}
      required
    >
      <option></option>
      {item.map((option) => (
        <option key={option.id} value={option.key}>
          {option.name}
        </option>
      ))}
    </Form.Select>
  );
}
export function selectBeneficiaryState(
  item,
  beneficiaryInfo,
  setBeneficiaryInfo
) {
  return (
    <Form.Select
      type="text"
      placeholder="select your answer"
      name="beneficiaryState"
      value={beneficiaryInfo.beneficiaryState}
      onChange={(e) => {
        handleBeneficiaryChange(e, beneficiaryInfo, setBeneficiaryInfo);
      }}
      required
    >
      <option></option>
      {item.map((option) => (
        <option key={option.id} value={option.key}>
          {option.name}
        </option>
      ))}
    </Form.Select>
  );
}
export function selectBusinessTypes(
  item,
  value,
  name,
  personalInfo,
  setPersonalInfo
) {
  return (
    <Form.Select
      type="text"
      placeholder="select your answer"
      name={name}
      value={value}
      onChange={(e) => {
        handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
      }}
      required
    >
      <option></option>
      {item.map((option) => (
        <option key={option.id} value={option.key}>
          {option.name}
        </option>
      ))}
    </Form.Select>
  );
}
export function selectOccupations(
  item,
  value,
  name,
  personalInfo,
  setPersonalInfo
) {
  return (
    <Form.Select
      type="text"
      placeholder="select your answer"
      name={name}
      value={value}
      onChange={(e) => {
        handlePersonalInfoChange(e, personalInfo, setPersonalInfo);
      }}
      required
    >
      <option></option>
      {item.map((option) => (
        <option key={option.id} value={option.name}>
          {option.name}
        </option>
      ))}
    </Form.Select>
  );
}

export const handleModalEnter = () => {
  const modalBody = document.querySelector(".modal-content");
  modalBody.scrollTop = 0;
};

export const handleSubmit = (
  event,
  setValidated,
  steps,
  fullDetails,
  setSteps
) => {
  const form = event.currentTarget;
  if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    return;
  }

  event.preventDefault();
  if (steps === 2) {
    // getQuote();
    handleModalEnter();
    return;
  }
  if (steps === 9) {
    onCompleteForm(fullDetails);
    handleNextStep(setSteps, steps);
    handleModalEnter();
    return;
  }
  handleNextStep(setSteps, steps);
  handleModalEnter();
  setValidated(true);
};

export const upload = (
  e,
  setIsUploading,
  uploadType,
  setIdImage,
  setAddressImage,
  setSignatureImage,
  setPassportImage
) => {
  try {
    setIsUploading(true);
    console.warn(e.target.files);
    const files = e.target.files;
    const formData = new FormData();
    formData.append("file", files[0]);
    fetch("https://staging-upgrade.fbninsurance.co/api/file-upload", {
      method: "POST",
      body: formData,
    }).then((resp) => {
      resp.json().then((result) => {
        if (uploadType === "id") {
          setIdImage(result.data.url);
          setIsUploading(false);
          return;
        }
        if (uploadType === "passport") {
          setPassportImage(result.data.url);
          setIsUploading(false);
          return;
        }
        if (uploadType === "address") {
          setAddressImage(result.data.url);
          setIsUploading(false);
          return;
        }
        if (uploadType === "signature") {
          setSignatureImage(result.data.url);
          setIsUploading(false);
          return;
        }
        setIsUploading(false);
        console.warn(result);
      });
    });
  } catch (error) {
    alert(error);
  }
};

export const fullDetails = (
  personalInfo,
  beneficiaries,
  idImage,
  addressImage,
  signatureImage,
  passportImage
) => {
  let fullDetail = {
    firstName: personalInfo.firstName,
    middleName: personalInfo.middleName,
    lastName: personalInfo.lastName,
    title: personalInfo.title,
    phone: personalInfo.phone,
    dob: personalInfo.dob,
    gender: personalInfo.gender,
    state: personalInfo.state,
    city: personalInfo.city,
    email: personalInfo.email,
    usCitizen: personalInfo.usCitizen,
    address: personalInfo.address,
    maritalStatus: personalInfo.maritalStatus,
    occupation: personalInfo.occupation,
    businessTypes: personalInfo.businessTypes,
    religion: personalInfo.religion,
    bankType: personalInfo.bankType,
    selectBank: personalInfo.selectBank,
    premiumPayment: personalInfo.premiumPayment,
    commencementDate: personalInfo.commencementDate,
    ddBank: personalInfo.ddBank,
    ddAccountName: personalInfo.ddAccountName,
    ddAccountNumber: personalInfo.ddAccountNumber,
    ddAccountType: personalInfo.ddAccountType,
    issuanceDate: personalInfo.issuanceDate,
    expirationDate: personalInfo.expirationDate,
    accountNumber: personalInfo.accountNumber,
    accountType: personalInfo.accountType,
    cardNumber: personalInfo.cardNumber,
    inflation: personalInfo.inflation,
    idCardType: personalInfo.idCardType,
    bvn: personalInfo.bvn,
    beneficiaries: beneficiaries,
    hearAboutUs: personalInfo.hearAboutUs,
    haveReferralCode: personalInfo.haveReferralCode,
    referralCode: personalInfo.referralCode,
    publicOffice: personalInfo.publicOffice,
    uploadIdNum: personalInfo.uploadIdNum,
    medQ1: personalInfo.medQ1,
    medQ2: personalInfo.medQ2,
    medQ3: personalInfo.medQ3,
    medQ4: personalInfo.medQ4,
    medQ5: personalInfo.medQ5,
    medQ6: personalInfo.medQ6,
    medQ7: personalInfo.medQ7,
    medQ8: personalInfo.medQ8,
    medQ9: personalInfo.medQ9,
    medQ10: personalInfo.medQ10,
    medQ11: personalInfo.medQ11,
    medQ12: personalInfo.medQ12,
    medQ11Text: personalInfo.medQ11Text,
    medQ12Text: personalInfo.medQ12Text,
    height: personalInfo.height,
    weight: personalInfo.weight,
    idImage: idImage,
    addressImage: addressImage,
    signatureImage: signatureImage,
    passportImage: passportImage,
    doctorName: personalInfo.doctorName,
    doctorPhone: personalInfo.doctorPhone,
    hospitalName: personalInfo.hospitalName,
    hospitalAddress: personalInfo.hospitalAddress,
    doctorDuration: personalInfo.doctorDuration,
    sourceOfFund: personalInfo.sourceOfFund,
  };
  return fullDetail;
};

const checkValidity = (
  email,
  firstName,
  middleName,
  lastName,
  title,
  phone,
  dob,
  // state,
  address,
  maritalStatus,
  occupation,
  businessTypes,
  religion,
  bankType,
  premiumPayment,
  accountNumber,
  accountType,
  cardNumber,
  inflation,
  issuanceDate,
  expirationDate,
  idCardType,
  // uploadIdNum,
  medQ1,
  medQ2,
  medQ3,
  medQ4,
  medQ5,
  medQ6,
  medQ7,
  medQ8,
  medQ9,
  medQ10,
  medQ11,
  medQ12,
  height,
  weight,
  idImage,
  addressImage,
  signatureImage
) => {
  return (
    !!firstName &&
    !!middleName &&
    !!lastName &&
    !!title &&
    !!email &&
    !!phone &&
    !!dob &&
    // !!state &&
    !!address &&
    !!maritalStatus &&
    !!occupation &&
    !!businessTypes &&
    !!religion &&
    !!bankType &&
    !!premiumPayment &&
    !!accountNumber &&
    !!accountType &&
    !!cardNumber &&
    !!inflation &&
    !!issuanceDate &&
    !!expirationDate &&
    !!idCardType &&
    // !!uploadIdNum &&
    !!medQ1 &&
    !!medQ2 &&
    !!medQ3 &&
    !!medQ4 &&
    !!medQ5 &&
    !!medQ6 &&
    !!medQ7 &&
    !!medQ8 &&
    !!medQ9 &&
    !!medQ10 &&
    !!medQ11 &&
    !!medQ12 &&
    !!height &&
    !!weight &&
    !!idImage &&
    !!addressImage &&
    !!signatureImage
  );
};

export const isValid = (
  personalInfo,
  idImage,
  addressImage,
  signatureImage
) => {
  const validated = checkValidity(
    personalInfo.email,
    personalInfo.firstName,
    personalInfo.middleName,
    personalInfo.lastName,
    personalInfo.title,
    personalInfo.phone,
    personalInfo.dob,
    // personalInfo.state,
    personalInfo.address,
    personalInfo.maritalStatus,
    personalInfo.occupation,
    personalInfo.businessTypes,
    personalInfo.religion,
    personalInfo.bankType,
    personalInfo.premiumPayment,
    personalInfo.accountNumber,
    personalInfo.accountType,
    personalInfo.cardNumber,
    personalInfo.inflation,
    personalInfo.issuanceDate,
    personalInfo.expirationDate,
    personalInfo.idCardType,
    // personalInfo.uploadIdNum,
    personalInfo.medQ1,
    personalInfo.medQ2,
    personalInfo.medQ3,
    personalInfo.medQ4,
    personalInfo.medQ5,
    personalInfo.medQ6,
    personalInfo.medQ7,
    personalInfo.medQ8,
    personalInfo.medQ9,
    personalInfo.medQ10,
    personalInfo.medQ11,
    personalInfo.medQ12,
    personalInfo.height,
    personalInfo.weight,
    idImage,
    addressImage,
    signatureImage
  );
  return validated;
};

export const createProposal = async (
  setIsLoadingProposal,
  accessToken,
  personalInfo,
  idImage,
  addressImage,
  signatureImage,
  passportImage,
  beneficiaries,
  setShowPaymentPage,
  setProposalId,
  setProposalDetails,
  alert,
  dependants
) => {
  const quoteInfo = sessionStorage.getItem("quoteInfo");
  const parsedQuote = JSON.parse(quoteInfo);

  let info = {
    title: personalInfo.title,
    first_name: personalInfo.firstName,
    middle_name: personalInfo.middleName,
    last_name: personalInfo.lastName,
    email: personalInfo.email,
    dob: personalInfo.dob,
    religion: personalInfo.religion,
    business_type: personalInfo.businessTypes,
    occupation: personalInfo.occupation,
    marital_status: personalInfo.maritalStatus,
    city: personalInfo.city,
    street_address: personalInfo.address,
    state: personalInfo.state,
    account_number: personalInfo.accountNumber,
    bvn: personalInfo.bvn,
    gender: personalInfo.gender,
    is_first_bank_account_holder:
      personalInfo.bankType === "Yes" ? true : false,
    bank_id: personalInfo.selectBank,
    phone_number: personalInfo.phone,
    accepted_terms_and_conditions: true,
    identification: idImage,
    id_number: personalInfo.cardNumber,
    id_type: personalInfo.idCardType,
    id_issue_date: personalInfo.issuanceDate,
    id_expiry_date: personalInfo.expirationDate,
    utility: addressImage,
    passport: passportImage,
    signature: signatureImage,
    is_in_good_health: true,
    payment_method: personalInfo.premiumPayment,
    quote_id: parsedQuote.quoteId,
    source_of_fund: personalInfo.sourceOfFund,
    beneficiaries: beneficiaries,
    health_declarations: {
      doctor_name: personalInfo.doctorName,
      doctor_phone: personalInfo.doctorPhone,
      hdf_q1: personalInfo.medQ1 === "Yes" ? true : false,
      hdf_q2: personalInfo.medQ2 === "Yes" ? true : false,
      hdf_q3: personalInfo.medQ3 === "Yes" ? true : false,
      hdf_q4: personalInfo.medQ4 === "Yes" ? true : false,
      hdf_q5: personalInfo.medQ5 === "Yes" ? true : false,
      hdf_q6: personalInfo.medQ6 === "Yes" ? true : false,
      hdf_q7: personalInfo.medQ7 === "Yes" ? true : false,
      hdf_q8: personalInfo.medQ8 === "Yes" ? true : false,
      hdf_q9: personalInfo.medQ9 === "Yes" ? true : false,
      hdf_q10: personalInfo.medQ10 === "Yes" ? true : false,
      hdf_q10_text: "Greater",
      hdf_q11: personalInfo.medQ11 === "Yes" ? true : false,
      hdf_q11_text: personalInfo.medQ11Text,
      hdf_q12: personalInfo.medQ12 === "Yes" ? true : false,
      hdf_q12_text: personalInfo.medQ12Text,
      height: personalInfo.height,
      hospital: personalInfo.hospitalName,
      hospital_address: personalInfo.hospitalAddress,
      how_long_with_doctor: personalInfo.doctorDuration,
      weight: personalInfo.weight,
    },
  };
  try {
    setIsLoadingProposal(true);
    const proposal = await fetch(`${process.env.REACT_APP_PROD_URL}proposals`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(info),
    }).then((res) => res.json());
    setProposalDetails(proposal);

    if (proposal.status === "success") {
      await addProposal({ ...proposal, dependants }).then((res) => {
        if (res.status === "success") {
          setShowPaymentPage(true);
          alert.success(res.message);
          setProposalId(proposal.data.id);
          setIsLoadingProposal(false);
        } else {
          alert.error("Something went wrong");
          setIsLoadingProposal(false);
          return;
        }
      });
    } else {
      alert.error(proposal.message);
      setIsLoadingProposal(false);
    }
  } catch (error) {
    setIsLoadingProposal(false);
  }
};

export const headerStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  paddingTop: 12,
  paddingBottom: 12,
};

export const centerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
};

export const titleStyles = {
  color: "#414141",
};

export const messageStyles = {
  fontSize: 13,
  fontWeight: 400,
};
