import { CSVLink } from "react-csv";
import { IoDownloadOutline } from "react-icons/io5";
import { Store } from "react-notifications-component";
import ReactPaginate from "react-paginate";

export const notify = (type, title, message) => {
  Store.addNotification({
    title,
    message: message,
    type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      showIcon: true,
      duration: 4000,
      onScreen: true,
      pauseOnHover: true,
    },
  });
};
export const paginate = (pageCount, handlePageClick) => {
  return (
    <ReactPaginate
      previousLabel="Previous"
      nextLabel="Next"
      breakLabel="..."
      pageCount={pageCount}
      onPageChange={handlePageClick}
      pageRangeDisplayed={5}
      renderOnZeroPageCount={null}
      containerClassName={"paginationBtns"}
      previousLinkClassName={"previousBtn"}
      nextLinkClassName={"nextBtn"}
      disabledClassName={"paginationDisabled"}
      activeClassName={"paginationActive"}
    />
  );
};
export const exportCsv = (data, headers, filename) => {
  return (
    // <div className="action-btn-container">
    <CSVLink
      headers={headers}
      data={data || []}
      filename={filename}
      className="export-btn"
    >
      <IoDownloadOutline /> {!data ? "loading..." : "Export"}
    </CSVLink>
    // </div>
  );
};

export const noDataMessage = (type, button) => {
  return (
    <div className="no-data">
      <h1>There are no {type}s available</h1>
      <p>
        Add a new {type} by clicking the <span>{button}</span> button...
      </p>
    </div>
  );
};
export const customDataMessage = (type, message) => {
  return (
    <div className="no-data">
      <h1>There are no {type}s available</h1>
      <p>{message}</p>
    </div>
  );
};

export const calculatePercentage = (value, total) => {
  if (total === 0) {
    return 0; // Avoid division by zero
  }
  const percentage = (value / 100) * total;
  return percentage;
};

export const numFormat = (number) => {
  return parseInt(number).toLocaleString("en-US");
};

export const postReferral = async (policy_num, agent_code) => {
  const values = {
    reference_no: policy_num,
    referral_code: agent_code,
  };
  const res = await fetch(
    "https://myapps.sanlam.com.ng/uploads/agent_referral_prod.php",
    {
      method: "POST",
      // headers: {
      //   "content-type": "application/json",
      //   Accept: "application/json",
      // },
      body: JSON.stringify(values),
    }
  ).then((res) => res.json());

  return res;
};
