import React from "react";

export const InclusiveDetails = () => {
  return (
    <>
      <p>
        <strong>Family Love (Inclusive)</strong>
      </p>
      <p>
        With the extended family system in Nigeria, the death of a loved one
        often places a financial strain on the surviving family members.
        Our&nbsp;<strong>Family Love</strong>
        <strong>&nbsp;Plan</strong>&nbsp;provides financial support on the death
        of the policyholder or named co-life assured.&nbsp;
      </p>
      <p>
        <strong>
          <em>Main Benefits</em>
        </strong>
        <em>:&nbsp;</em>
      </p>
      <ul>
        <li>
          You get a lump sum in case of the death of any of the covered
          members&nbsp;
        </li>
        <li>
          We give back six months premium for every three years that there has
          been no claim on the policy. This benefit applies to each family
          member named in the policy schedule&nbsp;
        </li>
      </ul>
      <p>
        <strong>
          <em>Add-on-benefits</em>
        </strong>
        <em>:</em>&nbsp;{" "}
      </p>
      <ul>
        <li>Waiver of Premium – Physical Impairment</li>
        <li>Waiver of Premium – Death</li>
        <li>Family income benefits</li>
        <li>
          No more premium after age 65 i.e the main life assured ceases paying
          the premium after age 65.&nbsp;
        </li>
        <li>
          At the death of the policyholder, Sanlam takes over all future
          premiums for the rest of the lives assured provided the “No More
          Premium Benefit after death (Waiver of Premium)” option is
          chosen.&nbsp;
        </li>
      </ul>
      <p>
        <strong>
          <em>Who could be covered?&nbsp;</em>
        </strong>
      </p>
      <p>
        The Family Love Inclusive Plan &nbsp;(FLPI) is a funeral support cover
        for:&nbsp;
      </p>
      <ul>
        <li>You&nbsp;</li>
        <li>Your spouse&nbsp;</li>
        <li>Parents & Parents-in-law&nbsp;</li>
        <li>
          Wider family (Grandparents, Uncles, Aunts, Stepparents,
          Children)&nbsp;
        </li>
      </ul>
      <p>
        <em>
          *Please note that no member covered under this policy can have a cover
          level higher than the principal life assured.
        </em>
        &nbsp;
      </p>
      <p>
        <strong>
          <em>Waiting Period:</em>
        </strong>
        &nbsp;
      </p>
      <ul>
        <li>
          A waiting period of 6 months is applicable on this policy. Within this
          period, no claims can be made on the death of the main or principal
          life and spouse resulting from natural causes.&nbsp;
        </li>
        <li>
          The waiting period for the other covered members (parents and
          parents-in-laws and the wider family) is one year if death occurs by
          natural causes.&nbsp;
        </li>
        <li>There is no waiting period applicable if death is by accident.</li>
      </ul>
      <p>
        <strong>
          <u>Applicable escalation rates at the start of the policy:&nbsp;</u>
        </strong>
      </p>
      <p>
        Premium growth&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp;% Cover growth
      </p>
      <ul>
        <li>
          10% (default)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;7.5% (default)
        </li>
        <li>
          15%&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;11.25%
        </li>
        <li>
          20%&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;15%
        </li>
      </ul>
    </>
  );
};
