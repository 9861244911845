import React, { useContext, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { useAlert } from "react-alert";
import flutterwave from "../assets/flutterwave.png";
import paystack from "../assets/paystack.png";
import { GrClose } from "react-icons/gr";
import { makeFlutterwavePayment, makePaystackPayment } from "../services";
import { RecurrentModal } from "./RecurrentModal";
import { AuthContext } from "../helpers/AuthContext";

export const PaymentPage = ({
  showPaymentPage,
  setShowPaymentPage,
  proposalId,
}) => {
  const [isRecurrent, setIsRecurrent] = useState(false);
  const alert = useAlert();
  const [pmtMethod, setPmtMethod] = useState({
    method: "",
    type: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { proposalDetails } = useContext(AuthContext);

  const handleChange = (e) => {
    const name = e.target.name;
    const value =
      name === "phone" ||
      name === "accountNumber" ||
      name === "bvn" ||
      name === "height" ||
      name === "weight"
        ? e.target.value.replace(/\D/g, "")
        : e.target.value;
    setPmtMethod({ ...pmtMethod, [name]: value });
  };

  const policyDuration = (saveLength) => {
    let duration;

    if (proposalDetails.data.quote.fop === "monthly") {
      duration = parseInt(saveLength) * 12;
    }

    if (proposalDetails.data.quote.fop === "quaterly") {
      duration = parseInt(saveLength) * 4;
    }
    if (proposalDetails.data.quote.fop === "half-annually") {
      duration = parseInt(saveLength) * 2;
    }
    if (proposalDetails.data.quote.fop === "annually") {
      duration = parseInt(saveLength) * 1;
    }
    return duration;
  };

  const values = {
    proposal_id: proposalDetails.data.id,
    amount: proposalDetails.data.quote.premium,
    phone_number: proposalDetails.data.customer.phone_number,
    recurring: isRecurrent ? true : false,
    duration: policyDuration(
      parseInt(proposalDetails.data.quote.policy_duration)
    ),
    interval: proposalDetails.data.quote.fop,
    return_url: `http://buyinsurance.sanlam.com.ng/verification`,
  };

  const payment = () => {
    setIsLoading(true);
    try {
      if (pmtMethod.method === "ps") {
        return makePaystackPayment(values)
          .then((res) => {
            window.open(res.data.authorization_url, "_blank");
            setIsLoading(false);
          })
          .catch((err) => {
            alert.error("something went wrong");
            setIsLoading(false);
          });
      } else {
        return makeFlutterwavePayment(values)
          .then((res) => {
            window.open(res.data.authorization_url, "_blank");
            setIsLoading(false);
          })
          .catch((err) => {
            alert.error("something went wrong");
            setIsLoading(false);
          });
      }
    } catch (error) {
      alert.error("something went wrong");
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={showPaymentPage}
      fullscreen
      className="payment-modal"
    >
      <Modal.Body>
        <div className="payment-container">
          <div className="payment-header">
            <p></p>
            <p>Make Payment</p>
            <GrClose
              onClick={() => {
                setShowPaymentPage(false);
              }}
            />
          </div>
          <div className="payment-wrapper">
            <div className="pmt-details-container">
              <p className="pmt-title">Payment Details</p>
              <div className="pmt-details">
                <span>Policy Number</span>
                <p>{`${proposalDetails.data.quote.product.abbreviation}${proposalDetails.data.id}EE`}</p>
              </div>
              <div className="pmt-details">
                <span>Policy Holder</span>
                <p>{`${proposalDetails.data.customer.first_name} ${proposalDetails.data.customer.last_name}`}</p>
              </div>
              <div className="pmt-details">
                <span>Product</span>
                <p>{proposalDetails.data.quote.product.name}</p>
              </div>
              <div className="pmt-details">
                <span>Frequency Of Payment</span>
                <p>{proposalDetails.data.quote.fop}</p>
              </div>
              <div className="total-pmt">
                <span>Total amount</span>
                <p>
                  ₦
                  {parseFloat(
                    proposalDetails.data.quote.premium
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
            </div>
            <div className="pmt-method-container">
              <p className="pmt-title">Payment Method</p>
              <div
                className="pmt-method-wrapper"
                onClick={() => {
                  setPmtMethod({ method: "fw" });
                }}
              >
                <div>
                  <img src={flutterwave} alt="flutterwave" />
                  <p style={{ paddingLeft: 12 }}>
                    pay your premium by utilizing flutterwave
                  </p>
                </div>
                <div
                  className={
                    pmtMethod.method === "fw"
                      ? "payment-options-container show-payment-options"
                      : "payment-options-container"
                  }
                >
                  <p>select a payment option</p>
                  <Form.Check
                    inline
                    label="One off payment"
                    name="type"
                    onChange={handleChange}
                    type={"radio"}
                    id={`inline-radio-1`}
                    value="one-off"
                  />
                  <Form.Check
                    inline
                    label="Set up auto-renewal payment"
                    name="type"
                    onChange={handleChange}
                    type={"radio"}
                    id={`inline-radio-1`}
                    value="recurrent"
                  />
                </div>
              </div>
              <div
                className="pmt-method-wrapper"
                onClick={() => {
                  setPmtMethod({ method: "ps" });
                }}
              >
                <div style={{ paddingLeft: 12 }}>
                  <img src={paystack} alt="paystack" />
                  <p>pay your premium by utilizing paystack</p>
                </div>
                <div
                  className={
                    pmtMethod.method === "ps"
                      ? "payment-options-container show-payment-options"
                      : "payment-options-container"
                  }
                >
                  <p>select a payment option</p>
                  <Form.Check
                    inline
                    label="One off payment"
                    name="type"
                    onChange={handleChange}
                    type={"radio"}
                    id={`inline-radio-1`}
                    value="one-off"
                  />
                  <Form.Check
                    inline
                    label="Set up auto-renewal payment"
                    name="type"
                    onChange={handleChange}
                    type={"radio"}
                    id={`inline-radio-1`}
                    value="recurrent"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="btn-pmt">
            <button
              onClick={() => {
                if (pmtMethod.type === "recurrent") {
                  setIsRecurrent(true);
                } else {
                  payment();
                }
              }}
              className="blue-button"
            >
              {isLoading ? (
                <Spinner animation="border" variant="light" size={"sm"} />
              ) : (
                "Make Payment"
              )}
            </button>
          </div>
        </div>
      </Modal.Body>
      <RecurrentModal
        isRecurrent={isRecurrent}
        setIsRecurrent={setIsRecurrent}
        payment={payment}
        amount={proposalDetails.data.quote.premium}
      />
    </Modal>
  );
};
