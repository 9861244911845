import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../helpers/AuthContext";
import { Otp } from "../Otp";
import { useParams } from "react-router-dom";
import { addQuote, sendOtp } from "../../services";
import { Col, OverlayTrigger, Row } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { calculatePercentage } from "../CommonActions";
import { Tooltip } from "react-bootstrap";
import { GoInfo } from "react-icons/go";

export const QuoteSummary = ({ productId, productCode }) => {
  const {
    quote,
    prods,
    quoteForm,
    isGenerating,
    steps,
    setSteps,
    personalInfo,
    setQuote,
    prodFound,
  } = useContext(AuthContext);
  const [isOtp, setIsOtp] = useState(false);
  const [isOtpSending, setIsOtpSending] = useState(false);
  const [otpData, setOtpData] = useState();
  const [flpProceed, setFlpProceed] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [prestigeSumAssured, setPrestigeSumAssured] = useState();
  const [classicSumAssured, setClassicSumAssured] = useState();
  const [sumAssured, setSumAssured] = useState(null);
  const [prestigeDuration, setPrestigrDuration] = useState("");
  const [inclusiveDuration, setInclusiveDuration] = useState("");
  const [classicDuration, setClassicDuration] = useState("");
  const { abbr } = useParams();

  const sumBenefits = (val1, val2, val3, val4) => {
    const total = val1 + val2 + val3 + val4;

    return total.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const sendOtps = () => {
    setIsOtpSending(true);
    sendOtp(quote.data.id, personalInfo.email, personalInfo.firstName)
      .then((res) => {
        setFlpProceed(false);
        if (res.status === "success") {
          setOtpData(res);
          setIsOtpSending(false);
          setIsOtp(true);
        } else {
          setIsOtpSending(false);
          console.log(res);
        }
      })
      .catch((error) => {
        setIsOtpSending(false);
        setFlpProceed(false);
        console.log(error);
      });
  };

  const handleFlpProceed = () => {
    setFlpProceed(true);
    sessionStorage.setItem(
      "quoteInfo",
      JSON.stringify({
        fullname: quoteForm?.fullname,
        phone: quoteForm?.phone,
        email: quoteForm?.email,
        frequencyOfPayment: selectedQuote?.data?.frequency_of_payment,
        amount: selectedQuote?.data?.premium,
        policyDuration: selectedQuote?.data?.policy_duration,
        product: selectedQuote?.data?.product.name,
        quoteId: selectedQuote?.data?.id,
      })
    );

    addQuote({
      ...selectedQuote,
      email: personalInfo.email,
      firstName: personalInfo.firstName,
    }).then((res) => {
      if (res.status && res.status === "success") {
        sendOtps();
        sessionStorage.setItem(
          "person",
          JSON.stringify({
            firstName: quoteForm.firstName,
            middleName: quoteForm.middleName,
            lastName: quoteForm.lastName,
            phone: quoteForm.phone,
            email: quoteForm.email,
            dob: quoteForm.dob,
            title: quoteForm.title,
          })
        );
        // setSteps(steps + 1);
        // alert.success(res.message);
      } else {
        setFlpProceed(false);
        alert.error("Something went wrong");
      }
    });
  };

  console.log("prodfound", prodFound);

  useEffect(() => {
    prodFound?.map((prod) => {
      if (prod?.data?.product?.id === 40) {
        setPrestigeSumAssured(prod?.data?.waivers[0].sum_assured);
      }
      if (prod?.data?.product?.id === 42) {
        setClassicSumAssured(prod?.data?.waivers[0].sum_assured);
      }
    });
  }, []);

  return (
    <>
      {abbr !== "FLP" ? (
        <>
          {" "}
          <div className="quote-form-summary">
            {quote.data.product_code !== "FLSP" &&
            quote.data.product_code !== "GLRP" &&
            quote.data.product_code !== "FLPP" &&
            quote.data.product_code !== "FLPC" &&
            quote.data.product_code !== "FLPI" ? (
              <>
                <div className="summary-wrapper">
                  <span>Product</span>
                  <p>{quote.data.product.name}</p>
                </div>
                <div className="summary-wrapper">
                  <span>Frequency of Payment</span>
                  <p>{quote.data.frequency_of_payment}</p>
                </div>
                <div className="summary-wrapper">
                  <span>Assured Date Of Birth</span>
                  <p>{quote.data.assured_date_of_birth}</p>
                </div>
                <div className="summary-wrapper">
                  <span>Policy Duration</span>
                  <p>{quote.data.policy_duration} years</p>
                </div>

                {(productCode === "SAV" || productCode === "PRP") && (
                  <div className="summary-wrapper">
                    <span>Yearly Contribution</span>
                    <p>{quote.data.yearly_contribution}</p>
                  </div>
                )}
                {productCode === "SAV" && (
                  <div className="summary-wrapper">
                    <span>Death Benefit/Funeral Expense</span>
                    <p>
                      {parseFloat(quote.data.death_benefit).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </p>
                  </div>
                )}
                {productCode === "SAV" && (
                  <div className="summary-wrapper">
                    <span>Accidental Death Benefit</span>
                    <p>
                      {parseFloat(
                        quote.data.accidental_death_benefit
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                )}

                <div className="summary-wrapper">
                  <span>Sum Assured</span>
                  <p>
                    {parseFloat(quote.data.sum_assured).toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </p>
                </div>
                {quote.data.product_code === "CFLN" && (
                  <>
                    <h5>Other Benefits</h5>
                    <div className="summary-wrapper">
                      <span>
                        {
                          quote.data.extra_benefits.maturities
                            .first_partial_maturity.year
                        }
                        th year
                      </span>
                      <p>
                        {quote.data.extra_benefits.maturities.first_partial_maturity.benefit.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>

                    <div className="summary-wrapper">
                      <span>
                        {
                          quote.data.extra_benefits.maturities
                            .second_partial_maturity.year
                        }
                        th year
                      </span>
                      <p>
                        {quote.data.extra_benefits.maturities.second_partial_maturity.benefit.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>

                    <div className="summary-wrapper">
                      <span>
                        {
                          quote.data.extra_benefits.maturities.full_maturity
                            .year
                        }
                        th year
                      </span>
                      <p>
                        {quote.data.extra_benefits.maturities.full_maturity.benefit.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>

                    <div className="summary-wrapper">
                      <span>Bonus</span>
                      <p>
                        {quote.data.extra_benefits.bonus.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>

                    <div className="summary-wrapper">
                      <span>Estimated total benefit</span>
                      <p>
                        {sumBenefits(
                          quote.data.extra_benefits.maturities
                            .first_partial_maturity.benefit,
                          quote.data.extra_benefits.maturities
                            .second_partial_maturity.benefit,
                          quote.data.extra_benefits.maturities.full_maturity
                            .benefit,
                          quote.data.extra_benefits.bonus
                        )}
                      </p>
                    </div>
                  </>
                )}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    width: "100%",
                    paddingTop: "13px",
                    paddingBottom: "13px",
                    paddingRight: "7px",
                    paddingLeft: "7px",
                    borderRadius: "5px",
                    color: "#FFF",
                    backgroundColor: "#007dcc",
                  }}
                >
                  <p
                    style={{
                      fontSize: "1.1rem",
                      marginBottom: 0,
                      color: "#FFF",
                      fontWeight: 400,
                    }}
                  >
                    Premium Payable
                  </p>
                  <h4
                    style={{
                      fontSize: "1.1rem",
                    }}
                  >
                    {parseFloat(quote.data.premium).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h4>
                </div>
              </>
            ) : quote.data.product_code === "GLRP" ? (
              <>
                <div className="summary-wrapper">
                  <span>Product</span>
                  <p>{quote.data.product.name}</p>
                </div>
                <div className="summary-wrapper">
                  <span>Frequency of Payments</span>
                  <p>{quote.data.frequency_of_payment}</p>
                </div>
                <div className="summary-wrapper">
                  <span>Assured Date Of Birth</span>
                  <p>{quote.data.assured_date_of_birth}</p>
                </div>
                <div className="summary-wrapper">
                  <span>Guaranteed Period</span>
                  <p>{quote.data.guaranteed_period}</p>
                </div>
                <div className="summary-wrapper">
                  <span>Benefit</span>
                  <p>{quote.data.choice_benefit}</p>
                </div>
                <div className="summary-wrapper">
                  <span>Payout</span>
                  <p>
                    {parseFloat(quote.data.payout).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    width: "100%",
                    paddingTop: "13px",
                    paddingBottom: "13px",
                    paddingRight: "7px",
                    paddingLeft: "7px",
                    borderRadius: "5px",
                    color: "#FFF",
                    backgroundColor: "#007dcc",
                  }}
                >
                  <p
                    style={{
                      fontSize: "1.1rem",
                      marginBottom: 0,
                      color: "#FFF",
                      fontWeight: 400,
                    }}
                  >
                    Purchase Amount
                  </p>
                  <h4
                    style={{
                      fontSize: "1.1rem",
                    }}
                  >
                    {parseFloat(quote.data.purchase_amount).toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </h4>
                </div>
              </>
            ) : (
              <>
                {quote.data.waivers[0].beneficiaries.map((detail) => {
                  return (
                    <div className="dependant-summary">
                      <p>
                        <strong>{detail.beneficiary}</strong>
                      </p>
                      <div className="summary-wrapper">
                        <span>Sum Assured</span>
                        <p>
                          {parseFloat(detail.sum_assured).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </p>
                      </div>
                      <div className="summary-wrapper">
                        <span>Premium Calculated</span>
                        <p>
                          {parseFloat(detail.premium).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </p>
                      </div>
                    </div>
                  );
                })}

                <div className="summary-wrapper">
                  <span>Total Sum Assured</span>
                  <p>
                    {parseFloat(
                      quote.data.waivers[0].sum_assured
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div className="summary-wrapper">
                  <span>Total Risk Premium</span>
                  <p>
                    {parseFloat(quote.data.waivers[0].premium).toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </p>
                </div>

                {productCode === "FLSP" && (
                  <div className="summary-wrapper">
                    <span>Investment Premium</span>
                    <p>
                      {parseFloat(quote.data.contribution).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </p>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    width: "100%",
                    paddingTop: "13px",
                    paddingBottom: "13px",
                    paddingRight: "7px",
                    paddingLeft: "7px",
                    borderRadius: "5px",
                    color: "#FFF",
                    backgroundColor: "#007dcc",
                  }}
                >
                  <p
                    style={{
                      fontSize: "1.1rem",
                      marginBottom: 0,
                      color: "#FFF",
                    }}
                  >
                    Total Premium Payable
                  </p>
                  <h4
                    style={{
                      fontSize: "1.1rem",
                    }}
                  >
                    {parseFloat(quote.data.premium).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h4>
                </div>
              </>
            )}
            <button
              className="blue-button"
              style={{ minWidth: 100, marginTop: 15 }}
              onClick={(e) => {
                e.preventDefault();
                // setSteps(steps + 1);
                // setIsOtp(true);
                sendOtps();
              }}
            >
              {isOtpSending ? "Generating OTP..." : "Proceed"}
            </button>
            <Otp
              isOtp={isOtp}
              setIsOtp={setIsOtp}
              quoteId={quote.data.id}
              email={personalInfo.email}
            />
          </div>
        </>
      ) : (
        <>
          {prodFound.length > 0 ? (
            <>
              {prodFound?.length > 0 && (
                <h6 style={{ marginTop: "30px" }}>
                  Please select a quote to proceed
                </h6>
              )}
              <Row
                style={{
                  paddingTop: "30px",
                  width: "100%",
                }}
              >
                {prodFound?.map((product) => {
                  return (
                    <Col
                      sm={
                        prodFound.length === 1
                          ? 12
                          : prodFound.length === 2
                          ? 6
                          : 4
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        marginBottom: "45px",
                      }}
                    >
                      <div
                        style={{
                          border:
                            selectedQuote === product
                              ? "2px solid #007dcc"
                              : "1px solid #818181 ",
                        }}
                        className="flp-quote"
                        onClick={() => {
                          setQuote(product);
                          setSelectedQuote(product);
                        }}
                      >
                        {selectedQuote === product && (
                          <AiFillCheckCircle
                            style={{
                              position: "absolute",
                              right: -10,
                              top: -14,
                              fontSize: "1.2rem",
                              color: "#007dcc",
                            }}
                          />
                        )}
                        <h6
                          style={{
                            display: "flex",
                            alignSelf: "center",
                            justifySelf: "center",
                            marginBottom: "20px",
                          }}
                        >
                          {product?.data?.product?.name}
                        </h6>
                        {product?.data?.waivers[0].beneficiaries.map(
                          (detail) => {
                            return (
                              <div className="dependant-summary">
                                <p
                                  style={{
                                    fontWeight: 500,
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  {detail.beneficiary}
                                </p>
                                <div className="flp-summary-wrapper">
                                  <span>Sum Assured</span>
                                  <p>
                                    {parseFloat(
                                      detail.sum_assured
                                    ).toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </p>
                                </div>
                                <div className="flp-summary-wrapper">
                                  <span>Premium Calculated</span>
                                  <p>
                                    {parseFloat(detail.premium).toLocaleString(
                                      "en-US",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </p>
                                </div>
                              </div>
                            );
                          }
                        )}

                        <div className="flp-summary-wrapper">
                          <span>Total Sum Assured</span>
                          <p>
                            {parseFloat(
                              product?.data?.waivers[0].sum_assured
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        </div>
                        <div className="flp-summary-wrapper">
                          <span>Total Risk Premium</span>
                          <p>
                            {parseFloat(
                              product?.data?.waivers[0].premium
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        </div>

                        {productCode === "FLSP" && (
                          <div className="flp-summary-wrapper">
                            <span>Investment Premium</span>
                            <p>
                              {parseFloat(
                                product?.data?.contribution
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            width: "100%",
                            paddingTop: "13px",
                            paddingBottom: "13px",
                            paddingRight: "7px",
                            paddingLeft: "7px",
                            borderRadius: "5px",
                            color: "#FFF",
                            backgroundColor: "#007dcc",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "0.9rem",
                              marginBottom: 0,
                              color: "#FFF",
                            }}
                          >
                            Total Premium Payable
                          </p>
                          <h4
                            style={{
                              fontSize: "1.1rem",
                            }}
                          >
                            {parseFloat(product?.data?.premium).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </h4>
                        </div>
                      </div>

                      {product?.data?.product?.id === 40 && (
                        <div
                          className="flp-extra-benefits"
                          onClick={() => {
                            setQuote(product);
                            setSelectedQuote(product);
                          }}
                          style={{
                            border:
                              selectedQuote === product
                                ? "2px solid #007dcc"
                                : "1px solid #818181 ",
                          }}
                        >
                          <p className="extra-benefit-title">Extra Benefits</p>
                          <div className="flp-summary-wrapper">
                            <span>Waiver of premium</span>
                            <p>Death</p>
                          </div>
                          <div className="flp-summary-wrapper">
                            <span>Waiver of premium</span>
                            <p>Impairment</p>
                          </div>
                          <div className="flp-summary-wrapper">
                            <span>Burial repatriation</span>
                            <p>
                              {calculatePercentage(
                                10,
                                quoteForm?.itemDetail * 500000
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          </div>
                          <div className="flp-summary-wrapper">
                            <span
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              Family income cover
                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    <p
                                      style={{
                                        textAlign: "left",
                                        fontSize: 11,
                                      }}
                                    >
                                      This is a total of 5% of the sum assured
                                      for 6 months
                                    </p>
                                  </Tooltip>
                                }
                              >
                                <p
                                  style={{
                                    marginBottom: 0,
                                    marginLeft: 6,
                                  }}
                                >
                                  <GoInfo />
                                </p>
                              </OverlayTrigger>
                            </span>

                            <p>
                              {calculatePercentage(
                                5,
                                quoteForm?.itemDetail * 500000 * 6
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          </div>
                          <div className="flp-summary-wrapper">
                            <span>Double accident cover</span>
                            <p>
                              {(quoteForm?.itemDetail * 500000).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </p>
                          </div>
                          <div className="flp-summary-wrapper">
                            <span>1st annivesary cover</span>
                            <p>
                              {calculatePercentage(
                                50,
                                quoteForm?.itemDetail * 500000
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          </div>
                          <div className="flp-summary-wrapper">
                            <span
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              Cashback{" "}
                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    <p
                                      style={{
                                        textAlign: "left",
                                        fontSize: 11,
                                      }}
                                    >
                                      You will get cashback equivalent of your
                                      premium for 6 months
                                    </p>
                                  </Tooltip>
                                }
                              >
                                <p
                                  style={{
                                    marginBottom: 0,
                                    marginLeft: 6,
                                  }}
                                >
                                  <GoInfo />
                                </p>
                              </OverlayTrigger>
                            </span>
                            <p></p>
                          </div>{" "}
                          <div
                            className="flp-summary-wrapper"
                            style={{ marginTop: "10px" }}
                          >
                            <span style={{ fontSize: "1rem" }}>
                              Total additional benefits
                            </span>
                            <p style={{ fontSize: "1rem" }}>
                              {(
                                calculatePercentage(
                                  10,
                                  quoteForm?.itemDetail * 500000
                                ) +
                                calculatePercentage(
                                  5,
                                  quoteForm?.itemDetail * 500000 * 6
                                ) +
                                quoteForm?.itemDetail * 500000 +
                                calculatePercentage(
                                  50,
                                  quoteForm?.itemDetail * 500000
                                )
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          </div>
                          <div
                            className="flp-summary-wrapper"
                            style={{ marginTop: "10px" }}
                          >
                            <span
                              style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                            >
                              Total benefits
                            </span>
                            <p style={{ fontSize: "1.2rem" }}>
                              {(
                                calculatePercentage(
                                  10,
                                  quoteForm?.itemDetail * 500000
                                ) +
                                calculatePercentage(
                                  5,
                                  quoteForm?.itemDetail * 500000 * 6
                                ) +
                                quoteForm?.itemDetail * 500000 +
                                calculatePercentage(
                                  50,
                                  quoteForm?.itemDetail * 500000
                                ) +
                                prestigeSumAssured
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          </div>
                        </div>
                      )}
                      {product?.data?.product?.id === 41 && (
                        <div
                          className="flp-extra-benefits"
                          onClick={() => {
                            setQuote(product);
                            setSelectedQuote(product);
                          }}
                          style={{
                            border:
                              selectedQuote === product
                                ? "2px solid #007dcc"
                                : "1px solid #818181 ",
                          }}
                        >
                          <p className="extra-benefit-title">Extra Benefits</p>
                          <div className="flp-summary-wrapper">
                            <span>Waiver of premium</span>
                            <p>Death</p>
                          </div>

                          <div className="flp-summary-wrapper">
                            <span
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              Cashback{" "}
                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    <p
                                      style={{
                                        textAlign: "left",
                                        fontSize: 11,
                                      }}
                                    >
                                      You will get cashback equivalent of your
                                      premium for 6 months
                                    </p>
                                  </Tooltip>
                                }
                              >
                                <p
                                  style={{
                                    marginBottom: 0,
                                    marginLeft: 6,
                                  }}
                                >
                                  <GoInfo />
                                </p>
                              </OverlayTrigger>
                            </span>
                            <p></p>
                          </div>
                        </div>
                      )}
                      {product?.data?.product?.id === 42 && (
                        <div
                          className="flp-extra-benefits"
                          onClick={() => {
                            setQuote(product);
                            setSelectedQuote(product);
                          }}
                          style={{
                            border:
                              selectedQuote === product
                                ? "2px solid #007dcc"
                                : "1px solid #818181 ",
                          }}
                        >
                          <p className="extra-benefit-title">Extra Benefits</p>
                          <div className="flp-summary-wrapper">
                            <span>Waiver of premium</span>
                            <p>Death</p>
                          </div>{" "}
                          <div className="flp-summary-wrapper">
                            <span>Waiver of premium</span>
                            <p>Impairments</p>
                          </div>{" "}
                          <div className="flp-summary-wrapper">
                            <span
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              Family income cover
                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    <p
                                      style={{
                                        textAlign: "left",
                                        fontSize: 11,
                                      }}
                                    >
                                      This is a total of 5% of the sum assured
                                      for 6 months
                                    </p>
                                  </Tooltip>
                                }
                              >
                                <p
                                  style={{
                                    marginBottom: 0,
                                    marginLeft: 6,
                                  }}
                                >
                                  <GoInfo />
                                </p>
                              </OverlayTrigger>
                            </span>

                            <p>
                              {calculatePercentage(
                                5,
                                quoteForm?.itemDetail * 500000 * 6
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          </div>
                          <div className="flp-summary-wrapper">
                            <span
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              Cashback{" "}
                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    <p
                                      style={{
                                        textAlign: "left",
                                        fontSize: 11,
                                      }}
                                    >
                                      You will get cashback equivalent of your
                                      premium for 6 months
                                    </p>
                                  </Tooltip>
                                }
                              >
                                <p
                                  style={{
                                    marginBottom: 0,
                                    marginLeft: 6,
                                  }}
                                >
                                  <GoInfo />
                                </p>
                              </OverlayTrigger>
                            </span>
                            <p></p>
                          </div>
                          <div
                            className="flp-summary-wrapper"
                            style={{ marginTop: "10px" }}
                          >
                            <span style={{ fontSize: "1rem" }}>
                              Total additional benefits
                            </span>
                            <p style={{ fontSize: "1rem" }}>
                              {calculatePercentage(
                                5,
                                quoteForm?.itemDetail * 500000 * 6
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          </div>
                          <div
                            className="flp-summary-wrapper"
                            style={{ marginTop: "10px" }}
                          >
                            <span
                              style={{
                                fontSize: "1.1rem",
                                fontWeight: "bold",
                              }}
                            >
                              Total benefits
                            </span>
                            <p style={{ fontSize: "1.2rem" }}>
                              {(
                                calculatePercentage(
                                  5,
                                  quoteForm?.itemDetail * 500000 * 6
                                ) + classicSumAssured
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          </div>
                        </div>
                      )}
                    </Col>
                  );
                })}
              </Row>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "30px",
              }}
            >
              <h5>No Product found</h5>
              <p>Please go back and adjust your selections</p>
            </div>
          )}
        </>
      )}

      {productCode === "FLP" && (
        <button
          className="blue-button"
          // style={{ marginTop: "30px" }}
          onClick={() => {
            handleFlpProceed();
          }}
          disabled={selectedQuote === null}
        >
          {flpProceed ? "Generating Otp..." : "Proceed"}
        </button>
      )}

      {selectedQuote !== null && (
        <Otp
          isOtp={isOtp}
          setIsOtp={setIsOtp}
          quoteId={quote?.data.id}
          email={personalInfo?.email}
        />
      )}
    </>
  );
};
