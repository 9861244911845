import React, { useState, useContext, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { BsArrowRight } from "react-icons/bs";
import { BiLeftArrowAlt } from "react-icons/bi";
import { GrFormClose } from "react-icons/gr";
import { CgFileDocument } from "react-icons/cg";
import { AuthContext } from "../helpers/AuthContext";
import { Form } from "react-bootstrap";
import { useAlert } from "react-alert";
import { Summary } from "./Summary";
import MultiStepProgressBar from "./MultiStepProgressBar";
import { QuoteCalculation } from "./quote/QuoteCalculation";
import { PersonalInformation } from "./PersonalInformation";
import { PersonalInformation as QuotePersonal } from "./quote/PersonalInformation";
import { BankDetails } from "./BankDetails";
import {
  centerStyle,
  createProposal,
  fullDetails,
  handleModalEnter,
  handleNextStep,
  handlePrevStep,
  handleShow,
  headerStyles,
  isValid,
  messageStyles,
  onCompleteForm,
  stepHeading,
  titleStyles,
} from "./utils";
import { QuoteSummary } from "./quote/QuoteSummary";
import { Beneficiaries } from "./Beneficiaries";
import { Referral } from "./Referral";
import { Medicals } from "./Medicals";
import { UploadDocuments } from "./UploadDocuments";
import { Declaration } from "./Declaration";

export const KYC = ({
  name,
  setShowForm,
  minDuration,
  maxDuration,
  productId,
  availableAmounts,
  productCode,
  costPerUnit,
}) => {
  const {
    setShowPaymentPage,
    smShow,
    setSmShow,
    setFullscreen,
    steps,
    setSteps,
    proposalId,
    setProposalId,
    isLoadingProposal,
    setIsLoadingProposal,
    idImage,
    addressImage,
    signatureImage,
    passportImage,
    setTotalProportions,
    quoteForm,
    setQuoteForm,
    personalInfo,
    beneficiaryInfo,
    beneficiaries,
    citiesData,
    setProposalDetails,
    dependants,
  } = useContext(AuthContext);
  const [validated, setValidated] = useState(false);

  const accessToken = sessionStorage.getItem("token");
  const alert = useAlert();
  const addressRef = useRef(null);
  const idRef = useRef(null);
  const signatureRef = useRef(null);
  const passportRef = useRef(null);
  const details = sessionStorage.getItem("details");
  const parsedDetails = JSON.parse(details);

  const currentStateCities =
    citiesData &&
    citiesData.data.filter(
      (city) => city.state === beneficiaryInfo.beneficiaryState
    );

  useEffect(() => {
    setTotalProportions(
      beneficiaries.reduce(
        (sum, proportion) => sum + parseInt(proportion.percentage),
        0
      )
    );
  }, [beneficiaries, setTotalProportions]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value =
      name === "itemDetail"
        ? e.target.value.replace(/\D/g, "")
        : e.target.value;
    setQuoteForm({ ...quoteForm, [name]: value });
    console.log(name, value);
  };

  let title = "";
  switch (steps) {
    case 10:
      title = "Declaration";
      break;
    case 11:
      title = "Summary";
      break;
    default:
      title = "KYC Form";
      break;
  }

  let message = "";
  if (steps === 4) {
    message = (
      <p style={messageStyles}>
        Kindly update your KYC so that we can learn more about you
      </p>
    );
  } else if (steps === 3) {
    message = (
      <p>
        <CgFileDocument />
        {`${personalInfo.firstName}'s Quote Summary`}
      </p>
    );
  } else {
    message = <p>Quote Info</p>;
  }

  const components = [
    null,
    <QuotePersonal validated={validated} setValidated={setValidated} />,
    <QuoteCalculation
      minDuration={minDuration}
      maxDuration={maxDuration}
      productId={productId}
      handleChange={handleChange}
      availableAmounts={availableAmounts}
      productCode={productCode}
      costPerUnit={costPerUnit}
    />,
    <QuoteSummary productId={productId} productCode={productCode} />,
    <PersonalInformation />,
    <BankDetails productCode={productCode} />,
    <Beneficiaries currentStateCities={currentStateCities} />,
    <Referral />,
    <Medicals productId={productId} productCode={productCode} />,
    <UploadDocuments
      idRef={idRef}
      addressRef={addressRef}
      signatureRef={signatureRef}
      passportRef={passportRef}
    />,
    <Declaration />,
    <Summary parsedDetails={parsedDetails} proposalId={proposalId} />,
  ];

  const currentComponent = components[steps];

  const handleStepButtonClick = (e) => {
    e.preventDefault();
    if (steps === 2) {
      handleModalEnter();
      return;
    }
    if (steps === 9) {
      handleFormSubmit();
      return;
    }
    if (
      steps === 8 &&
      (personalInfo.medQ1 === "" ||
        personalInfo.medQ2 === "" ||
        personalInfo.medQ3 === "" ||
        personalInfo.medQ4 === "" ||
        personalInfo.medQ5 === "" ||
        personalInfo.medQ6 === "" ||
        personalInfo.medQ7 === "" ||
        personalInfo.medQ8 === "" ||
        personalInfo.medQ9 === "" ||
        personalInfo.medQ10 === "" ||
        personalInfo.medQ11 === "" ||
        personalInfo.medQ12 === "" ||
        personalInfo.height === "" ||
        personalInfo.weight === "")
    ) {
      alert.error("Please fill in all required medical fields");
      return;
    }
    if (steps === 11) {
      handleProposalCreate();
    } else {
      handleNextStep(setSteps, steps);
      handleModalEnter();
    }
  };

  const handleFormSubmit = () => {
    if (isValid(personalInfo, idImage, addressImage, signatureImage)) {
      onCompleteForm(
        fullDetails(
          personalInfo,
          beneficiaries,
          idImage,
          addressImage,
          signatureImage,
          passportImage
        ),
        dependants
      );
      handleNextStep(setSteps, steps);
      handleModalEnter();
    } else {
      alert.error("Please ensure that all required fields are filled");
    }
  };

  const handleProposalCreate = () => {
    createProposal(
      setIsLoadingProposal,
      accessToken,
      personalInfo,
      idImage,
      addressImage,
      signatureImage,
      passportImage,
      beneficiaries,
      setShowPaymentPage,
      setProposalId,
      setProposalDetails,
      alert,
      dependants
    );
  };

  return (
    <>
      <button
        className="blue-button"
        onClick={() => handleShow("md-down", setFullscreen, setSmShow)}
      >
        <BsArrowRight />
        Get a Quote
      </button>
      <Modal
        size="md"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        fullscreen={true}
      >
        <div className="form-header">
          <div
            style={
              steps < 10
                ? headerStyles
                : { ...headerStyles, backgroundColor: "#007dcc" }
            }
          >
            <BiLeftArrowAlt
              onClick={() => {
                handlePrevStep(setSteps, steps);
              }}
              style={{ fontSize: 21 }}
            />
            <h1
              style={
                steps < 4
                  ? { textAlign: "center" }
                  : steps < 10
                  ? titleStyles
                  : { color: "#fff", textAlign: "center" }
              }
            >
              {steps < 4 ? name : title}
            </h1>
            <GrFormClose
              onClick={() => {
                setSmShow(false);
              }}
              style={{ fontSize: 21 }}
            />
          </div>
          {steps < 10 && message}
        </div>

        {steps >= 4 && steps < 10 && (
          <MultiStepProgressBar steps={steps} setSteps={setSteps} />
        )}
        {stepHeading(steps)}
        <>
          {currentComponent}
          {steps >= 6 && (
            <div style={centerStyle}>
              <button
                className="blue-button"
                onClick={handleStepButtonClick}
                style={{ minWidth: 100 }}
              >
                {steps === 11
                  ? isLoadingProposal
                    ? "Generating Proposal..."
                    : "Proceed to payment"
                  : steps === 10
                  ? "I Agree"
                  : "Next"}
              </button>
            </div>
          )}
        </>
      </Modal>
    </>
  );
};
