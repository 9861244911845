import React from "react";
import headerImg from "../../assets/header-img_copy.png";
import ring from "../../assets/ring-elipse.png";
import ico1 from "../../assets/life-insurance1.png";
import ico2 from "../../assets/life-insurance2.png";
import CookieConsent, { Cookies } from "react-cookie-consent";

import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

export const Header = () => {
  return (
    <div className="header-container">
      <div className="header-info">
        <h1>
          Sanlam meets your{" "}
          <span style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                margin: 0,
                width: "100%",
                height: "100%",
                border: "1px solid #B0D7EF",
                transform: "rotate(-16deg)",
                left: 0,
                top: 5,
                borderRadius: "50%",
              }}
            ></div>
            needs
          </span>
        </h1>
        <p>
          <img
            src={ico1}
            alt="ico1"
            style={{ width: "15px", margin: "0px 7px" }}
          />
          We have the know-how to help you grow, give you the peace of mind and
          the security you need.{" "}
          <img
            src={ico2}
            alt="ico2"
            style={{ width: "15px", margin: "0px 7px" }}
          />
        </p>
        <div className="header-btns">
          <Link
            to="body-container"
            spy={true}
            smooth={true}
            offset={-20}
            duration={2000}
          >
            <button className="blue-button">Buy Insurance</button>
          </Link>
          <button className="white-button">Get Recommendations</button>
        </div>
      </div>
      <img src={headerImg} alt="header" />
    </div>
  );
};
