import * as React from "react";
import axios from "axios";
import { AuthContext } from "../helpers/AuthContext";

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.defaults.timeout = process.env.REACT_APP_TIMEOUT;
// axios.defaults.headers.post["Content-Type"] = "application/json";
// axios.defaults.headers.post["Accept"] = "application/json";
// //axios.defaults.headers.common.accept = 'application/json';

const token = sessionStorage.getItem("token") || null;

const instance1 = axios.create({
  baseURL: process.env.REACT_APP_PROD_URL,
  // baseURL: process.env.REACT_APP_BASE_URL,
  timeout: process.env.REACT_APP_TIMEOUT,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const instance2 = axios.create({
  baseURL: "https://api.buyinsurance.fbninsurance.co/",
  timeout: process.env.REACT_APP_TIMEOUT,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

instance1.interceptors.request.use(
  async (config) => {
    // const accessToken = sessionStorage.getItem("token");
    const token = sessionStorage.getItem("token") || null;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export async function getProducts() {
  const { data } = await instance1.get(`products`);
  return data;
}
export async function getSingleProducts(abbr) {
  const { data } = await instance1.get(`product/${abbr}`);
  return data;
}
export async function getProductQuote(values) {
  try {
    const { data } = await instance1.post(`quotes`, values);
    return data;
  } catch (error) {
    return error;
  }
}
export async function makePaystackPayment(values) {
  try {
    const { data } = await instance1.post(`orders/create/paystack`, values);
    return data;
  } catch (error) {
    return error;
  }
}
export async function makeFlutterwavePayment(values) {
  try {
    const { data } = await instance1.post(`orders/create/flutterwave`, values);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getStates() {
  const { data } = await instance1.get(`lovs/states`);
  return data;
}
export async function getSourcesOfFund() {
  const { data } = await instance1.get(`lovs/sources_of_fund`);
  return data;
}
export async function getBenefits() {
  const { data } = await instance1.get(`lovs/benefits`);
  return data;
}
export async function getBanks() {
  const { data } = await instance1.get(`lovs/banks`);
  return data;
}
export async function getReligions() {
  const { data } = await instance1.get(`lovs/religions`);
  return data;
}
export async function getRelationships() {
  const { data } = await instance1.get(`lovs/relationships`);
  return data;
}

export async function getBusinessTypes() {
  const { data } = await instance1.get(`lovs/business_types`);
  return data;
}
export async function getOccupations() {
  const { data } = await instance1.get(`lovs/occupations`);
  return data;
}
export async function getTitles() {
  const { data } = await instance1.get(`lovs/titles`);
  return data;
}
export async function getCities() {
  const { data } = await instance1.get(`lovs/cities`);
  return data;
}
export async function auth() {
  const { data } = await instance1.post(`auth`, {
    api_key: "salesportal",
    api_secret: process.env.REACT_APP_PROD_SECRET,
    // api_secret: "YIEIROFIEORJGYLDKER3948939L84304",
  });
  return data;
}
export async function verifyPayment(reference) {
  try {
    const { data } = await instance1.get(`orders/${reference}`);
    return data;
  } catch (error) {
    return error;
  }
}

// Personal Endpoints:

export async function addQuote(quote) {
  const { data } = await instance2.post(`quote/add-quote`, quote);
  return data;
}

export async function sendOtp(quoteId, email, name) {
  const { data } = await instance2.get(
    `quote/generate-otp/${quoteId}/${email}/${name}`
  );
  return data;
}

export async function verifyOtp(quoteId, otp) {
  const { data } = await instance2.get(`quote/verify-otp/${quoteId}/${otp}`);
  return data;
}

export async function addProposal(proposal) {
  const { data } = await instance2.post(`proposal/add-proposal`, proposal);
  return data;
}

export async function addPayment(payment) {
  const { data } = await instance2.post(`payment/add-payment`, payment);
  return data;
}
